type LoadingSkeletonProps = {
  height?: string;
  variant?: 'bd-img' | 'round-img' | 'tile-img';
  width?: string;
  additionalClass?: string;
};

import './loading-skeleton.scss';

export const LoadingSkeleton = ({
  additionalClass = '',
  height = 'auto',
  variant,
  width = 'auto'
}: LoadingSkeletonProps): JSX.Element | null => {
  const styles = { width, height };

  return (
    <span
      className={
        variant
          ? `loader ${variant} ${additionalClass}`
          : `loader ${additionalClass}`
      }
      style={variant && (!height || !width) ? undefined : styles}></span>
  );
};
