import moment from 'moment';

export const formatDate = (date: string) =>
  new Date(date).toLocaleString('en-US', {
    day: 'numeric',
    year: 'numeric',
    month: 'long'
  });

export const formatAbbrvDate = (date: string) =>
  new Date(date).toLocaleString('en-US', {
    day: 'numeric',
    year: 'numeric',
    month: 'short'
  });

export const getMonths = moment.months();

export const getDaysInMonth = Array.from(
  { length: moment().daysInMonth() },
  (v, i) => {
    const max = moment().daysInMonth();
    if (i <= max) {
      return `${i}`;
    } else {
      return '';
    }
  }
).filter((day) => day && day !== '0');
getDaysInMonth.push(`${moment().daysInMonth()}`);

export const getYears = Array.from(
  { length: 50 },
  (v, i) => `${moment().year() - 66 + i + 1}`
);

/**
 * Formats a birthdate string for a redirect URL.
 *
 * @param {string} date - The original birthdate string in 'MMDYYYY' or 'MMDDYYYY' format.
 * @returns {string} - The formatted birthdate string in 'MMDDYYYY' format.
 */
export const formatRedirectUrlBirthdate = (date: string) => {
  // Check the length of the input date to determine if date needs to be reformatted
  if (date.length === 7) {
    // Extracts month, day, and year
    const month = date.substring(0, 2).padStart(2, '0');
    const day = date.substring(2, 3).padStart(2, '0');
    const year = date.substring(3);

    // Return the formatted date string in 'MMDDYYYY' format.
    return `${month}${day}${year}`;
  }
  return date;
};
