'use client';

import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';

import { useStorage } from '../../../../hooks/useStorage';
import { CTAButton } from '../../../CTAButton';
import { PackageComponentWrapper } from '../../../PackageComponentWrapper';

import ClearIcon from '../../../../assets/svgs/close/close-teal-circle.svg';

import './employee-login.scss';
import { Markdown } from '../../../Markdown';

type FormElements = Readonly<
  {
    email: HTMLInputElement;
  } & HTMLFormControlsCollection
>;

type YourFormElement = Readonly<
  {
    elements: FormElements;
  } & HTMLFormElement
>;
export const EmployeeLogin = ({
  disclaimer
}: {
  disclaimer: string;
}): JSX.Element | null => {
  const [emails, setEmails] = useStorage<string[]>('employeeEmails', []);
  const [clicks, setClicks] = useState<number[]>([]);
  const [show, setShow] = useState(false);
  const [formError, setFormError] = useState<string>('');

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleSubmit = (e: React.FormEvent<YourFormElement>) => {
    e.preventDefault();
    const email: string = e.currentTarget.email.value;
    if (validateEmail(email)) {
      e.currentTarget.reset();
      setFormError('');
      const updatedEmailList = [...emails, email];
      setEmails(updatedEmailList);
    } else {
      setFormError('Email is not valid.');
    }
  };

  const handleClear = (email: string) => {
    const currInd = emails.findIndex((em) => em === email);
    if (currInd >= 0) {
      const updatedEmails = [...emails];
      updatedEmails.splice(currInd, 1);
      setEmails(updatedEmails);
    }
  };

  return (
    <>
      {show ? (
        <Dialog
          className="employee-login-modal disable-scroll"
          draggable={false}
          header={<h2 className="text-primary mh5">Employee Login</h2>}
          footer={
            <CTAButton
              text="Close"
              variant="secondary"
              callBackFunc={() => {
                setShow(false);
              }}
            />
          }
          onHide={() => {
            setShow(false);
          }}
          resizable={false}
          visible={show}>
          <PackageComponentWrapper minWidth={false}>
            <ul className="email-list">
              {Array.isArray(emails) &&
                emails.map((email) => (
                  <li key={email}>
                    <div className="d-flex align-items-center">
                      <span className="email">{email}</span>
                      <button
                        className="clear-email pointer margin-left-5 padding-0 bg-white"
                        onClick={() => handleClear(email)}>
                        <ClearIcon />
                      </button>
                    </div>
                  </li>
                ))}
            </ul>
            <form onSubmit={handleSubmit} className="employee-form">
              <FloatLabel>
                <InputText
                  id="email"
                  placeholder="firstName.lastName@curaleaf.com"
                />
                <label htmlFor="email">Email</label>
              </FloatLabel>
              <button
                className="submit-email text-white bg-primary pointer"
                type="submit">
                Add
              </button>
            </form>
            {formError ? (
              <small className="text-error margin-left-5">{formError}</small>
            ) : null}
          </PackageComponentWrapper>
        </Dialog>
      ) : null}
      <div className="footer-disclaimer">
        <div>
          <button
            onClick={() => {
              const now = new Date().getTime();
              const currClicks = [...clicks, now];
              const threeClicks =
                currClicks.length > 3
                  ? currClicks.slice(currClicks.length - 3)
                  : currClicks;
              setClicks(threeClicks);
              if (
                threeClicks.filter((click) => now - click <= 3000).length === 3
              ) {
                setShow(true);
              }
            }}>
            <Markdown
              content={disclaimer}
              additionalClass="text-tertiary-dark"
            />
          </button>
        </div>
      </div>
    </>
  );
};
