import { Dispatch, SetStateAction } from 'react';
import { Sidebar } from 'primereact/sidebar';

import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';

import CloseIcon from '../../../assets/svgs/close/close-teal.svg';

import './account-drawer.scss';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  setVisibility: Dispatch<SetStateAction<boolean>>;
  visible: boolean;
};

export const AccountDrawer = ({
  children,
  setVisibility,
  visible
}: Props): JSX.Element | null => {
  const { width } = useSiteWideContext();

  const closeDrawer = () => setVisibility(false);

  return (
    <Sidebar
      blockScroll
      visible={visible}
      position={width && width > 767 ? 'right' : 'bottom'}
      className="account-sidebar"
      onHide={closeDrawer}
      maskClassName="bg-blur">
      <ErrorBoundary component="account-drawer">
        <div className="close-container d-flex justify-content-end">
          <button
            aria-label="Close account drawer"
            aria-hidden="true"
            onClick={closeDrawer}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                closeDrawer();
              }
            }}
            className="close-icon">
            <CloseIcon />
          </button>
        </div>
        {children}
      </ErrorBoundary>
    </Sidebar>
  );
};
