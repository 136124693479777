import { CategoryTile as Default } from './default';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';

import './category-tile.scss';
import { Loader } from './loader';

export type CategoryTileProps = {
  title: string;
  link?: string;
  isLoading: false;
};

type CategoryTileLoadingProps = {
  isLoading: true;
};

export const CategoryTile = ({
  props
}: {
  props: CategoryTileProps | CategoryTileLoadingProps;
}): JSX.Element | null =>
  props.isLoading ? (
    <ErrorBoundary component="category-tile-loader">
      <Loader />
    </ErrorBoundary>
  ) : (
    <ErrorBoundary component="category-tile">
      <Default link={props.link} title={props.title} />
    </ErrorBoundary>
  );
