'use client';

import { useState, useEffect } from 'react';

import { CS_State } from 'services';

import { FooterNavigation as Default } from './default';
import { SocialMediaLinksData } from '../../models/types';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';

import './footer-navigation.scss';

export type FooterSectionLinks = {
  linkName: string;
  url?: string;
  id: number;
  callBackFunc?: () => void;
  content?: string;
};

export type FooterNavLinks = {
  heading: string;
  links: FooterSectionLinks[];
  url?: string;
  id: number;
};

export type FooterProps = {
  copyrightInfo: string;
  disclaimer?: string;
  forKiosk: boolean;
  footerLinks?: FooterNavLinks[];
  legalLinks: {
    content?: string;
    id: number;
    linkText: string;
    page?: {
      slug: string;
    };
    url?: string;
  }[];
  socialMediaLinks?: SocialMediaLinksData[];
  states?: Pick<
    CS_State,
    'slug' | 'show_transparency_link' | 'lab_results_url'
  >[];
};

export const FooterNavigation = ({
  copyrightInfo,
  disclaimer,
  forKiosk,
  footerLinks,
  legalLinks,
  socialMediaLinks,
  states
}: FooterProps): JSX.Element | null => {
  const { selectedDispensaryLoading, selectedDispensary } =
    useSiteWideContext();
  const [updatedFooterLinks, setFooterLinks] = useState<FooterNavLinks[]>();

  useEffect(() => {
    const hasOtherLinks =
      updatedFooterLinks?.findIndex((a) => a.heading === 'Other links') || -1;
    if (!forKiosk && footerLinks && hasOtherLinks < 0) {
      const links = [...footerLinks];
      const otherLinks: FooterNavLinks = {
        heading: 'Other links',
        id: footerLinks?.length || 99,
        links: legalLinks.map((ll) => ({
          linkName: ll.linkText,
          url: ll.url,
          id: ll.id,
          content: ll.content
        }))
      };
      links.push(otherLinks);
      setFooterLinks(links);
    }
  }, []);

  useEffect(() => {
    if (
      !selectedDispensaryLoading &&
      selectedDispensary &&
      states?.length &&
      !forKiosk &&
      updatedFooterLinks?.length
    ) {
      const matchingState = states.find(
        (state) => state.slug === selectedDispensary.location.state.slug
      );

      // deep copy of array because it kept updating the state before it was set
      const fLinks: FooterNavLinks[] = JSON.parse(
        JSON.stringify(updatedFooterLinks)
      );

      const lastFooterLinksEntry = fLinks[fLinks.length - 1];
      const hasTransparencyLink =
        lastFooterLinksEntry && lastFooterLinksEntry.heading === 'Other links'
          ? lastFooterLinksEntry.links.find(
              (ol) => ol.linkName === 'Transparency'
            )
          : false;

      if (
        matchingState?.show_transparency_link &&
        matchingState.lab_results_url &&
        !hasTransparencyLink &&
        lastFooterLinksEntry
      ) {
        lastFooterLinksEntry.links.push({
          linkName: 'Transparency',
          url: matchingState.lab_results_url,
          id: 99
        });
        fLinks.splice(updatedFooterLinks.length - 1, 1, lastFooterLinksEntry);
        setFooterLinks(fLinks);
      }
    }
  }, [selectedDispensaryLoading, selectedDispensary, states]);

  return (
    <ErrorBoundary component="footer">
      <Default
        copyrightInfo={copyrightInfo}
        disclaimer={disclaimer}
        forKiosk={forKiosk}
        socialMediaLinks={socialMediaLinks}
        footerLinks={updatedFooterLinks}
      />
    </ErrorBoundary>
  );
};
