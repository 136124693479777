import {
  CarouselBanner,
  CarouselFunctionState,
  CarouselSlideOptions
} from './types';

export const selectItem = (index: number, states: CarouselFunctionState) => {
  const { setActiveImageIndex, setClassName } = states;
  setActiveImageIndex(index);
  setClassName('animation-fade');
  setTimeout(() => {
    setClassName('');
  }, 800);
};

const prevSlide = (
  banners: CarouselBanner[],
  states: CarouselFunctionState
) => {
  const { setActiveImageIndex, activeImageIndex } = states;
  setActiveImageIndex(
    activeImageIndex !== 0 ? activeImageIndex - 1 : banners.length - 1
  );
};

export const nextSlide = (
  banners: CarouselBanner[],
  states: CarouselFunctionState
) => {
  const { setActiveImageIndex, activeImageIndex } = states;
  setActiveImageIndex(
    activeImageIndex !== banners.length - 1 ? activeImageIndex + 1 : 0
  );
};

export const clickPrev = async (
  slideOptions: CarouselSlideOptions,
  banners: CarouselBanner[],
  states: CarouselFunctionState
) => {
  const { autoSlide, setAutoSlide, autoSlideSeconds } = slideOptions;
  const timeToSlide = autoSlideSeconds ? autoSlideSeconds * 1000 : 5000;
  autoSlide && setAutoSlide && setAutoSlide(false);
  prevSlide(banners, states);
  autoSlide &&
    setTimeout(() => {
      setAutoSlide && setAutoSlide(true);
    }, timeToSlide);
};

export const clickNext = (
  slideOptions: CarouselSlideOptions,
  banners: CarouselBanner[],
  states: CarouselFunctionState
) => {
  const { autoSlide, setAutoSlide, autoSlideSeconds } = slideOptions;
  const timeToSlide = autoSlideSeconds ? autoSlideSeconds * 1000 : 5000;
  autoSlide && setAutoSlide && setAutoSlide(false);
  nextSlide(banners, states);
  autoSlide &&
    setTimeout(() => {
      setAutoSlide && setAutoSlide(true);
    }, timeToSlide);
};

export const prevSet = (
  banners: CarouselBanner[],
  carouselLimiter: number,
  states: CarouselFunctionState
) => {
  const { setActiveImageIndex, activeImageIndex } = states;
  setActiveImageIndex(
    activeImageIndex > 0
      ? activeImageIndex - carouselLimiter >= 0
        ? activeImageIndex - carouselLimiter
        : 0
      : banners.length - carouselLimiter >= 0
        ? banners.length - carouselLimiter
        : 0
  );
};

export const nextSet = (
  banners: CarouselBanner[],
  carouselLimiter: number,
  states: CarouselFunctionState
) => {
  const { setActiveImageIndex, activeImageIndex } = states;

  setActiveImageIndex(
    activeImageIndex !== banners.length - carouselLimiter &&
      activeImageIndex + carouselLimiter <= banners.length
      ? activeImageIndex + carouselLimiter
      : 0
  );
};

export const clickPrevSet = async (
  carouselLimiter: number,
  slideOptions: CarouselSlideOptions,
  banners: CarouselBanner[],
  states: CarouselFunctionState
) => {
  const { autoSlide, setAutoSlide, autoSlideSeconds } = slideOptions;
  const timeToSlide = autoSlideSeconds ? autoSlideSeconds * 1000 : 5000;
  autoSlide && setAutoSlide && setAutoSlide(false);
  prevSet(banners, carouselLimiter, states);
  autoSlide &&
    setTimeout(() => {
      setAutoSlide && setAutoSlide(true);
    }, timeToSlide);
};

export const clickNextSet = (
  carouselLimiter: number,
  slideOptions: CarouselSlideOptions,
  banners: CarouselBanner[],
  states: CarouselFunctionState
) => {
  const { autoSlide, setAutoSlide, autoSlideSeconds } = slideOptions;
  const timeToSlide = autoSlideSeconds ? autoSlideSeconds * 1000 : 5000;
  autoSlide && setAutoSlide && setAutoSlide(false);
  nextSet(banners, carouselLimiter, states);
  autoSlide &&
    setTimeout(() => {
      setAutoSlide && setAutoSlide(true);
    }, timeToSlide);
};
