import './summary-card-header.scss';

export type SummaryCardHeaderProps = {
  transactionDateTime: string;
  items: {
    imageUrl: string;
    quantity: string;
  }[];
  totalPrice: string;
  loyalty?: string | null;
};

export const SummaryCardHeader = ({
  transactionDateTime,
  items,
  totalPrice,
  loyalty
}: SummaryCardHeaderProps) => {
  const dateConverter = (unix: string) => {
    const a = new Date(unix);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const month = months[a.getMonth()];
    const date = a.getDate();
    const year = a.getFullYear();
    const currentYear = new Date().getFullYear();
    return `${month} ${date}${year !== currentYear ? `, ${year} ` : ''}`;
  };

  const getTotalItemQuantity = (
    items: {
      imageUrl: string;
      quantity: string;
    }[]
  ) => {
    let itemQuantity = items.length;
    items.forEach((item) => {
      if (Math.floor(+item.quantity) > 1) {
        itemQuantity += Math.floor(+item.quantity) - 1;
      }
    });
    return itemQuantity;
  };

  return (
    <div className="information-summary d-flex justify-content-between">
      <div className="info d-flex flex-column">
        <small className="font-bold uppercase">Order Placed</small>
        <p className="body-m margin-0">{dateConverter(transactionDateTime)}</p>
      </div>
      <div className="info d-flex flex-column">
        <small className="font-bold uppercase">Items</small>
        <p className="body-m margin-0">{getTotalItemQuantity(items)}</p>
      </div>
      <div className="info d-flex flex-column">
        <small className="font-bold uppercase">Total</small>
        <p className="body-m margin-0">
          ${totalPrice}
          {loyalty ? (
            <span className="points-tag body-sm text-primary bg-success-light subtitle margin-left-5">
              {loyalty} pts
            </span>
          ) : null}
        </p>
      </div>
    </div>
  );
};
