import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { VideoBlockProps } from '.';

export const VideoBlock = ({
  anchor = 'video',
  url
}: VideoBlockProps): JSX.Element | null => {
  const [hasWindow, setHasWindow] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true);
    }
  }, []);

  return (
    <PackageComponentWrapper>
      <div
        id={anchor}
        className="video-block container-uw my-2 my-sm-4 my-md-5"
        data-test="video-block">
        {hasWindow ? (
          <ReactPlayer
            controls={true}
            data-test="player"
            url={url}
            width="100%"
            height="100%"
          />
        ) : null}
      </div>
    </PackageComponentWrapper>
  );
};
