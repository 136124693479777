import { useEffect, useState } from 'react';

export default function useOutsideClick(
  ref: React.MutableRefObject<HTMLAnchorElement | null>,
  cb: () => void
) {
  const [isClicked, setIsClicked] = useState<boolean>();
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb();
        setIsClicked(true);
      } else {
        setIsClicked(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return isClicked;
}
