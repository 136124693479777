import { FormikProps } from 'formik';

import { CTAButton } from '../CTAButton';
import { EcomCTAButton } from './Account/CTAButton';
import { CheckboxSelection } from './components/Checkbox';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { DropdownSelection } from './components/DropdownSelection';
import { MultiselectAccount } from './components/Multiselect/account';
import { AccountPreferencesFormFields } from '../../templates/Account/settings';

import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { FormDisclaimer } from './components/Disclaimer';

export type AccountPreferencesProps = {
  brandPreferences?: { label: string }[];
  buttonText: string;
  categoryPreferences?: { label: string }[];
  copy: {
    brand: string;
    product_categories: string;
  };
  disclaimer?: string;
  formik: FormikProps<AccountPreferencesFormFields>;
  loading: boolean;
  statesNdispensaries: {
    state: string;
    dispensaries: { name: string; id: string }[];
  }[];
};

export const AccountPreferencesForm = ({
  brandPreferences,
  buttonText,
  categoryPreferences,
  copy,
  disclaimer,
  formik,
  loading,
  statesNdispensaries
}: AccountPreferencesProps) => {
  if (!formik) return null;

  const groupedDispensaries = statesNdispensaries
    .filter((stateNDispo) => stateNDispo.dispensaries.length)
    .map((stateNDispo) => ({
      label: stateNDispo.state,
      items: stateNDispo.dispensaries.map((dispo) => {
        return {
          label: dispo.name
        };
      })
    }));

  return (
    <PackageComponentWrapper minWidth={false}>
      <ErrorBoundary component="account-preferences-form">
        <form className="form" onSubmit={formik.handleSubmit}>
          <h5 className="bb-secondary-primary margin-bottom-16 padding-bottom-16 body-xl">
            Communication Preferences
          </h5>
          <div className="preferences-form">
            {statesNdispensaries && statesNdispensaries.length > 1 ? (
              <DropdownSelection
                disabled={loading}
                dropdown_options={statesNdispensaries.map(
                  (state) => state.state
                )}
                field="state"
                formik={formik}
                required={true}
                sort={true}
                title="What state do you live in?"
              />
            ) : null}
            {statesNdispensaries && statesNdispensaries.length > 1 ? (
              <MultiselectAccount
                disabled={loading}
                multiselect_options={groupedDispensaries}
                field="dispensaryPreferences"
                formik={formik}
                sort={true}
                title="What dispensary will you most likely visit?"
                grouped={true}
              />
            ) : null}
            {brandPreferences ? (
              <MultiselectAccount
                disabled={loading}
                field="brandPreference"
                formik={formik}
                multiselect_options={brandPreferences}
                required={false}
                sort={true}
                title={copy.brand}
              />
            ) : null}
            {categoryPreferences ? (
              <MultiselectAccount
                disabled={loading}
                field="categoryPreference"
                formik={formik}
                multiselect_options={categoryPreferences}
                required={false}
                sort={true}
                title={copy.product_categories}
              />
            ) : null}
            <div className="optin-checkbox-container">
              <span className="optin-checkbox-label body-l">
                I want to receive offers, rewards, and other marketing
                communications:
              </span>
              <CheckboxSelection
                checkbox_options={{
                  label: 'Email'
                }}
                field="mainSubscriptionStatus"
                formik={formik}
                required={false}
              />
              <CheckboxSelection
                checkbox_options={{
                  label: 'SMS'
                }}
                field="smsSubscriptionStatus"
                formik={formik}
                required={false}
              />
            </div>
            {disclaimer ? <FormDisclaimer disclaimer={disclaimer} /> : null}
            <div className="button-group d-flex justify-content-between">
              <CTAButton
                callBackFunc={formik.resetForm}
                text="Cancel"
                variant="tertiary"
              />
              <EcomCTAButton
                disabled={!formik.dirty}
                loading={loading}
                text={buttonText}
                variant="secondary"
              />
            </div>
          </div>
        </form>
      </ErrorBoundary>
    </PackageComponentWrapper>
  );
};
