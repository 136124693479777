import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { Params } from 'next/dist/shared/lib/router/utils/route-matcher';

import { SEARCH_QUERY_KEYS } from '../../../../utils/constants';
import { EcomProductGridProps } from '../..';
import { EcomProductPageType } from '../../../../models/productPage';
import { SortBy } from '../../../../models/types';
import { FilterAccordion } from './FilterAccordion';
import { CTAButton } from '../../../CTAButton';

import './filter-modal.scss';

import Close from '../../../../assets/svgs/close/modal-close.svg';

export type FilterMenuItem = {
  id?: string;
  label: string;
  isSelected: boolean;
  key: string;
  values?: {
    min: number;
    max: number;
    current: [number, number];
  };
  unit?: '%' | 'mg';
};

export type FilterKeys =
  | 'Brands'
  | 'Categories'
  | 'Effects'
  | 'Options'
  | 'Potency'
  | 'Specials'
  | 'Strains'
  | 'Subcategories'
  | 'Terpenes'
  | 'Weights';

export type FilterMenus = {
  [key in FilterKeys]: FilterMenuItem[];
};

type FilterModalProps = {
  filters: FilterMenus;
  isLoading: false;
  onClose?: () => void;
  onSelect: EcomProductGridProps['onSelectFilter'];
  pageType: EcomProductPageType['type'];
  searchParams: Params;
  shopLink: string;
  sortBy: SortBy;
};

export const FilterModal = ({
  filters,
  onClose,
  onSelect,
  pageType,
  searchParams,
  shopLink,
  sortBy
}: FilterModalProps) => {
  const { push, refresh } = useRouter();
  const pathname = usePathname();
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (searchParams) {
      let counter = 0;
      SEARCH_QUERY_KEYS.map((qk) => {
        const key = searchParams[qk];
        if (key && typeof key === 'string') {
          const value = qk === 'p' ? 1 : key.split(',').length;
          counter += value;
        }
      });
      setCount(counter);
    }
  }, [searchParams]);

  return (
    <>
      <div className="filter-modal" data-test="filter-accordion-wrapper">
        {Object.keys(filters).map((menu, i) => {
          const key = menu as keyof typeof filters;
          if (key && filters[key]) {
            return (
              <FilterAccordion
                key={key}
                props={{
                  defaultOpen: i === 0,
                  isLoading: false,
                  filters,
                  onSelect,
                  header: key,
                  pageType,
                  shopLink,
                  sortBy
                }}
              />
            );
          }
        })}
      </div>
      <div className="filter-modal-results-button d-flex flex-column padding-24">
        <CTAButton
          text="Apply"
          callBackFunc={onClose}
          variant="primary"
          fullWidth={true}
        />
        <a
          className="text-center d-flex gap-8 padding-left-16 padding-right-16 padding-top-8 padding-bottom-8 align-items-center justify-content-center margin-top-10 pointer"
          aria-disabled={count <= 0}
          data-disabled={count <= 0}
          role="button"
          tabIndex={0}
          onClick={() => {
            if (count > 0) {
              push(pathname);
              refresh();
            }
          }}>
          <Close />
          <span>Clear All</span>
        </a>
      </div>
    </>
  );
};
