'use client';

import Cookies from 'js-cookie';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import {
  Curaql,
  Curaql_User,
  DatalayerAnalytics,
  ProductTileType
} from 'services';

import { PGPProps } from './newProductGridPage';
import { BreadCrumbs } from '../components/Breadcrumbs';
import { CartSummary } from '../components/CartSummary';
import { TextBlock } from '../components/TextBlock';
import { StorefrontCarousel } from '../components/StorefrontCarousel';
import { useSiteWideContext } from '../hooks/siteWideContext';
import { AxiosData } from '../hooks/graphs';
import { useDispensaryPathContext } from '../hooks/dispensaryPathContextProvider';
import { productsAnalyticsMap } from '../utils/storefrontCarouselsMap';
import { formatRedirectUrlBirthdate } from '../utils/date';
import { PageState } from '../components/PageState';

type RedirectUrlData = {
  user: Pick<Curaql_User, 'redirectParamUrl'>;
};

export const CartPage = ({ searchParams }: PGPProps) => {
  const { addItemToCart, cart, isLoggedIn, isMobile, userMenuType } =
    useSiteWideContext();
  const { currentPathDispensary } = useDispensaryPathContext();
  const pathname = usePathname();
  const [initialLoad, setInitialLoad] = useState(true);
  const [pushedViewCart, setPushedViewCart] = useState(false);
  const [userParam, setUserParam] = useState<string>('');

  const { data: redirectUrl } = useQuery({
    queryKey: ['redirectUrl'],
    queryFn: async (): Promise<string | null | undefined> => {
      try {
        return axios({
          url: process.env.CURAQL_BASEURL,
          method: 'post',
          data: {
            query: Curaql.GET_CART_DATA
          },
          withCredentials: true
        }).then(({ data }: AxiosData<RedirectUrlData>) => {
          if (data.errors?.length) {
            throw new Error(data.errors[0].message);
          }
          return data.data.user.redirectParamUrl;
        });
      } catch (error) {
        console.error(`Redirect param error: ${error}`);
        DatalayerAnalytics.pushErrorEvent({
          category: 'api',
          location: 'packages/ui/templates/cartPage.tsx',
          description: error as string,
          consolidateDispensary: currentPathDispensary || undefined
        });
        return null;
      }
    },
    enabled: isLoggedIn
  });

  const { data: graphRecommendations, isLoading: graphRecLoad } = useQuery({
    queryKey: ['graphRecommendations', currentPathDispensary.uid, userMenuType],
    queryFn: async (): Promise<ProductTileType[] | null> => {
      try {
        return axios({
          url: process.env.CURAQL_BASEURL,
          method: 'post',
          data: {
            query: Curaql.GET_CART_RECOMMENDATIONS,
            variables: {
              dispensaryUniqueId: currentPathDispensary.uid,
              checkoutId: cart.data?.id
            }
          },
          withCredentials: true
        }).then(({ data }: AxiosData<any>) => {
          if (data.errors?.length) throw new Error(data.errors[0].message);
          return data.data.recommendations;
        });
      } catch (error) {
        console.error(`Cart recommendations error: ${error}`);
        return null;
      }
    },
    enabled: !!(cart.data && currentPathDispensary.uid)
  });

  useEffect(() => {
    if (currentPathDispensary?.location?.state) {
      DatalayerAnalytics.pushPageView({
        page_type: 'cart',
        consolidateDispensary: currentPathDispensary
      });
    }
  }, [pathname, currentPathDispensary]);

  useEffect(() => {
    if (isLoggedIn && redirectUrl) {
      const params = new URLSearchParams(redirectUrl);
      const birthdate = params.get('birthdate');
      if (birthdate && params) {
        params.set('birthdate', formatRedirectUrlBirthdate(birthdate));
      }
      setUserParam(`?${params.toString()}`);
    } else if (userParam !== '') {
      setUserParam('');
    }
  }, [redirectUrl, isLoggedIn]);

  useEffect(() => {
    if (cart.loading !== initialLoad) {
      setInitialLoad(cart.loading);
    }
  }, [cart]);

  useEffect(() => {
    // TODO: test abandon cart. ask justin
    const urlParams = new URLSearchParams(window.location.search);
    const cartCookie = urlParams.get('cartCookie');
    const cookieParams: string =
      typeof cartCookie == 'string' ? decodeURIComponent(cartCookie) : '';
    if (cookieParams) {
      Cookies.set(Curaql.CART_META_KEY, cookieParams);
      const queries = Object.assign({}, searchParams);
      delete queries.cartCookie;
      delete queries.stateSlug;
      delete queries.dispensarySlug;

      const cartCookie = JSON.parse(cookieParams);
      DatalayerAnalytics.pushBasicEvent(
        'resumed_cart',
        {
          cart_id: cartCookie.checkoutId
        },
        currentPathDispensary
      );
    }
  }, []);

  useEffect(() => {
    if (cart.data && currentPathDispensary && !pushedViewCart) {
      DatalayerAnalytics.pushViewCart({
        value: cart.data.priceSummary.total / 100,
        items: cart.data.items,
        consolidateDispensary: currentPathDispensary
      });
      setPushedViewCart(true);
    }
  }, [cart.data, currentPathDispensary]);

  if (!currentPathDispensary?.shopLink) {
    return (
      <PageState
        header={'Dispensary not found.'}
        link={{
          url: '/locations',
          text: 'See our other locations!'
        }}
      />
    );
  }

  return (
    <>
      <BreadCrumbs
        props={{
          breadcrumb: [
            {
              id: 0,
              urlFragment: currentPathDispensary.shopLink,
              breadcrumbName: 'Home'
            }
          ],
          currentPage: 'Cart',
          isLoading: false
        }}
      />
      <CartSummary
        props={
          !initialLoad
            ? {
                enabledLogin: true,
                isLoading: false,
                shopLink: currentPathDispensary.shopLink,
                userParam
              }
            : {
                isLoading: true
              }
        }
      />
      {graphRecommendations?.length ? (
        <StorefrontCarousel
          banners={productsAnalyticsMap(addItemToCart, graphRecommendations)}
          isLoading={graphRecLoad}
          isMobile={isMobile}
          title="You Might Also Like..."
          type="products"
        />
      ) : null}
      <TextBlock
        content={[
          '<small> * Curaleaf prices and promotions are subject to change without notice.Promotions may be limited by the availability of product. Occasionally, our prices and promotions may contain typographical errors, inaccuracies, or omissions.We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information is incorrect at any time without prior notice. This includes the right to cancel your order after it has been submitted.</small>'
        ]}
      />
    </>
  );
};
