'use client';

import IframeResizer from 'iframe-resizer-react';

import { PackageComponentWrapper } from '../PackageComponentWrapper';

export type IFrameProps = {
  src: string;
};

export const IFrame = ({ src }: IFrameProps): JSX.Element | null => {
  if (!src) return null;

  return (
    <PackageComponentWrapper>
      <div className="iframe-resizer">
        <IframeResizer
          src={src}
          style={{ width: '1px', minWidth: '100%', border: 'none' }}
          data-test="iframe-resizer"
          heightCalculationMethod="taggedElement"
          checkOrigin={false}
          scrolling={false}
        />
      </div>
    </PackageComponentWrapper>
  );
};
