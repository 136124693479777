'use client';

import { ProductTileType } from 'services';

import { Loader } from './loader';
import { ProductTile as Default } from './default';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { SiteWideWrapperProps } from '../../hooks/siteWideContext';

import './product-tile.scss';

export type ProductTileProps = {
  addItemToCart: SiteWideWrapperProps['addItemToCart'];
  handleAnalytics?: (productName: string) => void;
  isLoading: false;
  isMobile: boolean;
  product: ProductTileType;
  shopLink: string;
  view?: 'grid' | 'list';
};

export type LoadingProps = {
  forCarousel?: boolean;
  isLoading: true;
  view?: 'grid' | 'list';
};

export const ProductTile = ({
  props
}: {
  props: ProductTileProps | LoadingProps;
}): JSX.Element | null => {
  return props.isLoading ? (
    <Loader {...props} />
  ) : (
    <ErrorBoundary component="product-tile">
      <Default {...props} />
    </ErrorBoundary>
  );
};
