import { Loader } from './loader';
import { OrderConfirmation as Default } from './default';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { Consolidate_Order } from 'services';

import './order-confirmation.scss';

export type EcomOrderConfirmationProps = {
  order: Consolidate_Order | null;
  orderNumber: string;
  isLoading: false;
};

export type LoadingProps = {
  isLoading: true;
};

export const OrderConfirmation = (
  props: EcomOrderConfirmationProps | LoadingProps
): JSX.Element | null =>
  props.isLoading ? (
    <Loader />
  ) : (
    <ErrorBoundary component="order-confirmation">
      <Default {...props} />
    </ErrorBoundary>
  );
