import classNames from 'classnames';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { PackageComponentWrapper } from '../../../PackageComponentWrapper';
import { CuraleafSpinner } from '../../../Loader/CuraleafSpinner';
import {
  Curaql,
  Curaql_LoyaltyTransaction,
  DatalayerAnalytics
} from 'services';

export const PointHistoryView = (): JSX.Element | null => {
  const { isRefetching, isLoading, data } = useQuery({
    queryKey: ['loyaltyHistory'],
    queryFn: async (): Promise<Curaql_LoyaltyTransaction[] | null> => {
      try {
        return axios({
          url: process.env.CURAQL_BASEURL,
          method: 'post',
          data: {
            query: Curaql.GET_LOYALTY_POINT_DATA
          },
          withCredentials: true
        }).then(
          ({
            data
          }: {
            data: {
              data: { loyaltyPointHistory: Curaql_LoyaltyTransaction[] };
              errors?: any;
            };
          }) => {
            if (data.errors?.length) {
              throw new Error(data.errors[0].message);
            }
            return data.data.loyaltyPointHistory;
          }
        );
      } catch (error) {
        console.error('get loyalty history: ', error);
        DatalayerAnalytics.pushErrorEvent({
          category: 'api',
          location:
            'packages/ui/components/Account/Drawer/View/pointHistory.tsx',
          description: error as string
        });
        return null;
      }
    }
  });

  const loading = isLoading && isRefetching;
  return (
    <PackageComponentWrapper>
      <div className="point-history">
        <div className="sidebar-header">
          <h2 className="margin-bottom-32 text-primary h3">Point History</h2>
          <h6 className="body-l">
            Points expire after three months of inactivity.
          </h6>
        </div>
        <div className="sidebar-form">
          {loading ? (
            <CuraleafSpinner />
          ) : (
            data?.length &&
            data.map((history, i) => {
              return (
                <div
                  className={classNames(
                    'history-tile d-flex justify-content-between align-items-center',
                    {
                      'bg-secondary-primary': i % 2 !== 0
                    }
                  )}
                  key={`${history.date}-${history.pointsEarned}-${i}`}>
                  <div className="history-left-col d-flex flex-column">
                    <span className="row subtitle text-primary body-m">
                      {history.date}
                    </span>
                    <span className="row">
                      <p className="subtitle text-primary body-m">
                        <b>{history.locationName}</b>
                      </p>
                    </span>
                  </div>
                  <div className="history-right-col d-flex">
                    <span className="row">
                      <p className="subtitle body-m font-bold text-primary">
                        <b>${history.retailValueFormatted}</b>
                      </p>
                    </span>
                    <div
                      className={classNames('points-tag row body-m', {
                        'bg-success-light text-primary':
                          history.pointsEarned >= 0,
                        'bg-error-light': history.pointsEarned < 0
                      })}>
                      <span className="subtitle">
                        {history.pointsEarnedFormatted}pts
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
