import { Dispatch } from 'react';

import { DutchiePlus_OrderType, StoreCardDispensary } from 'services';
import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import { CuraleafSpinner } from '../../Loader/CuraleafSpinner';
import { StoreCard } from '../../StoreCard';
import { CoordinatesInput } from '../../LocationFinder';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';

type Props = {
  dispensaries?: StoreCardDispensary[];
  lastCoordinates?: CoordinatesInput | null;
  loading: boolean;
  openStoreDrawer: Dispatch<boolean>;
  drawerType: DutchiePlus_OrderType;
  selectedDispensary?: StoreCardDispensary | null;
};

export const StoreList = ({
  dispensaries,
  lastCoordinates,
  loading,
  drawerType,
  openStoreDrawer,
  selectedDispensary
}: Props): JSX.Element | null => {
  let selectedDispensaryWithDistance: StoreCardDispensary | undefined =
    selectedDispensary
      ? {
          ...selectedDispensary,
          location: {
            ...selectedDispensary.location,
            distance: ''
          },
          type: 'locations'
        }
      : undefined;

  let dispensaryList =
    drawerType === 'PICKUP' && selectedDispensary && dispensaries
      ? [...dispensaries]?.filter((disp) => {
          if (disp.deliveryOnly) return false;
          if (disp.uid === selectedDispensary?.uid) {
            // replace cached selectedDispensary from useDispensary to have distance if it exists
            selectedDispensaryWithDistance = disp;
            return false;
          }
          return true;
        })
      : dispensaries;

  const isStateSite =
    process.env.CONTENTSTACK_ENVIRONMENT.includes('ct') ||
    process.env.CONTENTSTACK_ENVIRONMENT.includes('nd');

  if (isStateSite && dispensaryList?.length) {
    dispensaryList = dispensaryList.filter((d) => {
      return process.env.CONTENTSTACK_ENVIRONMENT?.includes(
        `${d.location.state.abbreviation.toLowerCase()}`
      );
    });
  }

  return (
    <PackageComponentWrapper minWidth={false}>
      <ErrorBoundary component="store-drawer-list">
        <div className="store-drawer-list d-flex flex-column">
          {loading ? (
            <div className="store-list-loader">
              <CuraleafSpinner />
            </div>
          ) : (
            <>
              {dispensaryList &&
              selectedDispensaryWithDistance &&
              ((drawerType === 'PICKUP' && !selectedDispensary?.deliveryOnly) ||
                (drawerType === 'DELIVERY' &&
                  selectedDispensary?.deliveryOnly)) ? (
                <StoreCard
                  dispensary={selectedDispensaryWithDistance}
                  drawerType={drawerType}
                  openStoreDrawer={openStoreDrawer}
                  type="drawer"
                />
              ) : null}
              {!lastCoordinates && drawerType === 'DELIVERY' ? (
                <div>
                  <h3 className="mh6 text-primary margin-bottom-20">
                    Please enter a valid address or zip to find dispensaries
                    that deliver to your location.
                  </h3>
                </div>
              ) : dispensaryList?.length ? (
                <>
                  {[...dispensaryList]?.map((dispensary) => {
                    return (
                      <StoreCard
                        dispensary={dispensary}
                        drawerType={drawerType}
                        key={dispensary.friendlyName}
                        openStoreDrawer={openStoreDrawer}
                        type="drawer"
                      />
                    );
                  })}
                </>
              ) : (
                <div>
                  <h3 className="mh6 text-primary margin-bottom-20">
                    No Results Found
                  </h3>
                  <p>
                    We&rsquo;re sorry, it appears that there are no stores
                    currently offering delivery to your location. However, you
                    can opt for convenient pick-up at one of our other
                    accessible locations.
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </ErrorBoundary>
    </PackageComponentWrapper>
  );
};
