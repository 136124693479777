'use client';

import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';

import Toggle from './Toggle';

import { ErrorBoundary } from '../../../utils/nullErrorBoundary';
import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import { PreferencesType } from '../index';

import './store-preference.scss';

type ComponentProps = {
  data: PreferencesType;
};

let currentPreferenceState = [];
const currentPreference = new Subject();

type currentPreferenceChannelType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscribe: (setState: (filters?: any) => void) => void;
  publish: (preferences: string[]) => void;
};

export const currentPreferenceChannel: currentPreferenceChannelType = {
  subscribe: (setState: () => void) => currentPreference.subscribe(setState),
  publish: (preference: string[]) => {
    currentPreferenceState = preference;
    currentPreference.next(currentPreferenceState);
  }
};

export const StorePreference = ({
  data
}: ComponentProps): JSX.Element | null => {
  const { toggles, heading } = data;

  const [currentPreference, setcurrentPreference] = useState<string[]>([]);

  const triggerPreferenceUpdateEvent = () => {
    currentPreferenceChannel.publish(currentPreference);
  };

  useEffect(() => {
    triggerPreferenceUpdateEvent();
  }, [currentPreference]);

  const updateToggles = (name: string) => {
    const newPreference = currentPreference;
    const foundIndex = newPreference.findIndex((element) => element === name);

    if (foundIndex >= 0) {
      newPreference.splice(foundIndex, 1);
    } else {
      newPreference.push(name);
    }
    setcurrentPreference([...newPreference]);
  };

  return (
    <PackageComponentWrapper minWidth={false}>
      <ErrorBoundary component="location-store-preference">
        <div className="location-pref-finder">
          <h3
            className="toggle-heading text-primary title-l"
            data-test="toggleHeading">
            {heading}
          </h3>
          <div className="toggles-container" data-test="togglesContainer">
            {toggles.map((item) => (
              <Toggle
                key={item.id}
                label={item.toggleName}
                tag={item.preference}
                updatePreference={updateToggles}
                data-test="toggleComponent"
                isActive={item.isActive}
              />
            ))}
          </div>
        </div>
      </ErrorBoundary>
    </PackageComponentWrapper>
  );
};
