import { CS_CuraleafPage_RewardsTable } from 'services';
import { Markdown } from '../Markdown';
import { PackageComponentWrapper } from '../PackageComponentWrapper';

import StarIcon from '../../assets/svgs/shared/rewards-star.svg';

import './rewards-table.scss';

export type RewardsTableProps = CS_CuraleafPage_RewardsTable['rewards_table'];

export const RewardsTable = ({
  heading,
  table_row
}: RewardsTableProps): JSX.Element | null => {
  if (!table_row || !table_row.length) return null;

  return (
    <PackageComponentWrapper additionalClass="m-container container-lr">
      <div className="rewards-table">
        <table>
          {heading && (
            <td className="heading">
              <Markdown content={heading} />
            </td>
          )}
          {table_row.map((row, i) => {
            return (
              <tr className="bb-secondary-light" key={i}>
                {row.cell.map((cell, i) => {
                  if (cell.markdown) {
                    return (
                      <td
                        key={i}
                        className={`markdown-cell ${i === 0 ? 'text-left' : 'text-center'} bb-secondary-primary vertical-align-top`}>
                        <div className={`bg-${cell.color}`}>
                          <Markdown content={cell.markdown} />
                        </div>
                      </td>
                    );
                  } else {
                    return (
                      <td
                        className="icon-cell text-center bb-secondary-primary"
                        key={i}>
                        {cell.icon === 'star' ? (
                          <span className={`star-${cell.color}`}>
                            <StarIcon />
                          </span>
                        ) : null}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </table>
      </div>
    </PackageComponentWrapper>
  );
};
