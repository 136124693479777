import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import axios from 'axios';
import { FormikProps } from 'formik';

import { AccountMode, AuthStatus, DatalayerAnalytics } from 'services';
import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';

import { AccountDrawer } from '.';
import { CreateAccountForm } from './View/create';
import { LoginForm } from './View/login';
import { ResetPasswordForm } from './View/reset';
import { ForgotPasswordForm } from './View/forgot';
import { EditPersonalInformationForm } from './View/edit';
import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import { FormConfirmation } from '../../FormBuilder/components/Confirmation';
import { FormStatusProps } from '../../FormBuilder/Account/status';
import { AccountPreferencesFormFields } from '../../../templates/Account/settings';

export type AuthDrawerForm = {
  confirmation: {
    title: string;
    content: string;
    image?: {
      alternativeText?: string;
      width?: number;
      height?: number;
      url: string;
    } | null;
    callback?: () => void;
  };
  error: AuthStatus;
  loading: boolean;
  formik: FormikProps<AccountPreferencesFormFields>;
};

type AuthDrawerProps = {
  form?: AuthDrawerForm;
  visible: boolean;
  setVisibility: Dispatch<SetStateAction<boolean>>;
};

export const AuthDrawer = ({
  form,
  setVisibility,
  visible
}: AuthDrawerProps): JSX.Element | null => {
  const { push } = useRouter();
  const {
    accountDrawerUserEmail,
    accountDrawerMode,
    isLoggedIn,
    setAccountDrawerMode,
    intitiateAuthHandoff
  } = useSiteWideContext();

  const initialMessage: FormStatusProps = {
    type: '',
    text: ''
  };

  const [createHeader, setCreateHeader] = useState('Create an Account');
  const [message, setMessage] = useState(initialMessage);
  const [resetToken, setResetToken] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [referralToken, setReferralToken] = useState('');

  const parseQueryParams = (): {
    [key: string]: { mode: AccountMode; token: string | null; type: string };
  } => {
    const queryParams = new URLSearchParams(location.search);
    const ca = queryParams.get('ca'); // create account
    const ev = queryParams.get('ev'); // email verify
    const lr = queryParams.get('lr'); // loyalty referral
    const pr = queryParams.get('pr'); // password reset
    const si = queryParams.get('si'); // sign in
    const sp = queryParams.get('sp'); // session persist

    return {
      ca: { mode: 'create', token: ca, type: 'ca' },
      ev: { mode: 'login', token: ev, type: 'ev' },
      lr: { mode: 'create', token: lr, type: 'lr' },
      pr: { mode: 'reset', token: pr, type: 'pr' },
      si: { mode: 'login', token: si, type: 'si' },
      sp: { mode: 'set-auth', token: sp, type: 'sp' }
    };
  };

  useEffect(() => {
    const queries = parseQueryParams();
    const match = Object.values(queries).find((query) => query.token);
    if (match) {
      setVisibility(true);
      setAccountDrawerMode(match.mode);
      if (match.token) {
        if (match.type === 'ev') {
          axios
            .post(`${process.env.AUTH_URL}/api/auth/v1/verifyEmail`, {
              token: match.token
            })
            .then(() => {
              DatalayerAnalytics.pushAccountEvent({
                action: 'validate_email'
              });
              setMessage({
                type: 'success',
                text: 'Your email has been verified. Please sign in.'
              });
            })
            .catch(() => {
              setMessage({
                type: 'error',
                text: 'Seems like your token has expired, please try signing in.'
              });
            });
        }
        if (match.type === 'pr') setResetToken(match.token);
        if (match.type === 'lr') setReferralToken(match.token);
        if (!isLoggedIn && match.type === 'ca' && match.token === 'cr') {
          setCreateHeader('Enroll into Best Buds');
        }
        if (match.type === 'sp') {
          // call auth api to set token
          intitiateAuthHandoff(match.token);
          setVisibility(false);
        }
        push(location.origin + location.pathname);
      }
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) setMessage(initialMessage);
  }, [isLoggedIn]);

  const currentMode = () => {
    /* eslint-disable no-fallthrough */
    switch (accountDrawerMode) {
      case 'create': {
        return (
          <CreateAccountForm
            header={createHeader}
            referralToken={referralToken}
          />
        );
      }
      case 'edit': {
        if (form) {
          return (
            <EditPersonalInformationForm
              error={form.error}
              loading={form.loading}
              formik={form.formik}
            />
          );
        }
      }
      case 'forgot': {
        return <ForgotPasswordForm email={accountDrawerUserEmail} />;
      }
      case 'reset': {
        return (
          <ResetPasswordForm
            email={accountDrawerUserEmail}
            setMessage={setMessage}
            setShowConfirmation={setShowConfirmation}
            token={resetToken}
          />
        );
      }
      case 'login':
      /* falls through */
      default: {
        return <LoginForm message={message} />;
      }
    }
  };

  if (showConfirmation && form) {
    return (
      <FormConfirmation
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        confirmation={form.confirmation}
      />
    );
  }

  return (
    <PackageComponentWrapper>
      <AccountDrawer visible={visible} setVisibility={setVisibility}>
        <ErrorBoundary component={`${accountDrawerMode}-form`}>
          {currentMode()}
        </ErrorBoundary>
      </AccountDrawer>
    </PackageComponentWrapper>
  );
};
