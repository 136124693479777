'use client';

import { useSiteWideContext } from '../../hooks/siteWideContext';
import { Markdown } from '../Markdown';
import { PackageComponentWrapper } from '../PackageComponentWrapper';

import './license-bar.scss';

export const LicenseBar = (): JSX.Element | null => {
  const { selectedDispensary } = useSiteWideContext();

  if (!selectedDispensary?.extraFooterText?.length) return null;
  return (
    <PackageComponentWrapper additionalClass="bg-secondary-light">
      <Markdown
        data-test="license-bar"
        additionalClass="license-bar-curaleaf"
        content={selectedDispensary.extraFooterText}
      />
    </PackageComponentWrapper>
  );
};
