'use client';

import { ErrorBoundary } from '../../../utils/nullErrorBoundary';
import { StoresByStateType } from '../index';
import { Accordion } from '../../Accordion';
import { Markdown } from '../../Markdown';
import { StoreCard } from '../../StoreCard';

import './accordion-row.scss';

type AccordionRowProps = { storesPerState: StoresByStateType };

const AccordionRow = ({
  storesPerState
}: AccordionRowProps): JSX.Element | null => {
  const hasMultipleStores = storesPerState.stores.length >= 4;
  const hasSplitHours =
    storesPerState.stores.some((store) => store.todaysHours?.includes(', ')) ??
    false;

  return (
    <ErrorBoundary component="accordion-row">
      <Accordion
        mode="light"
        variant="primary"
        header={
          <h3 data-test="state-name" className="text-primary margin-0">
            {storesPerState.title}
          </h3>
        }
        content={
          storesPerState.curaleaf_com?.copy?.length ? (
            <div className="coming-soon container-uw" data-test="coming-soon">
              <Markdown content={storesPerState.curaleaf_com.copy} />
            </div>
          ) : (
            <div
              data-multiple-stores={hasMultipleStores}
              data-split-hours={hasSplitHours}
              className="store-list"
              data-test="store-dropdown">
              {storesPerState.stores.map((store, i) => (
                <StoreCard
                  key={`${store.friendlyName}-${i}`}
                  dispensary={store}
                  type="location"
                />
              ))}
            </div>
          )
        }
      />
    </ErrorBoundary>
  );
};

export default AccordionRow;
