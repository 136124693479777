'use client';

import ReactPlayer from 'react-player';
import { VideoBlock as Default } from './default';

export type VideoBlockProps = {
  anchor?: string;
  url: string;
};

export const VideoBlock = (props: VideoBlockProps): JSX.Element | null => {
  if (!props.url || (props.url && !ReactPlayer.canPlay(props.url))) return null;

  return <Default {...props} />;
};
