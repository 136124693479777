'use client';

import Carousel from '../Carousel';
import { CarouselDots } from '../Carousel/components';

import { PromoCarouselProps } from './index';
import { useCarousel } from '../../hooks/useCarousel';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { PromoBanner } from './PromoBanner';
import { SmallPromoBanner } from './SmallBanner';

import './promo-carousel.scss';

export const PromoCarousel = ({
  anchor,
  auto_slide_in_seconds = 7,
  banners,
  isMobile,
  variant
}: PromoCarouselProps): JSX.Element | null => {
  const {
    setSelectedItem,
    setClassName,
    selectedItem,
    activeImageIndex,
    setActiveImageIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  } = useCarousel(banners, true, auto_slide_in_seconds);

  const states = {
    setSelectedItem,
    setClassName,
    selectedItem,
    activeImageIndex,
    setActiveImageIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  };

  return (
    <PackageComponentWrapper additionalClass="m-container-desktop">
      <section
        id={anchor}
        data-test="promo-banner"
        className="promo-banner-carousel">
        {banners.length > 1 ? (
          <div className="carousel-wrapper">
            <Carousel data={banners} states={states}>
              {banners.map((item) => (
                <div
                  data-test="carousel-item"
                  key={item.id}
                  className="carousel-el"
                  style={{
                    transform: `translate3d(${-activeImageIndex * 100}%, 0, 0)`
                  }}>
                  {variant === 'large' ? (
                    <PromoBanner {...item} key={item.id} isMobile={isMobile} />
                  ) : (
                    <SmallPromoBanner
                      {...item}
                      key={item.id}
                      isMobile={isMobile}
                    />
                  )}
                </div>
              ))}
            </Carousel>
            <div
              className="promo-carousel-dots container-lr"
              data-test="container-lg">
              <div
                data-test="dots-block"
                className={`slider column d-flex thumbs reverse gx-6`}>
                {banners && banners.length > 1 && (
                  <CarouselDots
                    data={banners}
                    states={states}
                    dotName="sm-dot"
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="carousel">
            {banners &&
              banners.map((item) =>
                variant === 'large' ? (
                  <PromoBanner {...item} key={item.id} isMobile={isMobile} />
                ) : (
                  <SmallPromoBanner
                    {...item}
                    key={item.id}
                    isMobile={isMobile}
                  />
                )
              )}
          </div>
        )}
      </section>
    </PackageComponentWrapper>
  );
};
