import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';

import {
  DatalayerAnalytics,
  DutchiePlus_OrderType,
  GoogleGeoIp,
  StoreCardDispensary
} from 'services';
import { StoreList } from './StoreList';
import { OrderTypeSelection } from '../CartSummary/OrderType';
import { CoordinatesInput, LocationFinder } from '../LocationFinder';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';

import CloseIcon from '../../assets/svgs/close/close-teal.svg';

import './store-drawer.scss';

type Props = {
  setVisibility: Dispatch<SetStateAction<boolean>>;
  visible: boolean;
};

export const StoreDrawer = ({
  setVisibility,
  visible
}: Props): JSX.Element | null => {
  const { isMobile, selectedDispensary, websiteUrl, width } =
    useSiteWideContext();
  const [drawerType, setDrawerType] = useState<DutchiePlus_OrderType>('PICKUP');
  const [dispensaryList, setDispensarylist] = useState<StoreCardDispensary[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [isGeoEnabled, setIsGeoEnabled] = useState(false);
  const [coordinates, setCoordinates] = useState<CoordinatesInput>();
  const selectedDispensaryMatch: StoreCardDispensary | undefined =
    selectedDispensary
      ? dispensaryList.find((d) => d.uid === selectedDispensary.uid)
      : undefined;
  const isDelivery = drawerType === 'DELIVERY';

  const getIPCoords = async () => {
    await GoogleGeoIp.getGoogleCoords()
      .then((res) => {
        const { location } = res;
        if (location && location.lat && location.lng) {
          const coords = {
            latitude: location.lat,
            longitude: location.lng
          };
          setCoordinates(coords);
          fetchDispensaries(coords, isDelivery);
        } else {
          fetchDispensaries(
            selectedDispensary?.location.coordinates,
            isDelivery
          );
        }
      })
      .catch(() => {
        fetchDispensaries(selectedDispensary?.location.coordinates, isDelivery);
      });
  };

  const checkGeolocationEnabled = async () => {
    await navigator.permissions
      ?.query({ name: 'geolocation' })
      .then((result) => {
        if (result.state === 'granted') {
          setIsGeoEnabled(true);
        }
      });
  };

  useEffect(() => {
    if (isGeoEnabled && visible) {
      global.navigator.geolocation.getCurrentPosition(
        (position) => {
          const coords = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          setCoordinates(coords);
          fetchDispensaries(coords, isDelivery);
        },
        () => {
          getIPCoords();
        },
        {
          timeout: 5000
        }
      );
    } else if (visible) {
      getIPCoords();
    }
  }, [global.navigator?.geolocation, visible]);

  useEffect(() => {
    if (!isGeoEnabled) {
      checkGeolocationEnabled();
    }
  }, []);

  const fetchDispensaries = (coords?: CoordinatesInput, delivery?: boolean) => {
    setLoading(true);
    let url = `${websiteUrl}/api/dispensaries/store-drawer`;
    if (coords) {
      url += `?coordinates=${JSON.stringify(coords)}`;
      if (delivery) {
        url += '&forDelivery=true';
      }
    }

    if (url) {
      fetch(url.trim(), {
        next: { revalidate: process.env.DISABLE_REDIS ? 1 : 300 }
      })
        .then((res) => res.json())
        .then(({ data }: { data: StoreCardDispensary[] }) =>
          setDispensarylist(data)
        )
        .catch((error) => {
          DatalayerAnalytics.pushErrorEvent({
            category: 'api',
            location: 'fetchDispensaries',
            description:
              (error as string) || 'Fetch Dispensaries Error in Store Drawer',
            consolidateDispensary: selectedDispensary || undefined
          });
        })
        .finally(() => setLoading(false));
    }
  };

  const coords =
    (coordinates || selectedDispensary?.location.coordinates) ?? undefined;

  return (
    <Sidebar
      visible={visible}
      baseZIndex={720}
      blockScroll
      position={width && width > 480 ? 'left' : 'bottom'}
      className="store-drawer"
      onHide={() => setVisibility(false)}
      maskClassName="store-drawer-mask bg-blur">
      <ErrorBoundary component="store-drawer">
        <div
          className={`close-container d-flex align-items-center ${isMobile ? 'justify-content-between' : 'justify-content-end'}`}>
          {isMobile ? (
            <h2 className="margin-bottom-0 body-xl text-primary uppercase">
              Select Dispensary
            </h2>
          ) : null}
          <button
            aria-label="Close store drawer"
            onClick={() => setVisibility(false)}
            className="close-icon">
            <CloseIcon />
          </button>
        </div>
        <div className="sidebar-header">
          {!isMobile ? (
            <h2 className="margin-bottom-20 h3 text-primary">
              Select Dispensary
            </h2>
          ) : null}
          <OrderTypeSelection
            mode="storedrawer"
            options={{
              drawerType,
              updateDrawerType: (type) => {
                if (coords?.latitude && coords.longitude) {
                  fetchDispensaries(coords, type === 'DELIVERY');
                }
                setDrawerType(type);
              }
            }}
          />
          <div>
            <LocationFinder
              storeDrawerCallback={(coords?: CoordinatesInput) =>
                fetchDispensaries(coords, isDelivery)
              }
              current_location="Use My Current Location"
              input_placeholder="Enter Address or Zip"
              mode="storedrawer"
              search_button="Search"
              storepref={{
                toggles: ['RECREATIONAL', 'MEDICAL'].map((data, id) => ({
                  id,
                  toggleName: data === 'RECREATIONAL' ? 'ADULT-USE' : data,
                  preference: data,
                  isActive: false
                }))
              }}
              setStoreDrawerLoading={setLoading}
            />
          </div>
          <div>
            <StoreList
              selectedDispensary={selectedDispensaryMatch}
              dispensaries={dispensaryList}
              loading={loading}
              drawerType={drawerType}
              openStoreDrawer={setVisibility}
              lastCoordinates={coords}
            />
          </div>
        </div>
      </ErrorBoundary>
    </Sidebar>
  );
};
