import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { LoadingSkeleton } from '../Loader/Skeleton';
import { BrandDescription } from './BrandDescription';
import { SpecificationsAccordion } from './SpecificationsAccordion';
import { StorefrontCarousel } from '../StorefrontCarousel';
import { useSiteWideContext } from '../../hooks/siteWideContext';

export const Loader = (): JSX.Element | null => {
  const { isMobile } = useSiteWideContext();
  const variants = new Array(3).fill(undefined);

  return !isMobile ? (
    <PackageComponentWrapper>
      <div className="product-description container-lr d-flex">
        <div className="right-col col-2">
          <div className="summary">
            <LoadingSkeleton width="20%" height="20px" />
            <h1 className="margin-top-5 margin-bottom-32 h3 text-primary">
              <LoadingSkeleton width="300px" />
            </h1>
            <div className="variant-radio-form d-flex flex-wrap margin-bottom-32">
              {variants.map((_, i) => (
                <div key={i} className="padding-right-10">
                  <LoadingSkeleton height="70px" width="88px" />
                </div>
              ))}
            </div>
            <div className="margin-bottom-32">
              <div className="highlights">
                <LoadingSkeleton height="24px" width="70%" />
              </div>
            </div>
            <div className="d-flex margin-bottom-32 column-gap-16">
              <LoadingSkeleton height="56px" width="120px" />
              <div className="cart-button-col d-flex flex-column">
                <LoadingSkeleton height="56px" width="100%" />
              </div>
            </div>
            <LoadingSkeleton height="115px" width="100%" />
          </div>
        </div>
        <div className="left-col col-2">
          <div className="image-wrapper">
            <LoadingSkeleton width="100%" height="100%" />
          </div>
          <div className="margin-top-16">
            <p>
              <LoadingSkeleton height="24px" width="100%" />
            </p>
            <p>
              <LoadingSkeleton height="24px" width="60%" />
            </p>
          </div>
          <SpecificationsAccordion
            props={{ isLoading: true, onCoaClick: () => {} }}
          />
          <BrandDescription props={{ isLoading: true }} />
        </div>
      </div>
      <PackageComponentWrapper additionalClass="bg-white pdp-lower">
        <StorefrontCarousel
          banners={[]}
          isLoading={true}
          isMobile={isMobile}
          title=""
          type="products"
        />
      </PackageComponentWrapper>
    </PackageComponentWrapper>
  ) : (
    <PackageComponentWrapper>
      <div className="product-description container-lr d-flex">
        <div className="summary">
          <div className="margin-top-10 margin-bottom-5">
            <LoadingSkeleton width="150px" />
          </div>
          <h1 className="margin-bottom-16 h3 text-primary">
            <LoadingSkeleton width="300px" />
          </h1>
          <div className="image-wrapper">
            <LoadingSkeleton width="100%" height="100%" />
          </div>
          <div className="variant-radio-form d-flex flex-wrap margin-bottom-20 margin-top-32">
            {variants.map((_, i) => (
              <div key={i} className="padding-right-10">
                <LoadingSkeleton height="56px" width="120px" />
              </div>
            ))}
          </div>
          <div className="margin-bottom-20">
            <div className="highlights">
              <LoadingSkeleton height="20px" width="320px" />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between pdp-cart-buttons">
        <LoadingSkeleton height="56px" width="120px" />
        <LoadingSkeleton height="56px" width="70%" />
      </div>
      <div className="margin-top-32 product-description container-lr d-flex">
        <LoadingSkeleton height="115px" width="100%" />
        <div className="padding-top-16">
          <p>
            <LoadingSkeleton height="24px" width="320px" />
          </p>
          <p>
            <LoadingSkeleton height="24px" width="128px" />
          </p>
        </div>
        <SpecificationsAccordion
          props={{ isLoading: true, onCoaClick: () => {} }}
        />
      </div>
      <PackageComponentWrapper additionalClass="bg-white pdp-lower">
        <StorefrontCarousel
          banners={[]}
          isLoading={true}
          isMobile={isMobile}
          title=""
          type="products"
        />
      </PackageComponentWrapper>
    </PackageComponentWrapper>
  );
};
