import { JobListing as Default } from './default';

export type JobListingProps = {
  careers: {
    label: string;
    legal?: Legal;
    key?: string;
    children: DepartmentListing[];
  }[];
  title?: string;
  subtitle?: string;
  legal?: Legal;
};

type Legal = {
  text: string;
  link: string;
};

export type Listing = {
  title: string;
  url: string;
  location: string;
};

export type DepartmentListing = {
  department: string;
  listing: Listing[];
};

export const JobListing = (props: JobListingProps): JSX.Element | null => {
  if (!props.careers || !props.careers.length) return null;
  return <Default {...props} />;
};
