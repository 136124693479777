import MedicalIcon from '../assets/svgs/locations/icon-medical.svg';
import DeliveryIcon from '../assets/svgs/store-offers/delivery.svg';
import RecreationalIcon from '../assets/svgs/locations/icon-adult.svg';
import SunIcon from '../assets/svgs/locations/icon-sun.svg';
import ThunderIcon from '../assets/svgs/locations/icon-lightning.svg';

const ICONS = {
  MEDICAL: 'MEDICAL',
  RECREATIONAL: 'RECREATIONAL',
  DELIVERY: 'DELIVERY',
  SUN: 'SUN',
  LIGHTNING: 'LIGHTNING'
};

export const getIcon = (
  tag: string,
  forStoreCards: boolean,
  key?: number
): JSX.Element => {
  const { MEDICAL, RECREATIONAL, DELIVERY, SUN, LIGHTNING } = ICONS;

  switch (tag) {
    case RECREATIONAL:
      return (
        <div data-test="adult-tag" key={key ? key : null}>
          {forStoreCards ? 'Adult-Use' : <RecreationalIcon />}
        </div>
      );
    case MEDICAL:
      return (
        <div data-test="medical-tag" key={key ? key : null}>
          {forStoreCards ? 'Medical' : <MedicalIcon />}
        </div>
      );
    case DELIVERY:
      return (
        <div data-test="delivery-tag" key={key ? key : null}>
          {forStoreCards ? 'Delivery' : <DeliveryIcon />}
        </div>
      );
    case SUN:
      return (
        <div data-test="sun-tag" key={key ? key : null}>
          {!forStoreCards ? <SunIcon /> : null}
        </div>
      );
    case LIGHTNING:
      return (
        <div data-test="lightning-tag" key={key ? key : null}>
          {!forStoreCards ? <ThunderIcon /> : null}
        </div>
      );
    default:
      return <></>;
  }
};
