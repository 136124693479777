import { AuthContextTypes } from 'services';

export type CheckoutMetaData = WebMetaData | KioskMetaData | null;

type WebMetaData = {
  source: 'web';
  accountId: string;
  email: string;
};

type KioskMetaData = {
  source: 'kiosk';
  creditedEmployees: string[];
};

export const checkoutMetaMap = (
  user?: AuthContextTypes['user'],
  emails?: string[]
): CheckoutMetaData => {
  if (user && process.env.IS_KIOSK !== 'true') {
    return {
      source: 'web',
      accountId: user._id,
      email: user.email
    };
  }
  if (emails && process.env.IS_KIOSK === 'kiosk') {
    return {
      source: 'kiosk',
      creditedEmployees: emails
    };
  }
  return null;
};
