import { Sidebar } from 'primereact/sidebar';
import { useState } from 'react';
import classNames from 'classnames';

import { ProductSortFilterProps } from '.';
import { FilterModal } from './FilterModal';
import { SortBySelect } from './Sort';

import GridIcon from '../../../assets/svgs/filter-sort/icon-view-grid.svg';
import ListIcon from '../../../assets/svgs/filter-sort/icon-view-list.svg';
import FilterIcon from '../../../assets/svgs/filter-sort/filter.svg';
import CloseIcon from '../../../assets/svgs/close/modal-close.svg';
import { StorefrontCarousel } from '../../StorefrontCarousel';

export const ProductSortFilter = ({
  filters,
  onSelectFilter,
  pageType,
  productView,
  searchParams,
  setProductView,
  sortBy,
  shopLink
}: ProductSortFilterProps): JSX.Element | null => {
  const [open, setOpen] = useState<boolean>(false);

  const closeDrawer = () => setOpen(false);

  return (
    <>
      <div className="product-sort-filter">
        {pageType === 'SubCategoryPage' ? (
          <div className="subcategory-filter-bar-mobile">
            <StorefrontCarousel
              isLoading={false}
              banners={filters.Subcategories.map((sub) => ({
                onSelectFilter,
                filter: sub
              }))}
              isMobile={true}
              type="subcategories"
            />
          </div>
        ) : null}
        <div className="d-flex justify-content-between align-items-center filter-wrapper">
          <div className="left-side d-flex gap-24">
            <button
              data-test="filter-button"
              className="filter-button body-m font-bold text-primary sm-border-radius bg-white d-flex align-items-center gap-8 pointer"
              onClick={() => setOpen(!open)}>
              <FilterIcon />
              <span className="filter-label">Filters</span>
            </button>
            {pageType === 'SubCategoryPage' ? (
              <div className="subcategory-filter-bar-dt">
                <StorefrontCarousel
                  isLoading={false}
                  banners={filters.Subcategories.map((sub) => ({
                    onSelectFilter,
                    filter: sub
                  }))}
                  isMobile={true}
                  type="subcategories"
                />
              </div>
            ) : null}
          </div>
          <div className="right-side d-flex gap-24">
            <div className="sort-by">
              <SortBySelect
                filters={filters}
                onSelectFilter={onSelectFilter}
                sortBy={sortBy}
              />
            </div>
            <div className="d-flex gap-12 view-button-group">
              <button
                className={classNames('view-button pointer', {
                  selected: productView === 'list'
                })}
                onClick={() => {
                  window.localStorage.setItem('productView pointer', 'list');
                  setProductView('list');
                }}
                aria-label="product list view">
                <ListIcon />
              </button>
              <button
                className={classNames('view-button pointer', {
                  selected: productView === 'grid'
                })}
                onClick={() => {
                  window.localStorage.setItem('productView', 'grid');
                  setProductView('grid');
                }}
                aria-label="product grid view">
                <GridIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        data-test="sidebar"
        header={
          <div className="d-flex header align-items-center justify-content-between">
            <h2 className="body-l font-bold text-black margin-0">Filters</h2>
            <button
              aria-label="Close filter drawer"
              onClick={closeDrawer}
              className="bg-white filter-close-icon pointer">
              <CloseIcon />
            </button>
          </div>
        }
        closeOnEscape
        showCloseIcon={false}
        visible={open}
        position="right"
        className="disable-scroll filter-sidebar"
        onHide={closeDrawer}>
        <FilterModal
          filters={filters}
          isLoading={false}
          onClose={closeDrawer}
          onSelect={onSelectFilter}
          pageType={pageType}
          searchParams={searchParams}
          shopLink={shopLink}
          sortBy={sortBy}
        />
      </Sidebar>
    </>
  );
};
