import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import { LoadingSkeleton } from '../../Loader/Skeleton';
import { LoadingProps } from '.';

export const Loader = ({
  showMoreAbout = true
}: LoadingProps): JSX.Element | null => {
  return (
    <PackageComponentWrapper minWidth={false}>
      <div
        className="brand-description bd-loader container padding-0 padding-top-16 d-flex"
        data-test="bd-withimage">
        <div className="image-col">
          <LoadingSkeleton variant="bd-img" />
        </div>
        <div className="description-col">
          <div>
            <h2 className="title-l text-black">
              <LoadingSkeleton width="100px" />
            </h2>
            <p>
              <LoadingSkeleton width="100%" />
            </p>
            <p>
              <LoadingSkeleton width="100%" />
            </p>
            <p>
              <LoadingSkeleton width="75%" />
            </p>
            {showMoreAbout ? (
              <a className="margin-top-16">
                <LoadingSkeleton width="200px" />
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
