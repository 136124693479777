import { Loader } from './loader';
import { FilterKeys, FilterMenuItem, FilterMenus } from '../index';
import { FilterAccordion as Default } from './default';
import { SortBy } from '../../../../models/types';
import { ErrorBoundary } from '../../../../utils/nullErrorBoundary';
import { EcomProductPageType } from '../../../../models/productPage';

export type FilterAccordionProps = {
  filters: FilterMenus;
  header: FilterKeys | 'Sort';
  isLoading: false;
  onSelect: (
    header: FilterKeys | 'Sort',
    filter: FilterMenuItem,
    sort?: SortBy
  ) => void;
  pageType: EcomProductPageType['type'];
  shopLink: string;
  sortBy: SortBy;
};

export type LoadingProps = {
  isLoading: true;
};

export const FilterAccordion = ({
  props
}: {
  props: FilterAccordionProps | LoadingProps;
}): JSX.Element | null =>
  props.isLoading ? (
    <Loader />
  ) : (
    <ErrorBoundary component="filter-accordion">
      <Default {...props} />
    </ErrorBoundary>
  );
