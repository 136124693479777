'use client';

import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { Dropdown } from 'primereact/dropdown';
import { Params } from 'next/dist/shared/lib/router/utils/route-matcher';

import { useSiteWideContext } from '../hooks/siteWideContext';
import { Consolidate_Dispensary } from 'services';
import { PackageComponentWrapper } from '../components/PackageComponentWrapper';
import { StoreCard } from '../components/StoreCard';
import { sortDispensaries } from '../utils/consolidateDispensaryMap';
import { CuraleafSpinner } from '../components/Loader/CuraleafSpinner';

type KioskHomePageProps = {
  searchParams: Params;
};

export const KioskHomePage = ({ searchParams }: KioskHomePageProps) => {
  const {
    clearSelectedDispensaryIDForKiosk,
    selectedDispensary,
    selectedDispensaryLoading,
    websiteUrl
  } = useSiteWideContext();
  const router = useRouter();
  const [allDispensaries, setAllDispensaries] = useState<
    Consolidate_Dispensary[]
  >([]);
  const [dispensariesLoading, setDispLoading] = useState(true);
  const [selectedDropdownDisp, setDropdownDisp] = useState();

  useEffect(() => {
    if (selectedDispensary) {
      if (searchParams.clear === 'true') {
        clearSelectedDispensaryIDForKiosk();
        router.replace('/', undefined);
      } else {
        router.push(selectedDispensary.shopLink);
      }
    } else {
      setDispLoading(true);
      fetch(`${websiteUrl}/api/dispensaries/store-drawer`, {
        next: { revalidate: process.env.DISABLE_REDIS ? 1 : 300 }
      })
        .then((res) => res.json())
        .then(({ data }: { data: Consolidate_Dispensary[] }) => {
          setAllDispensaries(data.sort((a, b) => sortDispensaries(a, b)));
        })
        .finally(() => setDispLoading(false));
    }
  }, [selectedDispensary, selectedDispensaryLoading]);

  const selectedDispensaryTemplate = (
    option: Consolidate_Dispensary | null,
    props: any
  ) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.friendlyName}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const dispensaryOptionTempplate = (option: Consolidate_Dispensary) => {
    return (
      <div className="flex align-items-center">
        <div>{option.friendlyName}</div>
      </div>
    );
  };

  if (selectedDispensary) {
    return null;
  } else if (!allDispensaries?.length && dispensariesLoading) {
    return <CuraleafSpinner />;
  } else {
    return (
      <PackageComponentWrapper additionalClass="container-sm">
        <Dropdown
          value={selectedDropdownDisp}
          onChange={(e) => setDropdownDisp(e.value)}
          options={allDispensaries}
          optionLabel="friendlyName"
          placeholder="Select a Dispensary"
          filter
          showClear
          valueTemplate={selectedDispensaryTemplate}
          itemTemplate={dispensaryOptionTempplate}
          className="w-full md:w-14rem"
        />
        <div className="margin-top-32">
          {selectedDropdownDisp ? (
            <StoreCard dispensary={selectedDropdownDisp} type="kiosk" />
          ) : null}
        </div>
      </PackageComponentWrapper>
    );
  }
};
