import classNames from 'classnames';
import { useState, useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { InView } from 'react-intersection-observer';

import {
  Consolidate_Product,
  Consolidate_ProductVariant,
  DatalayerAnalytics,
  ProductTileType,
  alphanumericSlugify,
  dutchieFriendlyName,
  slugify
} from 'services';

import { EcomProductDescriptionProps } from './index';
import { CartButton } from './CartButton';
import { BrandDescription } from './BrandDescription';
import { SpecificationsAccordion } from './SpecificationsAccordion';
import {
  ProductDetailsAccordion,
  ProductDetailsAccordionProps
} from './ProductDetailsAccordion';
import Carousel from '../Carousel';
import { CarouselDots } from '../Carousel/components';
import { StrainTag } from '../ProductTile/StrainTag';
import { useCarousel } from '../../hooks/useCarousel';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { ecommerceSanitizeQuery } from '../../utils/url';
import { PriceSection } from '../PriceSection';
import { SpecialOffersTag } from './SpecialOffersTag';
import { Markdown } from '../Markdown';
import { QuantitySelect } from '../QuantitySelect';
import { TextBlock } from '../TextBlock';
import { useDispensaryPathContext } from '../../hooks/dispensaryPathContextProvider';
import { PageState } from '../PageState';
import { StorefrontCarousel } from '../StorefrontCarousel';
import { productsAnalyticsMap } from '../../utils/storefrontCarouselsMap';
import { UNIT_MAP } from '../../utils/constants';
import { SwitchMenuDialog } from '../SwitchMenuDialog';
import { moodiDayScript } from '../../utils/moodiDayScript';
import {
  fetchProductsForRecommendations,
  RecommendationCarousel
} from '../../utils/getPdpRecs';

import SelectedIcon from '../../assets/svgs/shared/icon-selected.svg';
import ProductDefaultImage from '../../assets/images/product-placeholder.png';

import './product-description.scss';

export type ProductSpecificationItemProps = {
  title: string;
  items: {
    key: string;
    value?: string | string[];
    hasRange?: boolean;
  }[];
  showBar: boolean;
};

type ProductSpecificationItem = 'detail' | 'profile' | 'terpene';

export type ProductSpecificationProps = {
  [key in ProductSpecificationItem]: ProductSpecificationItemProps;
};

export const calculateBarWidth = (
  item: ProductSpecificationItemProps['items'][number],
  section: ProductSpecificationItemProps,
  variants?: Consolidate_ProductVariant[]
) => {
  if (!item.value || !section) return 0;
  const ounce = 28350;
  const gram = 1000;

  let mgRangeMin = 0;
  if (item.value.includes('mg/g')) return 0;
  if (item.value.includes('-') && typeof item.value == 'string') {
    const rangeValues = item.value.split('-');
    if (rangeValues[0]?.includes('%')) {
      return +(rangeValues[0].split('%')[0] || 0);
    } else if (rangeValues[0]?.includes('mg')) {
      mgRangeMin = +(rangeValues[0].split('mg')[0] || 0);
    } else {
      return 0;
    }
  }
  if (variants && item.value.includes('mg')) {
    if (variants[0]) {
      const splitValue =
        typeof item.value === 'string' && item.value.replace(/[^.0-9 ]/g, '');
      if (variants[0].option.includes('mg')) {
        const splitVariant = variants[0].option.split('mg')[0];
        if (splitVariant) {
          return (+splitValue / +splitVariant) * 100;
        }
      }
      if (variants[0].option.includes('g')) {
        /*
          To convert from grams (g) to milligrams (mg), you multiply the amount in grams by 1,000.
          Example: 3 g = 3 * 1,000 = 3,000 mg
       */
        const splitVariant = variants[0].option.split('g')[0];
        const totalWeight = splitVariant ? +splitVariant * gram : 0;
        if (mgRangeMin) {
          return (mgRangeMin / totalWeight) * 100;
        } else {
          return (+splitValue / totalWeight) * 100;
        }
      }
      if (variants[0].option.includes('oz')) {
        /*
          To convert from ounces (oz) to milligrams (mg), you multiply the amount in ounces by 28,349.5 (approximately).
          Example: 4 oz ≈ 4 * 28,349.5 ≈ 113,398 mg (approximately)
        */
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const splitVariant = (variants[0].option.split('oz')[0] as any)
          .split('/')
          .map(Number);
        const splitValueTotal =
          splitVariant.length > 1
            ? splitVariant[0] / splitVariant[1]
            : splitVariant[0];
        const totalWeight = splitValueTotal * ounce;
        if (mgRangeMin) {
          return (mgRangeMin / totalWeight) * 100;
        } else {
          return (+splitValue / totalWeight) * 100;
        }
      }
    }
  }
  if (item.value.includes('%') && typeof item.value === 'string') {
    return +(item.value.split('%')[0] || 0);
  }
  return 0;
};

const specificationsMap = (product: Consolidate_Product) => {
  const {
    category,
    cannabinoids,
    effects,
    strainType,
    subcategory,
    potencyThc,
    potencyCbd,
    terpenes
  } = product;

  const mappedData: ProductSpecificationProps = {
    profile: {
      title: 'Strength Profile',
      items: [],
      showBar: false
    },
    terpene: {
      title: 'Terpenes',
      items: [],
      showBar: false
    },
    detail: {
      title: 'Specifications',
      items: [],
      showBar: false
    }
  };

  // SPECIFICATIONS
  if (strainType) {
    mappedData.detail.items.push({
      key: 'lineage',
      value: dutchieFriendlyName(strainType)
    });
  }
  if (category && category !== 'NOT_APPLICABLE') {
    mappedData.detail.items.push({
      key: 'Category',
      value: dutchieFriendlyName(category)
    });
  }
  if (subcategory && subcategory !== 'DEFAULT') {
    //todo map in shop menu categories for CT extracts/concentrates
    mappedData.detail.items.push({
      key: 'Subcategory',
      value: dutchieFriendlyName(subcategory)
    });
  }
  if (effects?.length) {
    mappedData.detail.items;
    mappedData.detail.items.push({
      key: 'Effects',
      value: effects.map((effect) => {
        if (effect === effects[effects.length - 1]) {
          return effect.displayName;
        } else {
          return effect.displayName + ', ';
        }
      })
    });
  }

  // STRENGTH PROFILE
  if (potencyCbd?.formatted) {
    if (potencyCbd.range && potencyCbd.range.length > 1) {
      mappedData.profile.items.push({
        key: 'CBD',
        value: potencyCbd.formatted,
        hasRange: true
      });
    } else {
      mappedData.profile.items.push({
        key: 'CBD',
        value: potencyCbd.formatted
      });
    }
  }
  if (potencyThc?.formatted) {
    if (potencyThc.range && potencyThc.range.length > 1) {
      mappedData.profile.items.push({
        key: 'THC',
        value: potencyThc.formatted,
        hasRange: true
      });
    } else {
      mappedData.profile.items.push({
        key: 'THC',
        value: potencyThc.formatted
      });
    }
  }
  if (cannabinoids?.length) {
    const items = cannabinoids.reduce(
      (arr: ProductSpecificationProps['profile']['items'], canna) => {
        if (canna?.unit && canna?.cannabinoid?.name) {
          arr.push({
            key: canna.cannabinoid.name,
            value: `${canna.value}${UNIT_MAP[canna.unit]}`
          });
        }
        return arr;
      },
      []
    );
    mappedData.profile.items.push(...items);
  }

  // TERPENES
  if (terpenes?.length) {
    const items = terpenes.reduce(
      (arr: ProductSpecificationProps['terpene']['items'], terp) => {
        if (terp?.value && terp?.unitSymbol && terp?.terpene?.name) {
          arr.push({
            key: terp.terpene.name,
            value: `${terp.value}${terp.unitSymbol}`
          });
        }
        return arr;
      },
      []
    );
    mappedData.terpene.items.push(...items);
  }

  (['profile', 'terpene'] as (keyof ProductSpecificationProps)[]).map((k) => {
    const key = k;
    // Sort Items by Percent Value
    mappedData[key].items = mappedData[key].items.sort((a, b) => {
      if (
        a.value &&
        b.value &&
        typeof a.value === 'string' &&
        typeof b.value === 'string'
      ) {
        const aValue = calculateBarWidth(
          a,
          mappedData.profile,
          product.variants
        );
        const bValue = calculateBarWidth(
          b,
          mappedData.profile,
          product.variants
        );
        return bValue - aValue;
      }
      return 0;
    });
    // Check if we need to show a bar to represent the value
    mappedData[key].showBar = !!mappedData[key].items.length;
  });

  return { specifications: mappedData, variants: product.variants };
};

export const ProductDescription = ({
  coaUrl,
  product,
  productSpecials
}: EcomProductDescriptionProps): JSX.Element | null => {
  const { addItemToCart, isMobile, userMenuType, websiteUrl } =
    useSiteWideContext();
  const { currentPathDispensary, defaultMenuType } = useDispensaryPathContext();

  const [selectedVariant, setSelectVariant] =
    useState<Consolidate_ProductVariant | null>(
      product.variants[0] ? product.variants[0] : null
    );
  const [showMenuSwitchDialog, setMenuSwitchDialog] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);
  const [recommendations, setRecommendations] =
    useState<RecommendationCarousel>({
      all: [],
      category: []
    });
  const [showStickyCartButtons, setShowStickyCartButtons] = useState(true);

  const terpenesContent =
    product.terpenes && product.terpenes.length
      ? product.terpenes
          .filter((terp) => terp?.value && terp.value > 0)
          .reduce((arr: ProductDetailsAccordionProps['content'], terp) => {
            if (terp?.terpene) {
              arr.push({
                value: `${terp.terpene.name}: ${terp.value}${terp.unitSymbol}`,
                tooltip: terp.terpene.description
                  ? terp.terpene.description
                  : undefined
              });
            }
            return arr;
          }, [])
      : [];

  const productImages = product.images.map((img, i) => ({
    ...img,
    id: `${i}`
  }));

  useEffect(() => {
    fetchProductsForRecommendations(
      websiteUrl,
      [product.id],
      currentPathDispensary.retailerId,
      defaultMenuType,
      setRecommendations,
      'ProductPage'
    );
  }, []);

  const {
    setSelectedItem,
    setClassName,
    selectedItem,
    activeImageIndex,
    setActiveImageIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  } = useCarousel(productImages, false, 7);

  const states = {
    setSelectedItem,
    setClassName,
    selectedItem,
    activeImageIndex,
    setActiveImageIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  };

  const unitMap = (unit: string) => {
    switch (unit) {
      case 'MILLIGRAMS':
        return 'mg';
      case 'MILLIGRAMS_PER_GRAM':
        return 'mg/g';
      case 'MILLIGRAMS_PER_ML':
        return 'mg/ml';
      case 'PERCENTAGE':
        return '%';
    }
  };

  const highlightsContent = () => {
    const content = [];
    if (product.strainType && product.strainType !== 'NOT_APPLICABLE') {
      content.push({
        value: dutchieFriendlyName(product.strainType) // TODO: Check with HIGH_CBD etc
      });
    }
    const flowerEquivalent = product.variants[0]?.flowerEquivalent;
    if (
      flowerEquivalent &&
      !currentPathDispensary.storefrontMap?.hideFlowerEquivalent
    ) {
      content.push({
        value: `Flower Equivalent: ${flowerEquivalent.value}${flowerEquivalent.unit}`
      });
    }
    if (product?.potencyThc?.formatted) {
      const thcName = currentPathDispensary.shopLink.includes('curaleaf-ct-')
        ? 'Total THC'
        : 'THC';
      content.push({
        value: `${product.potencyThc.formatted} ${thcName}`
      });
    }
    if (product?.cannabinoids && product.cannabinoids.length) {
      product.cannabinoids.map((canna) => {
        if (!canna?.cannabinoid?.name || !canna.unit || !canna.value) return;

        content.push({
          value: `${canna.value}${unitMap(canna.unit)} ${
            canna.cannabinoid.name.split(' ')[0]
          }`,
          tooltip: canna.cannabinoid.description || ''
        });
      });
    }
    return content;
  };

  const hideEffects = process.env.HIDE_EFFECTS === 'true';
  const effectsContent =
    !hideEffects && product.effects
      ? product.effects.map((effect) => ({
          value: effect.displayName
        }))
      : [];

  const hasHighlights =
    highlightsContent().length ||
    terpenesContent.length ||
    (effectsContent && effectsContent.length);

  useEffect(() => {
    setQuantity(1);
  }, [selectedVariant, product]);

  useEffect(() => {
    if (product.variants[0]) {
      setSelectVariant(product.variants[0]);
    }
  }, [product]);

  useEffect(() => {
    if (!loading) {
      // MOODIDAY after products load in
      moodiDayScript();
    }
  }, [loading]);

  if (!selectedVariant) {
    return (
      <PageState
        header="Product not available."
        showPopularProducts={true}
        link={{
          url: currentPathDispensary.shopLink,
          text: 'Check out our other products!'
        }}
      />
    );
  }

  let buttonText = 'Add To Cart';
  let disableButton = false;
  let quantityMax = selectedVariant.quantity;
  if (currentPathDispensary?.pauseEcommerce) {
    buttonText = currentPathDispensary.pauseEcommerce;
    disableButton = true;
    quantityMax = 0;
  } else if (
    currentPathDispensary.menuTypes.length > 1 &&
    (!product.menuTypes.includes(userMenuType) ||
      !currentPathDispensary.menuTypes.includes(userMenuType))
  ) {
    buttonText = `${
      userMenuType === 'RECREATIONAL' ? 'Medical Only' : 'Adult-Use Only'
    }`;
    disableButton = true;
  }

  const oppsiteButtonText =
    userMenuType === 'MEDICAL' ? 'Adult-Use' : 'Medical';

  const image = product.images[0]?.url || ProductDefaultImage;

  const onCoaClick = () => {
    const splitCoas = coaUrl!.split('/');
    DatalayerAnalytics.pushBasicEvent(
      'view_coa',
      {
        item_name: product.name, // name of the product being analyzed/described in the COA
        item_brand: product.brand?.name, // brand of analyzed item
        item_category: product.category, // category of item clicked
        file_name: splitCoas[splitCoas.length - 1], // the name of the file
        link_url: coaUrl // URL of the file
      },
      currentPathDispensary
    );
  };

  if (!isMobile) {
    return (
      <>
        <SwitchMenuDialog
          show={showMenuSwitchDialog}
          setShow={setMenuSwitchDialog}
        />
        <PackageComponentWrapper>
          <div className="product-description container-lr d-flex">
            <div className="right-col col-2">
              <div className="summary">
                {product.brand ? (
                  <div className="margin-bottom-5">
                    <Link
                      data-test="brand"
                      className="underline body-m"
                      href={ecommerceSanitizeQuery(
                        `${currentPathDispensary.shopLink}/brands/${alphanumericSlugify(product.brand.name)}`
                      )}>
                      {product.brand.name}
                    </Link>
                  </div>
                ) : null}
                <h1 className="margin-bottom-5 h3">{product.name}</h1>
                <div
                  className="moodi-day-rating-overlay margin-bottom-16"
                  data-review-link="true"
                  data-productid={product.id}></div>
                <div className="variant-radio-form d-flex flex-wrap margin-bottom-32">
                  {product.variants.map((variant) => (
                    <div
                      className="variant-option margin-bottom-10 margin-right-16"
                      key={variant.id}>
                      {selectedVariant.id === variant.id ||
                      product.variants.length === 1 ? (
                        <div className="selected-icon">
                          <SelectedIcon />
                        </div>
                      ) : null}
                      <input
                        type="radio"
                        aria-labelledby={variant.id}
                        value={variant.option}
                        onChange={() => setSelectVariant(variant)}
                      />
                      <label
                        data-has-variant={variant.option !== 'N/A'}
                        id={variant.id}
                        data-test="variant"
                        className={classNames(
                          'd-flex align-items-center justify-content-center',
                          {
                            selected:
                              selectedVariant.id === variant.id ||
                              product.variants.length === 1
                          }
                        )}
                        onClick={() => setSelectVariant(variant)}>
                        {variant.option !== 'N/A' ? (
                          <span
                            className={classNames('body-s font-medium', {
                              uppercase: product.category === 'APPAREL'
                            })}>
                            {variant.option}
                          </span>
                        ) : null}
                        <PriceSection
                          variant={variant}
                          type="pdp"
                          isMobile={isMobile}
                        />
                      </label>
                    </div>
                  ))}
                </div>
                {hasHighlights ? (
                  <div className="margin-bottom-32">
                    <div className="highlights">
                      {highlightsContent().length && product.strainType ? (
                        <ProductDetailsAccordion
                          content={highlightsContent()}
                          icon={product.strainType.toLowerCase()}
                          variant="bullet"
                        />
                      ) : null}
                      {terpenesContent.length ? (
                        <ProductDetailsAccordion
                          content={terpenesContent}
                          icon="terpenes"
                          variant="comma"
                        />
                      ) : null}
                      {!hideEffects && effectsContent.length ? (
                        <ProductDetailsAccordion
                          content={effectsContent}
                          icon="effects"
                          variant="comma"
                        />
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className="d-flex margin-bottom-32 column-gap-16">
                  <QuantitySelect
                    value={quantity}
                    max={quantityMax}
                    onDecrement={async () => await setQuantity(quantity - 1)}
                    onIncrement={async () => await setQuantity(quantity + 1)}
                  />
                  <div className="cart-button-col d-flex flex-column">
                    <CartButton
                      size="lg"
                      variant="secondary"
                      loading={loading}
                      data-test="cart-btn"
                      showCartIcon={false}
                      text={buttonText}
                      disabled={disableButton}
                      callBackFunc={async () => {
                        if (addItemToCart) {
                          setLoading(true);
                          await addItemToCart(
                            {
                              product,
                              quantity,
                              variant: selectedVariant,
                              analyticsContext: {
                                list: {
                                  id: 'pdp_page',
                                  name: 'PDP Page'
                                }
                              },
                              baseCartUrl:
                                window.location.origin +
                                currentPathDispensary.shopLink
                            },
                            () => {
                              setLoading(false);
                            }
                          );
                        }
                      }}
                    />
                    {!product.menuTypes.includes(userMenuType) &&
                    currentPathDispensary.menuTypes.length > 1 ? (
                      <div className="switch-menu-button text-right">
                        <a
                          role="button"
                          tabIndex={0}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              setMenuSwitchDialog(true);
                            }
                          }}
                          onClick={() => setMenuSwitchDialog(true)}
                          className="underline text-primary pointer">
                          Switch to {oppsiteButtonText} menu?
                        </a>
                      </div>
                    ) : null}
                  </div>
                </div>
                {productSpecials.length ? (
                  <SpecialOffersTag
                    productSpecials={productSpecials}
                    shopLink={currentPathDispensary.shopLink}
                  />
                ) : null}
                {process.env.NEXT_PUBLIC_MOODIDAY_CLIENT_ID ? (
                  <div id="moodi-day-widget" data-productid={product.id} />
                ) : null}
              </div>
            </div>
            <div className="left-col col-2">
              <div className="image-wrapper padding-bottom-16">
                {product.images.length > 1 ? (
                  <>
                    <StrainTag strain={product.strainType} />
                    <Carousel data={productImages} states={states}>
                      {productImages
                        .filter((img) => img)
                        .map((img, i) => {
                          return (
                            <div
                              key={img.url}
                              className="carousel-el"
                              style={{
                                transform: `translate3d(${
                                  -activeImageIndex * 100
                                }%, 0, 0)`
                              }}>
                              <Image
                                src={img.url}
                                alt={product.name}
                                priority={i === 0}
                                loading={i === 0 ? 'eager' : 'lazy'}
                                fill={true}
                                sizes="100%"
                                style={{
                                  objectFit: 'contain',
                                  objectPosition: 'center'
                                }}
                              />
                            </div>
                          );
                        })}
                    </Carousel>
                    <div className="storefront-banner-dots thumbs margin-bottom-16">
                      <CarouselDots
                        data={productImages}
                        states={states}
                        dotName="sm-dot"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <StrainTag strain={product.strainType} />
                    <Image
                      src={image}
                      alt={product.name}
                      priority
                      fill={true}
                      sizes="100%"
                      style={{
                        objectFit: 'contain'
                      }}
                    />
                  </>
                )}
              </div>
              {product.descriptionHtml ? (
                <Markdown
                  additionalClass="margin-top-16"
                  content={product.descriptionHtml}
                />
              ) : null}
              {product ? (
                <SpecificationsAccordion
                  props={{
                    isLoading: false,
                    coaUrl,
                    ...specificationsMap(product),
                    onCoaClick
                  }}
                />
              ) : null}
            </div>
          </div>
        </PackageComponentWrapper>
        <PackageComponentWrapper additionalClass="pdp-lower">
          <div className="gap-24 d-flex flex-column">
            {product && recommendations && Object.keys(recommendations).length
              ? Object.keys(recommendations).map((key, i) => {
                  const recommendation: ProductTileType[] =
                    recommendations[key as keyof typeof recommendations];
                  if (recommendation.length >= 4) {
                    return (
                      <StorefrontCarousel
                        key={`${product.category}-${i}`}
                        banners={productsAnalyticsMap(
                          addItemToCart,
                          recommendation
                        )}
                        isLoading={false}
                        link={
                          key === 'category'
                            ? `${
                                currentPathDispensary.shopLink
                              }/categories/${slugify(product.category)}`
                            : ''
                        }
                        isMobile={isMobile}
                        title={
                          key === 'category'
                            ? `More ${dutchieFriendlyName(product.category)}`
                            : 'Similar Products'
                        }
                        type="products"
                      />
                    );
                  }
                })
              : null}
          </div>
        </PackageComponentWrapper>
        <TextBlock
          content={[
            '<p className="body-sm">* Curaleaf prices and promotions are subject to change without notice. Promotions may be limited by the availability of product. Occasionally, our prices and promotions may contain typographical errors, inaccuracies, or omissions. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information is incorrect at any time without prior notice. This includes the right to cancel your order after it has been submitted.</p>'
          ]}
        />
      </>
    );
  }

  return (
    <>
      <SwitchMenuDialog
        show={showMenuSwitchDialog}
        setShow={setMenuSwitchDialog}
      />
      <PackageComponentWrapper>
        <div className="product-description container-lr d-flex">
          <div className="summary">
            {product.brand ? (
              <div className="margin-top-10 margin-bottom-5">
                <Link
                  data-test="brand"
                  className="underline body-m"
                  href={ecommerceSanitizeQuery(
                    `${currentPathDispensary.shopLink}/brands/${alphanumericSlugify(product.brand.name)}`
                  )}>
                  {product.brand.name}
                </Link>
              </div>
            ) : null}
            <h1 className="margin-bottom-16 h3 text-primary">{product.name}</h1>
            <div
              className="moodi-day-rating-overlay margin-bottom-16"
              data-review-link="true"
              data-productid={product.id}></div>
            <div className="image-wrapper padding-bottom-16">
              {product.images.length > 1 ? (
                <>
                  <StrainTag strain={product.strainType} />
                  <Carousel data={productImages} states={states}>
                    {product.images.map((img, i) => {
                      return (
                        <div
                          key={img.url}
                          className="carousel-el"
                          style={{
                            transform: `translate3d(${
                              -activeImageIndex * 100
                            }%, 0, 0)`
                          }}>
                          <Image
                            src={product.images[i]?.url || ''}
                            fill={true}
                            sizes="100%"
                            alt={product.name}
                            priority={i === 0}
                            loading={i === 0 ? 'eager' : 'lazy'}
                            style={{
                              objectFit: 'contain',
                              objectPosition: 'center'
                            }}
                          />
                        </div>
                      );
                    })}
                  </Carousel>
                  <div className="storefront-banner-dots thumbs margin-bottom-16">
                    <CarouselDots
                      data={productImages}
                      states={states}
                      dotName="sm-dot"
                    />
                  </div>
                </>
              ) : (
                <>
                  <StrainTag strain={product.strainType} />
                  <Image
                    src={image}
                    fill={true}
                    sizes="100%"
                    alt={product.name}
                    priority
                    style={{
                      objectFit: 'contain'
                    }}
                  />
                </>
              )}
            </div>
            <div className="variant-radio-form d-flex flex-wrap margin-bottom-20 margin-top-32">
              {product.variants.map((variant) => (
                <div
                  className="variant-option margin-bottom-10 margin-right-16"
                  key={variant.id}>
                  {selectedVariant.id === variant.id ||
                  product.variants.length === 1 ? (
                    <div className="selected-icon">
                      <SelectedIcon />
                    </div>
                  ) : null}
                  <input
                    type="radio"
                    aria-labelledby={variant.id}
                    value={variant.option}
                    onChange={() => setSelectVariant(variant)}
                  />
                  <label
                    id={variant.id}
                    data-test="variant"
                    className={classNames(
                      'd-flex align-items-center justify-content-center',
                      {
                        selected:
                          selectedVariant.id === variant.id ||
                          product.variants.length === 1
                      }
                    )}
                    onClick={() => setSelectVariant(variant)}>
                    <span
                      className={classNames('body-m text-black', {
                        uppercase: product.category === 'APPAREL'
                      })}>
                      {variant.option}
                    </span>
                    <PriceSection
                      variant={variant}
                      type="pdp"
                      isMobile={isMobile}
                    />
                  </label>
                </div>
              ))}
            </div>
            {hasHighlights ? (
              <div className="margin-bottom-20">
                <div className="highlights">
                  {product.strainType && highlightsContent().length ? (
                    <ProductDetailsAccordion
                      content={highlightsContent()}
                      icon={product.strainType.toLowerCase()}
                      variant="bullet"
                    />
                  ) : null}
                  {terpenesContent.length ? (
                    <ProductDetailsAccordion
                      content={terpenesContent}
                      icon="terpenes"
                      variant="comma"
                    />
                  ) : null}
                  {!hideEffects && effectsContent.length ? (
                    <ProductDetailsAccordion
                      content={effectsContent}
                      icon="effects"
                      variant="comma"
                    />
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <InView
          onChange={(inView) => setShowStickyCartButtons(!inView)}
          initialInView={false}>
          {({ ref }) => (
            <div ref={ref} className={classNames('d-flex pdp-cart-buttons')}>
              <QuantitySelect
                value={quantity}
                max={quantityMax}
                onDecrement={async () => await setQuantity(quantity - 1)}
                onIncrement={async () => await setQuantity(quantity + 1)}
              />
              <CartButton
                size="lg"
                variant="secondary"
                loading={loading}
                data-test="cart-btn"
                text={buttonText}
                disabled={disableButton}
                callBackFunc={async () => {
                  if (addItemToCart) {
                    setLoading(true);
                    await addItemToCart(
                      {
                        product,
                        quantity,
                        variant: selectedVariant,
                        analyticsContext: {
                          list: {
                            id: 'pdp_page',
                            name: 'PDP Page'
                          }
                        },
                        baseCartUrl:
                          window.location.origin +
                          currentPathDispensary.shopLink
                      },
                      () => {
                        setLoading(false);
                      }
                    );
                  }
                }}
              />
            </div>
          )}
        </InView>
        <div className="margin-top-32 product-description container-lr d-flex">
          {productSpecials.length ? (
            <div className="margin-bottom-32">
              <SpecialOffersTag
                productSpecials={productSpecials}
                shopLink={currentPathDispensary.shopLink}
              />
            </div>
          ) : null}
          {product.descriptionHtml ? (
            <Markdown content={product.descriptionHtml} />
          ) : null}
          {product ? (
            <SpecificationsAccordion
              props={{
                isLoading: false,
                coaUrl,
                ...specificationsMap(product),
                onCoaClick
              }}
            />
          ) : null}
          {product.brand?.name ? (
            <BrandDescription
              props={{
                productBrand: product.brand,
                isLoading: false
              }}
            />
          ) : null}
        </div>
        {process.env.NEXT_PUBLIC_MOODIDAY_CLIENT_ID ? (
          <div
            id="moodi-day-widget"
            data-review-link="true"
            data-productid={product.id}
          />
        ) : null}
        <PackageComponentWrapper additionalClass="bg-white pdp-lower">
          <div className="gap-24 d-flex flex-column">
            {product && recommendations && Object.keys(recommendations).length
              ? Object.keys(recommendations).map((key, i) => {
                  const recommendation: ProductTileType[] =
                    recommendations[key as keyof typeof recommendations];
                  if (recommendation.length >= 4) {
                    return (
                      <StorefrontCarousel
                        key={`${product.category}-${i}`}
                        banners={productsAnalyticsMap(
                          addItemToCart,
                          recommendation
                        )}
                        isMobile={isMobile}
                        isLoading={false}
                        link={
                          key === 'category'
                            ? `${
                                currentPathDispensary.shopLink
                              }/categories/${slugify(product.category)}`
                            : ''
                        }
                        title={
                          key === 'category'
                            ? `More ${dutchieFriendlyName(product.category)}`
                            : 'Similar Products'
                        }
                        type="products"
                      />
                    );
                  }
                })
              : null}
          </div>
        </PackageComponentWrapper>
        <TextBlock
          content={[
            '<small> * Curaleaf prices and promotions are subject to change without notice.Promotions may be limited by the availability of product. Occasionally, our prices and promotions may contain typographical errors, inaccuracies, or omissions.We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information is incorrect at any time without prior notice. This includes the right to cancel your order after it has been submitted.</small>'
          ]}
        />
        {showStickyCartButtons ? (
          <div className={classNames('d-flex sticky-cart-buttons')}>
            <QuantitySelect
              value={quantity}
              max={quantityMax}
              onDecrement={async () => await setQuantity(quantity - 1)}
              onIncrement={async () => await setQuantity(quantity + 1)}
            />
            <CartButton
              size="lg"
              variant="secondary"
              loading={loading}
              data-test="cart-btn"
              text={buttonText}
              disabled={disableButton}
              callBackFunc={async () => {
                if (addItemToCart) {
                  setLoading(true);
                  await addItemToCart(
                    {
                      product,
                      quantity,
                      variant: selectedVariant,
                      analyticsContext: {
                        list: {
                          id: 'pdp_page',
                          name: 'PDP Page'
                        }
                      },
                      baseCartUrl:
                        window.location.origin + currentPathDispensary.shopLink
                    },
                    () => {
                      setLoading(false);
                    }
                  );
                }
              }}
            />
          </div>
        ) : null}
      </PackageComponentWrapper>
    </>
  );
};
