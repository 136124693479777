import { Loader } from './loader';
import { SubcategoryTile as Default } from './default';
import { ErrorBoundary } from '../../../../utils/nullErrorBoundary';
import { FilterMenuItem } from '../../../NewEcomProductGrid/ProductSortFilter/FilterModal';
import { EcomProductGridProps } from '../../../NewEcomProductGrid';

import './subcategory-tile.scss';

export type SubcategoryTileProps = {
  isLoading: false;
  filter: FilterMenuItem;
  onSelectFilter: EcomProductGridProps['onSelectFilter'];
};

type LoadingProps = {
  isLoading: true;
};

export const SubcategoryTile = ({
  props
}: {
  props: SubcategoryTileProps | LoadingProps;
}): JSX.Element | null => {
  return props.isLoading ? (
    <Loader />
  ) : (
    <ErrorBoundary component="subcategory-tile">
      <Default props={props} />
    </ErrorBoundary>
  );
};
