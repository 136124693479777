'use client';

import Carousel from '../Carousel';
import { PromoCarouselDots } from '../Carousel/components';

import { useCarousel } from '../../hooks/useCarousel';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { HeroBanner, HeroBannerProps } from './banner';

import '../PromoCarousel/promo-carousel.scss';

export type HeroBannerCarouselProps = {
  anchor: string;
  banners: HeroBannerProps[];
  isMobile: boolean;
};

export const HeroBannerCarousel = ({
  anchor = 'hero',
  banners,
  isMobile
}: HeroBannerCarouselProps): JSX.Element | null => {
  const {
    setSelectedItem,
    setClassName,
    selectedItem,
    activeImageIndex,
    setActiveImageIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  } = useCarousel(banners, true, 7);

  const states = {
    setSelectedItem,
    setClassName,
    selectedItem,
    activeImageIndex,
    setActiveImageIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  };

  return (
    <PackageComponentWrapper>
      <div
        data-test="hero-banner"
        id={anchor}
        className="promo-banner-carousel">
        {banners.length > 1 ? (
          <div className="carousel-wrapper">
            <Carousel data={banners} states={states}>
              {banners.map((item, index) => (
                <div
                  data-test="carousel-item"
                  key={item.id}
                  className="carousel-el"
                  style={{
                    transform: `translate3d(${-activeImageIndex * 100}%, 0, 0)`
                  }}>
                  <HeroBanner {...item} isMobile={isMobile} key={index} />
                </div>
              ))}
            </Carousel>
            <div
              className="promo-carousel-dots container-lr"
              data-test="container-lg">
              <div
                data-test="dots-block"
                className={`slider column d-flex thumbs reverse gx-6`}>
                {banners && banners.length > 1 && (
                  <PromoCarouselDots
                    data={banners}
                    states={states}
                    arrows={false}
                    oneOfTotalText={false}
                    dotName="sm-dot"
                    carouselLimiter={1}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="carousel">
            {banners &&
              banners.map((item) => (
                <HeroBanner {...item} key={item.id} isMobile={isMobile} />
              ))}
          </div>
        )}
      </div>
    </PackageComponentWrapper>
  );
};
