'use client';

import { useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { isIOS, osVersion } from 'react-device-detect';

import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { StoreDrawer } from '../StoreDrawer';
import { SwitchMenuDialog } from '../SwitchMenuDialog';
import { ClearDispensaryModal } from './Kiosk/clearDispensaryModal';
import { useSiteWideContext } from '../../hooks/siteWideContext';

import GeoAltIcon from '../../assets/svgs/header/geo-alt.svg';
import SmGeoAltIcon from '../../assets/svgs/header/geo-alt-sm.svg';
import DownArrow from '../../assets/svgs/arrows/chevron-down.svg';
import SmDownArrow from '../../assets/svgs/arrows/chevron-down-sm.svg';

import './dispensary-title-banner.scss';

export const DispensaryTitleBanner = (): JSX.Element | null => {
  const {
    clearCart,
    isMobile,
    selectedDispensary,
    userMenuType,
    userOrderType
  } = useSiteWideContext();
  const [showTooltip, setShowTooltip] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [storeDrawerOpen, setStoreDrawerOpen] = useState(false);
  const forKiosk = process.env.IS_KIOSK === 'true';
  const [show, setShow] = useState(false);
  const handleToggle = () => {
    setShow(true);
  };

  const hasBothType =
    selectedDispensary && selectedDispensary.menuTypes.length === 2;
  const findADispensaryText = 'Find a Dispensary';

  // Detects known problematic iOS versions
  const isOldBrowser = (isIOS && parseFloat(osVersion) < 16) || false;
  const isStateSite = !!(
    process.env.CONTENTSTACK_ENVIRONMENT?.includes('ct') ||
    process.env.CONTENTSTACK_ENVIRONMENT?.includes('nd')
  );

  return (
    <>
      <SwitchMenuDialog
        show={show}
        setShow={setShow}
        callback={() => {
          setToggle(!toggle);
          if (clearCart) clearCart();
        }}
      />
      <PackageComponentWrapper additionalClass="bg-primary">
        {!forKiosk && !isOldBrowser ? (
          <StoreDrawer
            visible={storeDrawerOpen}
            setVisibility={setStoreDrawerOpen}
          />
        ) : null}
        <div
          className={
            'dispensary-title-banner bg-primary d-flex justify-content-between align-items-center'
          }
          onKeyDown={(event) => {
            if (event.key === 'Escape') {
              setShowTooltip(false);
            }
          }}>
          {forKiosk ? (
            <ClearDispensaryModal
              selectedDispensary={selectedDispensary}
              isMobile={isMobile}
            />
          ) : (
            <a
              className="d-flex align-items-center pointer"
              tabIndex={0}
              onClick={() => {
                if (isOldBrowser) {
                  window.location.href = '/locations';
                } else {
                  setStoreDrawerOpen(true);
                }
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  if (isOldBrowser) {
                    window.location.href = '/locations';
                  } else {
                    setStoreDrawerOpen(true);
                  }
                }
              }}>
              {isMobile ? <SmGeoAltIcon /> : <GeoAltIcon />}
              <span className="selected-store body-m text-white">
                {selectedDispensary
                  ? `${
                      userOrderType === 'DELIVERY' ||
                      selectedDispensary.deliveryOnly ||
                      selectedDispensary.deliveryOnly
                        ? 'Delivery from'
                        : 'Picking Up at'
                    } ${selectedDispensary.friendlyName}`
                  : findADispensaryText}
              </span>
              {selectedDispensary ? (
                isMobile ? (
                  <SmDownArrow />
                ) : (
                  <DownArrow />
                )
              ) : null}
            </a>
          )}
          <div className="d-flex">
            {userMenuType && selectedDispensary && hasBothType ? (
              <div
                tabIndex={0}
                className={classNames(
                  'toggle-container d-flex align-items-center text-white body-m',
                  {
                    pointer: true
                  }
                )}
                data-has-toggle={true}
                onClick={() => handleToggle()}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') handleToggle();
                }}>
                <span
                  data-has-both-types={true}
                  className="pricing-type margin-right-5 body-m">
                  {userMenuType === 'MEDICAL' ? 'Medical' : 'Adult-Use'}
                </span>
                <div className="toggle">
                  <div className="toggle-pill sm-border-radius" />
                  <div
                    className="toggle-circle bg-white"
                    data-toggled={toggle}
                  />
                </div>
              </div>
            ) : selectedDispensary ? (
              <div
                className={
                  'toggle-container d-flex align-items-center text-white body-m'
                }
                data-has-toggle={false}>
                <span
                  data-has-both-types={hasBothType}
                  className="pricing-type margin-right-5 body-m">
                  {selectedDispensary['menuTypes'][0] === 'MEDICAL'
                    ? 'Medical'
                    : 'Adult-Use'}
                </span>
              </div>
            ) : null}
            {selectedDispensary && !isMobile && !forKiosk ? (
              <>
                <div className="margin-inline-20 body-m text-white">|</div>
                <Link
                  className="body-m text-white pointer"
                  tabIndex={0}
                  href={isStateSite ? '/#locations' : '/locations'}>
                  {findADispensaryText}
                </Link>
              </>
            ) : null}
          </div>
        </div>
        <div
          className="backdrop"
          data-tooltip-backdrop={showTooltip}
          data-backdrop={showTooltip}
          onClick={() => setShowTooltip(false)}
        />
      </PackageComponentWrapper>
    </>
  );
};
