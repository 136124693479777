'use client';

import { useEffect } from 'react';

import { DatalayerAnalytics } from 'services';
import type { StoreMetadata } from 'services';

interface Props {
  page_type: string;
  storeMetadata?: StoreMetadata;
}

export const PageView = ({ page_type, storeMetadata }: Props) => {
  useEffect(() => {
    DatalayerAnalytics.pushPageView({
      page_type,
      storeMetadata
    });
  }, []);

  return null;
};
