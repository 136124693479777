import { FormikProps } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';

import { PackageComponentWrapper } from '../../../PackageComponentWrapper';

import {
  DefaultFormik,
  getFormErrorMessage,
  isFormFieldValid
} from '../../../../utils/forms';

import CheckIcon from '../../../../assets/svgs/shared/check.svg';
import '../form.scss';

export type CheckboxProps = {
  title?: string;
  checkbox_options:
    | {
        label: string;
      }[]
    | {
        label: string;
      };
  field: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  required?: boolean;
};

type CheckboxParams = {
  checked: boolean;
  value: {
    label: string;
  };
};

export const CheckboxSelection = ({
  checkbox_options,
  field,
  formik,
  title,
  required
}: CheckboxProps) => {
  if (!formik) {
    formik = DefaultFormik();
  }

  if (
    !checkbox_options ||
    (Array.isArray(checkbox_options) && !checkbox_options.length) ||
    !Object.keys(checkbox_options).length
  )
    return null;

  const onCategoryChange = (event: CheckboxParams) => {
    const selected: string[] = [...formik.values[field]];

    if (event.checked) {
      selected.push(event.value.label);
    } else {
      selected.map((select, i) => {
        if (event.value.label === select) {
          selected.splice(i, 1);
        }
      });
    }

    formik.setFieldValue(field, [...selected]);
  };

  return (
    <PackageComponentWrapper
      minWidth={false}
      additionalClass="margin-top-16 margin-bottom-16">
      {title ? (
        <div
          data-test="checkbox-title"
          className="checkbox-title d-flex align-items-center">
          <p className="margin-bottom-0">{title}</p>
          {required ? <sup className="body-l">*</sup> : null}
        </div>
      ) : null}
      <div
        data-test="checkboxes-container"
        className={classNames('checkbox-container-curaleaf', {
          'form-error':
            isFormFieldValid(field, formik) && formik.submitCount > 0
        })}>
        {Array.isArray(checkbox_options) ? (
          checkbox_options.map((check) => {
            return (
              <div
                data-test="checkbox-container"
                key={check.label}
                className="checkbox d-flex">
                <Checkbox
                  data-test="checkbox"
                  className="checkbox"
                  icon={<CheckIcon />}
                  name={field}
                  inputId={field}
                  value={check}
                  checked={(formik.values[field] as string[]).includes(
                    check.label
                  )}
                  onChange={(e: CheckboxParams | any) => onCategoryChange(e)}
                />
                <label
                  className="font-medium"
                  data-test="checkbox-label"
                  htmlFor={field}>
                  {check.label}
                </label>
              </div>
            );
          })
        ) : (
          <div
            data-test="checkbox-container"
            key={checkbox_options.label}
            className="checkbox d-flex align-items-center">
            <Checkbox
              data-test="checkbox"
              className="checkbox"
              icon={<CheckIcon />}
              inputId={field}
              name={field}
              value={formik.values[field]}
              checked={formik.values[field]}
              onChange={formik.handleChange}
            />
            <label
              className="font-medium"
              data-test="checkbox-label"
              htmlFor={field}>
              {checkbox_options.label}
            </label>
          </div>
        )}
        {formik.submitCount > 0 ? getFormErrorMessage(field, formik) : null}
      </div>
    </PackageComponentWrapper>
  );
};
