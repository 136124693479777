import { ParsedUrlQuery } from 'querystring';
import { slugify } from 'services';
import { ARTICLE_MAP } from './constants';
import { ArticleCardProps } from '../components/ArticleGrid/ArticleCard';

export const getArticleUrlFor = (
  slug: string,
  type: ArticleCardProps['type']
): string => {
  if (
    ARTICLE_MAP[type] &&
    !slug.includes(ARTICLE_MAP[type] as string) &&
    !slug.startsWith('http')
  ) {
    if (slug.startsWith('/')) {
      slug = `${ARTICLE_MAP[type]}${slug}`;
    } else {
      slug = `${ARTICLE_MAP[type]}/${slug}`;
    }
  }
  return slug;
};

export const isExternalUrl = (url: string): boolean => {
  return url.substring(0, 4) === 'http' && !url.includes('curaleaf.com');
};

export const ecommerceSanitizeQuery = (url: string) => {
  return url;
};

export const getShopSlug = (dispensarySlug: string, stateSlug: string) => {
  const stateDispensarySlug = `${stateSlug}/${dispensarySlug}`;

  return slugify(`/shop/${stateDispensarySlug}`);
};

export const locationSanitizeQuery = (
  router: {
    pathname: string;
    query: { [key: string]: string };
  },
  url: string
) => {
  // filtering out ?shop & state (dynamic routes)
  const query = Object.keys(router.query)
    .filter((key) => key !== 'shop' && key !== 'state')
    .reduce((result: ParsedUrlQuery, key: string) => {
      result[key] = router.query[key];
      return result;
    }, {});

  // creating params that isn't for dynamic routes to attach to url
  const params = [];
  for (const key in query) {
    if (Object.prototype.hasOwnProperty.call(query, key)) {
      const value = query[key];
      if (value !== undefined && key !== 'state' && typeof value === 'string') {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(value);
        params.push(`${encodedKey}=${encodedValue}`);
      }
    }
  }

  // return just url or url with existing params
  return params.length ? `${url}?${params.join('&')}` : url;
};
