'use client';

import classNames from 'classnames';
import { usePathname } from 'next/navigation';

import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { SocialIcons } from '../SocialIcons';
import { FooterAccordion } from './accordion';
import { EmployeeLogin } from './Kiosk/EmployeeLogin';
import { KioskClearDispensaryModal } from './Kiosk/ClearDispensaryModal';
import { FooterProps } from '.';
import { Markdown } from '../Markdown';

import Logo from '../../assets/svgs/header/full-logo-teal.svg';

export const FooterNavigation = ({
  disclaimer,
  forKiosk,
  footerLinks,
  socialMediaLinks
}: Omit<FooterProps, 'legalLinks'>): JSX.Element | null => {
  const pathname = usePathname();

  return (
    <PackageComponentWrapper additionalClass="bg-primary">
      <div
        className={classNames('footer-nav', {
          'specials-tray-padding': pathname.includes('/shop') && !forKiosk
        })}
        data-test="footer-container">
        <div className="logo">
          {forKiosk ? <KioskClearDispensaryModal /> : <Logo />}
        </div>
        {footerLinks ? (
          <div className="footer-links">
            {footerLinks?.map((fl) => {
              if (fl.heading) {
                return (
                  <FooterAccordion link={fl} key={fl.id} forKiosk={forKiosk} />
                );
              }
            })}
          </div>
        ) : null}
        {socialMediaLinks ? (
          <div className="corp-social-media" data-test="corp-social-icons">
            <SocialIcons
              size="normal"
              socialMediaLinks={socialMediaLinks}
              variant="secondary"
            />
          </div>
        ) : null}
        {forKiosk ? (
          <EmployeeLogin disclaimer={disclaimer || ''} />
        ) : disclaimer ? (
          <div className="text-center footer-disclaimer">
            <Markdown
              content={disclaimer}
              additionalClass="text-tertiary-dark"
            />
          </div>
        ) : null}
      </div>
    </PackageComponentWrapper>
  );
};
