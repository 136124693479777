'use client';

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import classNames from 'classnames';

import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { ecommerceSanitizeQuery } from '../../../utils/url';

import SearchIcon from '../../../assets/svgs/header/search.svg';
import CloseIcon from '../../../assets/svgs/close/close-teal.svg';

type SearchbarProps = {
  openSearch?: boolean;
  setOpenSearch?: Dispatch<SetStateAction<boolean>>;
};

// used wrapper to prevent hydration issues from useDispensary
export const Searchbar = ({
  openSearch,
  setOpenSearch
}: SearchbarProps): JSX.Element | null => {
  const { push } = useRouter();
  const { selectedDispensary } = useSiteWideContext();
  const searchParams =
    typeof window !== 'undefined'
      ? new URLSearchParams(window.location.search)
      : null;
  const searchKey = searchParams?.get('k');

  const [inputValue, setValue] = useState(
    searchKey?.length && typeof searchKey === 'string' ? searchKey : ''
  );

  useEffect(() => {
    if (searchKey && typeof searchKey === 'string') {
      setValue(searchKey);
    } else {
      setValue('');
    }
  }, [searchKey]);

  if (!selectedDispensary) return null;

  return (
    <div
      data-visible={!!openSearch}
      role="search"
      className={classNames(
        'search bg-secondary-light d-flex align-items-center margin-right-16',
        {
          ['kiosk-search']: process.env.IS_KIOSK === 'true'
        }
      )}>
      <SearchIcon />
      <input
        name="Products Search"
        type="search"
        className="search-input bg-secondary-light body-l"
        placeholder="Search for Products"
        onChange={(event) => setValue(event.target.value)}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter' && inputValue) {
            push(
              ecommerceSanitizeQuery(
                `${selectedDispensary?.shopLink}/products?k=${encodeURI(
                  inputValue
                )}`
              ),
              { scroll: false }
            );
            if (setOpenSearch) setOpenSearch(false);
          }
        }}
        value={inputValue}
      />
      {setOpenSearch ? (
        <div
          role="button"
          className="close-icon"
          aria-label="close"
          onClick={() => setOpenSearch(false)}
          onKeyDown={(event) => event.key === 'Enter' && setOpenSearch(false)}
          tabIndex={0}>
          <CloseIcon />
        </div>
      ) : null}
    </div>
  );
};
