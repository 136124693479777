import { Params } from 'next/dist/shared/lib/router/utils/route-matcher';

import { Loader } from './loader';
import { ProductFilter as Default } from './default';
import { SortBy } from '../../../models/types';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';
import { EcomProductPageType } from '../../../models/productPage';
import { EcomProductGridProps } from '..';

import './product-filter.scss';

export type ProductFilterProps = {
  filters: FilterMenus;
  isLoading: false;
  onClose?: () => void;
  onSelect: EcomProductGridProps['onSelectFilter'];
  pageType: EcomProductPageType['type'];
  productLength?: number;
  shopLink: string;
  sortBy: SortBy;
  searchParams: Params;
};

export type FilterKeys =
  | 'Brands'
  | 'Categories'
  | 'Effects'
  | 'Options'
  | 'Potency'
  | 'Specials'
  | 'Strains'
  | 'Subcategories'
  | 'Terpenes'
  | 'Weights';

export type FilterMenusBase = {
  [key in FilterKeys]: FilterMenuItem[];
};

export type FilterMenus = FilterMenusBase & {
  Sort?: any[];
};

export type FilterMenuItem = {
  id?: string;
  label: string;
  isSelected: boolean;
  key: string;
  values?: {
    min: number;
    max: number;
    current: [number, number];
  };
  unit?: '%' | 'mg';
};

export type LoadingProps = {
  isLoading: true;
};

export const ProductFilter = ({
  props
}: {
  props: ProductFilterProps | LoadingProps;
}): JSX.Element | null =>
  props.isLoading ? (
    <Loader />
  ) : (
    <ErrorBoundary component="product-filter">
      <Default {...props} />
    </ErrorBoundary>
  );
