import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useFormik } from 'formik';

import {
  AuthStatus,
  DatalayerAnalytics,
  ResetData,
  resetPassword
} from 'services';

import { EcomCTAButton } from '../../../FormBuilder/Account/CTAButton';
import {
  FormStatusProps,
  FormStatus
} from '../../../FormBuilder/Account/status';
import { PasswordField } from '../../../FormBuilder/components/Password/password';
import { TextInput } from '../../../FormBuilder/components/TextInput';
import { PackageComponentWrapper } from '../../../PackageComponentWrapper';

import { validationErrorCheck } from '../../../../utils/forms';
import { isEmailValid, isPasswordValid } from '../../../../utils/validators';
import { useSiteWideContext } from '../../../../hooks/siteWideContext';
import { logout } from '../../../../utils/account';

export type ResetProps = {
  email?: string;
  setMessage: Dispatch<SetStateAction<FormStatusProps>>;
  setShowConfirmation?: Dispatch<SetStateAction<boolean>>;
  token: string;
};

export const ResetPasswordForm = ({
  email = '',
  setMessage,
  setShowConfirmation,
  token
}: ResetProps) => {
  const {
    clearAnonCart,
    clearUser,
    setAccountDrawerMode,
    setAccountDrawerVisible,
    isLoggedIn
  } = useSiteWideContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AuthStatus>({
    text: ''
  });

  useEffect(() => {
    if (isLoggedIn && !email) {
      logout(clearUser, clearAnonCart);
    }
  }, [isLoggedIn]);

  const formik = useFormik({
    initialValues: {
      email,
      password: '',
      confirmPassword: ''
    },
    enableReinitialize: true,
    validateOnChange: false,
    validate: (data: ResetData) => {
      const errors = {
        email: !data.email
          ? 'Please enter a valid email address'
          : !isEmailValid(data.email)
            ? 'Invalid email address (e.g. example@email.com).'
            : '',
        password: !data.password
          ? 'Please enter a password.'
          : !isPasswordValid(data.password)
            ? 'Please enter a stronger password.'
            : '',
        confirmPassword: !data.confirmPassword
          ? 'Please reenter your password.'
          : ''
      };

      if (
        data.password &&
        data.confirmPassword &&
        data.password !== data.confirmPassword
      ) {
        errors.password = ' ';
        errors.confirmPassword = 'Passwords do not match.';
      }

      return validationErrorCheck(errors);
    },
    onSubmit: async (values: ResetData) => {
      setLoading(true);
      const submit = await resetPassword({
        ...values,
        token,
        setError
      });
      setLoading(false);

      formik.resetForm();

      if (submit) {
        DatalayerAnalytics.pushAccountEvent({
          action: 'reset_password'
        });
        if (email && setShowConfirmation) {
          setShowConfirmation(true);
          setAccountDrawerVisible(false);
        } else {
          formik.resetForm();
          setAccountDrawerMode('login');
          setMessage({
            text: 'Your password was successfully updated, please sign in.',
            type: 'success'
          });
        }
      }
    }
  });

  return (
    <PackageComponentWrapper>
      <form className="account-form" onSubmit={formik.handleSubmit}>
        <div className="sidebar-header">
          <h2 className="margin-bottom-32 text-primary h3">Reset Password</h2>
          {error.text ? <FormStatus {...error} type="error" /> : null}
          {!email ? (
            <div>
              <span>Remember your password? </span>
              <a
                tabIndex={0}
                className="underline pointer text-tertiary"
                onClick={() => setAccountDrawerMode('login')}>
                Sign In
              </a>
            </div>
          ) : null}
        </div>
        <div className="sidebar-form-padded">
          {!email ? (
            <TextInput
              title="Email Address"
              type="email"
              field="email"
              formik={formik}
              required={true}
            />
          ) : null}
          <PasswordField
            field="password"
            formik={formik}
            title="Create New Password"
            required={true}
            showFeedback={true}
          />
          <PasswordField
            field="confirmPassword"
            formik={formik}
            title="Confirm New Password"
            required={true}
          />
          <EcomCTAButton
            disabled={!formik.dirty}
            loading={loading}
            text="Reset"
            variant="secondary"
          />
        </div>
      </form>
    </PackageComponentWrapper>
  );
};
