import Link from 'next/link';
import Image from 'next/image';
import { useState } from 'react';

import { DatalayerAnalytics, DutchiePlus_OrderType } from 'services';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';
import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import { StoreDrawer } from '../../StoreDrawer';
import { MandatoryImageType } from '../../../models/types';
import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { useDispensaryPathContext } from '../../../hooks/dispensaryPathContextProvider';

type StorefrontImageBannerProps = StorefrontImageBannerInitialProps;

export type StorefrontImageBannerInitialProps = {
  cta_mode: string;
  cta_url: string;
  desktop: MandatoryImageType;
  id: number;
  mobile?: MandatoryImageType | null;
};

export const StorefrontImageBanner = ({
  cta_mode,
  cta_url,
  desktop,
  id,
  mobile
}: StorefrontImageBannerProps): JSX.Element | null => {
  const { setUserOrderType, user } = useSiteWideContext();
  const { currentPathDispensary } = useDispensaryPathContext();
  const [visible, setVisibility] = useState(false);

  const handleBannerClick = () => {
    if (cta_mode) {
      setVisibility(true);
      setUserOrderType(cta_mode as DutchiePlus_OrderType);
    }

    DatalayerAnalytics.pushBasicEvent(
      'banner_click',
      {
        content_name: desktop.alternativeText,
        content_type: 'banner',
        index: id,
        page_type: 'shop/home',
        site_platform:
          process.env.IS_KIOSK === 'true' ? 'curaleaf-kiosk' : 'curaleaf-web',
        userId: user ? user._id : ''
      },
      currentPathDispensary
    );
  };

  const banner = mobile ? mobile : desktop;
  const image = (
    <Image
      alt={banner.alternativeText || desktop.alternativeText}
      priority={id === 0}
      src={banner.url}
      loading={id === 0 ? 'eager' : 'lazy'}
      width={banner.width}
      height={banner.height}
      style={{
        objectFit: 'cover',
        width: '100%',
        height: 'auto'
      }}
    />
  );

  return (
    <PackageComponentWrapper minWidth={false}>
      <ErrorBoundary component="storefront-image-banner">
        {cta_url ? (
          <Link href={cta_url} onClick={handleBannerClick}>
            {image}
          </Link>
        ) : cta_mode ? (
          <a
            role="button"
            className="pointer"
            tabIndex={0}
            onClick={handleBannerClick}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleBannerClick();
              }
            }}>
            {image}
          </a>
        ) : (
          image
        )}
      </ErrorBoundary>
      <StoreDrawer visible={visible} setVisibility={setVisibility} />
    </PackageComponentWrapper>
  );
};
