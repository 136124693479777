import { Loader } from './loader';
import { FavoredBrands } from '../../../../templates/storefrontPage';
import { BrandTile as Default } from './default';
import { ErrorBoundary } from '../../../../utils/nullErrorBoundary';

import './brand-tile.scss';

export type BrandTileProps = FavoredBrands & {
  url: string;
  isLoading: false;
};

type LoadingProps = {
  isLoading: true;
};

export const BrandTile = ({
  props
}: {
  props: BrandTileProps | LoadingProps;
}): JSX.Element | null => {
  return props.isLoading ? (
    <Loader />
  ) : (
    <ErrorBoundary component="product-tile">
      <Default props={props} />
    </ErrorBoundary>
  );
};
