import Link from 'next/link';

import { ecommerceSanitizeQuery } from '../../../utils/url';
import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';

import './empty-cart.scss';

export type EmptyCartProps = {
  shopLink: string;
};

export const EmptyCart = ({ shopLink }: EmptyCartProps): JSX.Element | null => {
  return (
    <PackageComponentWrapper maxWidth={false} minWidth={false}>
      <ErrorBoundary component="empty-cart">
        <div
          className="empty-cart bg-white d-flex flex-column justify-content-center align-items-center"
          data-test="empty-cart">
          <h4 className="text-primary margin-bottom-16 title-l">
            Your cart is empty
          </h4>
          <Link
            className="h5-regular underline"
            href={ecommerceSanitizeQuery(shopLink)}>
            Time to start shopping!
          </Link>
        </div>
      </ErrorBoundary>
    </PackageComponentWrapper>
  );
};
