import { Dialog } from 'primereact/dialog';

import { PackageComponentWrapper } from '../../../PackageComponentWrapper';
import { AuthDrawerForm } from '../../../Account/Drawer/auth';
import Image from 'next/image';
import { imgPath } from '../../../../utils/imgPath';
import { ErrorBoundary } from '../../../../utils/nullErrorBoundary';
import { Markdown } from '../../../Markdown';

import '../form.scss';

export type FormConfirmationProps = {
  confirmation: AuthDrawerForm['confirmation'];
  setShowConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  showConfirmation: boolean;
};

export const FormConfirmation = ({
  confirmation,
  setShowConfirmation,
  showConfirmation
}: FormConfirmationProps) => {
  if (!showConfirmation) return null;
  const { title, callback, content, image } = confirmation;
  return (
    <PackageComponentWrapper>
      <ErrorBoundary component="form-confirmation">
        <Dialog
          data-test="dialog"
          className="dialog-curaleaf"
          visible={showConfirmation}
          onHide={() => {
            setShowConfirmation(false);
            if (callback) {
              callback();
            }
          }}
          header={title}
          closable
          dismissableMask
          breakpoints={{ '960px': '75vw', '640px': '100vw' }}>
          {image ? (
            <div data-test="dialog-image" className="image-container">
              <Image
                src={imgPath(image.url)}
                width={image.width}
                height={image.height}
                layout="responsive"
                alt={image.alternativeText || ''}
              />
            </div>
          ) : null}
          {content ? (
            <div>
              <Markdown data-test="dialog-content" content={content} />
            </div>
          ) : null}
        </Dialog>
      </ErrorBoundary>
    </PackageComponentWrapper>
  );
};
