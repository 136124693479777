'use client';

import { useEffect, useState } from 'react';

type WindowDimensions = {
  width: number | undefined;
  height: number | undefined;
};

export const useWindowDimensions = (): WindowDimensions => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    function handleResize(): void {
      window &&
      (window.innerWidth !== windowDimensions.width ||
        window.innerHeight !== windowDimensions.height)
        ? setWindowDimensions({
            width: window.innerWidth,
            height: window.innerHeight
          })
        : null;
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return (): void => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowDimensions;
};

export default useWindowDimensions;
