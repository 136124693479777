import classNames from 'classnames';

import { CTAButton, CTAButtonProps } from '../CTAButton';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { Markdown } from '../Markdown';

import './text-block.scss';

export type TextBlockProps = {
  additionalClass?: string;
  anchor?: string;
  align?: 'center' | 'left' | 'right';
  ctabutton?: CTAButtonProps[];
  content: string[];
  header?: string;
  padded?: boolean;
};

export const TextBlock = ({
  additionalClass,
  content,
  anchor = 'textblock',
  align = 'left',
  ctabutton,
  padded = true,
  header
}: TextBlockProps): JSX.Element | null => {
  if (!content || !content.length) return null;

  return (
    <PackageComponentWrapper additionalClass={additionalClass} minWidth={false}>
      <div
        data-test="text-block-container"
        id={anchor}
        className={classNames('text-block', {
          container: padded
        })}>
        {header && header.length ? (
          <h2 className="text-block-header">{header}</h2>
        ) : null}
        <div className="text-block-content">
          {content.map((content) => (
            <div key={content} className={`text-${align}`}>
              {content && content.length ? (
                <Markdown data-test="text-block-content" content={content} />
              ) : null}
            </div>
          ))}
        </div>
        {ctabutton && ctabutton.length ? (
          <div className={`button-group-${align}`}>
            {ctabutton.map((btn: CTAButtonProps) => (
              <CTAButton key={btn.text} {...btn} data-test="cta-button" />
            ))}
          </div>
        ) : null}
      </div>
    </PackageComponentWrapper>
  );
};
