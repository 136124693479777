import axios from 'axios';
import validator from 'validator';

import { MOBILE_WIDTH } from './constants';

export const isStringValid = (str: string, min = 2, max = 128): boolean => {
  return validator.isLength(str.trim(), { min, max });
};

export const isEmailValid = (email: string): boolean => {
  return validator.isEmail(email);
};

export const isPhoneValid = async (
  phone: string,
  websiteUrl: string
): Promise<boolean> => {
  if (process.env.NODE_ENV === 'development') {
    return true;
  }

  const isMobilePhone = validator.isMobilePhone(phone);
  if (isMobilePhone) {
    const isFakeNumber = await axios.get(
      `${websiteUrl}/api/fake-number?number=${phone}`
    );
    return isFakeNumber.status === 200;
  }
  return isMobilePhone;
};

export const isPasswordValid = (pw: string): boolean => {
  return validator.isStrongPassword(pw, {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1
  });
};

export const isMP4 = (url: string | null): boolean => {
  if (!url) return false;
  try {
    const tmp = url.split('.');
    return tmp[tmp.length - 1] === 'mp4';
  } catch (err) {
    return false;
  }
};

export const isNotMobileView = (width: number | undefined) => {
  return width && width > MOBILE_WIDTH;
};
