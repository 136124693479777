import classNames from 'classnames';
import Image from 'next/image';

import { PromoBannerProps } from '../PromoBanner';
import { imgPath } from '../../../utils/imgPath';
import { Markdown } from '../../Markdown';

import './small-banner.scss';
import { CTAButton } from '../../CTAButton';

export const SmallPromoBanner = ({
  content,
  ctaButton,
  image,
  layout
}: PromoBannerProps): JSX.Element | null => {
  return (
    <section className="small-banner" data-test="sm-promo-banners-container">
      <article
        className={classNames('column d-flex bg-primary text-white', {
          reverse: layout === 'left',
          row: layout !== 'left'
        })}
        data-test="sm-banner-container">
        <div className="text-container">
          {content && <Markdown content={content} additionalClass="col-md-8" />}
          {ctaButton && <CTAButton {...ctaButton} />}
        </div>
        {image ? (
          <div className="col-12 col-md-4">
            <div data-test="sm-image-fill" className="small-image-banner">
              <Image
                src={imgPath(image.url)}
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover'
                }}
                height={image.height}
                width={image.width}
                alt={image.alternativeText || ''}
              />
            </div>
          </div>
        ) : null}
      </article>
    </section>
  );
};
