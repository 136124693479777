import { LoadingSkeleton } from '../../Loader/Skeleton';

export const Loader = (): JSX.Element | null => {
  return (
    <div className="product-sort-filter psf-loader">
      <div className="button-group d-flex">
        <a
          role="button"
          data-test="filter-button"
          className="d-flex justify-content-center align-items-center body-m sm-border-radius">
          <LoadingSkeleton width="25px" />
          <span className="margin-left-10 body-m font-bold">
            <LoadingSkeleton width="88px" />
          </span>
        </a>
      </div>
    </div>
  );
};
