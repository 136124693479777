import { ProductSortFilterProps } from '..';
import { Select } from '../../../Select';
import { FilterMenus } from '../FilterModal';

export const SortBySelect = ({
  filters,
  onSelectFilter,
  sortBy
}: {
  filters: FilterMenus;
  onSelectFilter: ProductSortFilterProps['onSelectFilter'];
  sortBy: ProductSortFilterProps['sortBy'];
}) => {
  const menuItems = [
    {
      label: 'Price: High to Low',
      value: 'price-asc-rank'
    },
    {
      label: 'Price: Low to High',
      value: 'price-desc-rank'
    },
    {
      label: 'A-Z',
      value: 'alphabetized'
    }
  ];

  // Hides potency if they're all the same or none exists
  if (filters.Potency && filters.Potency.length) {
    menuItems.unshift(
      {
        label: 'THC: High to Low',
        value: 'potency-percent-asc-rank'
      },
      {
        label: 'THC: Low to High',
        value: 'potency-percent-desc-rank'
      }
    );
  }

  // These should come before Potency
  menuItems.unshift(
    {
      label: 'Recommended',
      value: 'recommended'
    },
    {
      label: 'Popular',
      value: 'popular'
    }
  );

  return (
    <Select
      id="sort-by"
      onSelectFilter={onSelectFilter}
      selected={sortBy}
      placeholder="Sort by"
      menuItems={menuItems}
    />
  );
};
