'use client';

import { marked } from 'marked';
import { useEffect, useState } from 'react';

// Replacing this because ipad 8/9 isn't compatible with rehype-raw
export const Markdown = ({
  additionalClass,
  content
}: {
  additionalClass?: string;
  content: string;
}) => {
  const [html, setHtml] = useState('');

  useEffect(() => {
    if (content) {
      // adds space after all heading hashtags (##Title -> ## Title) and ignores anchors in links
      try {
        const markdown = content
          .replaceAll(/(?<=^|\s)(#+)(?=[^\s#])(?![^(]*?\))/g, '$& ')
          // remove space from color hexes only (contentstack inline-styles)
          .replaceAll(/color:# /g, 'color: #')
          .replaceAll(/fill: # /g, 'fill: #');
        setHtml(`${marked.parse(markdown)}`);
      } catch (err) {
        console.warn('Error parsing markdown:', err);
        try {
          setHtml(`${marked.parse(content)}`);
        } catch (err) {
          setHtml(`<p>${content}</p>`);
        }
      }
    }
  }, [content?.length]);

  if (!html) return null;

  return (
    <div className={additionalClass}>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};
