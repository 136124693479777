'use client';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSearchParams } from 'next/navigation';

import {
  ContactFormDataType,
  DatalayerAnalytics,
  StatesNDispensaries
} from 'services';

import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { TextInput } from './components/TextInput';
import { TextArea } from './components/TextArea';
import { DropdownSelection } from './components/DropdownSelection';
import {
  FormConfirmation,
  FormConfirmationProps
} from './components/Confirmation';
import { CheckboxSelection } from './components/Checkbox';
import { EcomCTAButton } from './Account/CTAButton';
import { FormDisclaimer } from './components/Disclaimer';

import { isEmailValid, isStringValid } from '../../utils/validators';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { contactUsMap } from '../../utils/formMap';
import { ImageType } from '../../models/types';

import './embeddable-form.scss';

export type ContactUsProps = {
  brazeInstance?: string;
  buttonText: string;
  confirmation: Omit<FormConfirmationProps['confirmation'], 'callback'>;
  disclaimer: string;
  formUid: string;
  image?: ImageType | null;
  statesNdispensaries: StatesNDispensaries[];
  subtitle?: string;
  title: string;
  topics: {
    display_value: string;
  }[];
};

const FORM_GA_TYPE = 'contact_us';

export const ContactUsForm = ({
  brazeInstance,
  buttonText,
  confirmation,
  disclaimer,
  formUid,
  statesNdispensaries,
  subtitle,
  title,
  topics
}: ContactUsProps) => {
  const searchParams = useSearchParams();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const dispensary = searchParams.get('dispensary');
    const state = searchParams.get('state');

    if (dispensary) formik.setFieldValue('dispensary', dispensary);
    if (state) formik.setFieldValue('state', state);
  }, [searchParams]);

  useEffect(() => {
    DatalayerAnalytics.pushBasicEvent('form_view', {
      form_type: FORM_GA_TYPE
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      brazeInstance,
      dispensary: '',
      email: '',
      firstName: '',
      formUid,
      lastName: '',
      optIn: true,
      phone: '',
      topic: '',
      topicDescription: '',
      state:
        statesNdispensaries && statesNdispensaries[0]
          ? statesNdispensaries[0].state
          : ''
    },
    enableReinitialize: true,
    validate: (data: ContactFormDataType) => {
      const errors = {} as ContactFormDataType;

      if (!data.firstName) {
        errors.firstName = 'First Name is required.';
      } else if (!isStringValid(data.firstName, 2, 32)) {
        errors.firstName = 'Invalid First Name.';
      }

      if (!data.lastName) {
        errors.lastName = 'Last Name is required.';
      } else if (!isStringValid(data.lastName, 2, 32)) {
        errors.lastName = 'Invalid Last Name.';
      }

      if (!data.email) {
        errors.email = 'Email is required.';
      } else if (!isEmailValid(data.email)) {
        errors.email = 'Invalid email address. E.g. example@email.com';
      }

      if (!data.topic) {
        errors.topic = 'Topic is required.';
      }

      if (!data.topicDescription) {
        errors.topicDescription = 'Topic description is required.';
      }

      return errors;
    },
    onSubmit: async (values: ContactFormDataType) => {
      DatalayerAnalytics.pushBasicEvent('form_submit', {
        form_type: FORM_GA_TYPE
      });
      setLoading(true);
      try {
        const submit = await axios.post(
          '/api/form/submit',
          contactUsMap(values)
        );
        if (submit.status === 200) {
          setShowConfirmation(true);
          setLoading(false);
          formik.resetForm();
        }
      } catch (err) {
        setLoading(false);
        console.warn(`Error: ${err}`);
      }
    }
  });

  const matchingState = statesNdispensaries
    ? statesNdispensaries.find((state) => state.state === formik.values.state)
    : undefined;

  return (
    <PackageComponentWrapper>
      <ErrorBoundary component="contact-us">
        <FormConfirmation
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          confirmation={confirmation}
        />
        <div className="d-flex">
          <form
            className="embeddable-form-curaleaf container-lr padding-bottom-16"
            autoComplete="on"
            onSubmit={formik.handleSubmit}>
            <div className="sidebar-header margin-bottom-32 text-center">
              <h2 className="text-primary">{title}</h2>
              {subtitle ? (
                <p className="margin-top-16 margin-bottom-16">{subtitle}</p>
              ) : null}
            </div>
            <div>
              <div className="d-flex justify-content-between form-row">
                <div className="form-col">
                  <TextInput
                    title="First Name"
                    type="name"
                    field="firstName"
                    formik={formik}
                    required={true}
                    autoComplete="given-name"
                    customId="contact-user-first-name"
                  />
                </div>
                <div className="form-col">
                  <TextInput
                    title="Last Name"
                    type="name"
                    field="lastName"
                    formik={formik}
                    required={true}
                    autoComplete="family-name"
                    customId="contact-user-last-name"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between form-row">
                <div className="form-col">
                  <TextInput
                    title="Email"
                    type="email"
                    field="email"
                    formik={formik}
                    required={true}
                    customId="contact-user-email"
                    autoComplete="email"
                  />
                </div>
                <div className="form-col">
                  <TextInput
                    title="Phone"
                    type="tel"
                    field="phone"
                    formik={formik}
                    customId="contact-user-phone"
                    autoComplete="tel"
                  />
                </div>
              </div>
              {statesNdispensaries && statesNdispensaries.length > 1 ? (
                <DropdownSelection
                  title="What state do you live in?"
                  dropdown_options={statesNdispensaries.map(
                    (state) => state.state
                  )}
                  field="state"
                  sort={true}
                  formik={formik}
                />
              ) : null}
              {matchingState && matchingState.dispensaries.length ? (
                <DropdownSelection
                  title="What dispensary will you most likely visit?"
                  dropdown_options={matchingState.dispensaries.map(
                    (dispensary) => dispensary.name
                  )}
                  field="dispensary"
                  sort={true}
                  formik={formik}
                />
              ) : null}
              <DropdownSelection
                title="Please identify a topic so we may better assist you."
                dropdown_options={topics.map((topic) => topic.display_value)}
                field="topic"
                sort={true}
                formik={formik}
                required={true}
              />
              <TextArea
                title="Please provide our team with some details."
                field="topicDescription"
                formik={formik}
                required={true}
              />
              <CheckboxSelection
                checkbox_options={{
                  label:
                    'I want to receive marketing materials from Curaleaf and subsidiary brands'
                }}
                field="optIn"
                formik={formik}
              />
              <EcomCTAButton
                loading={loading}
                text={buttonText}
                variant="secondary"
              />
              <FormDisclaimer disclaimer={disclaimer} />
            </div>
          </form>
        </div>
      </ErrorBoundary>
    </PackageComponentWrapper>
  );
};
