export const moodiDayScript = () => {
  if (process.env.NEXT_PUBLIC_MOODIDAY_CLIENT_ID) {
    const script = document.createElement('script');
    script.src = 'https://review-module.moodiday.com/v2/embed.js';
    script.defer = true;
    script.setAttribute('clientID', process.env.NEXT_PUBLIC_MOODIDAY_CLIENT_ID);
    document.body.append(script);
    return () => {
      document.body.removeChild(script);
    };
  }
};
