'use client';

import { useState } from 'react';
import { CarouselBanner } from '../components/Carousel/types';

export function useCarousel(
  data: CarouselBanner[],
  autoSlideEffect: boolean,
  autoSlideInSeconds: number
) {
  const [className, setClassName] = useState<string>('');
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0);
  const [selectedItem, setSelectedItem] = useState<CarouselBanner | undefined>(
    data[activeImageIndex] ?? undefined
  );
  const [autoSlide, setAutoSlide] = useState<boolean>(autoSlideEffect);
  const [autoSlideSeconds] = useState<number>(autoSlideInSeconds);

  return {
    selectedItem,
    setSelectedItem,
    className,
    setClassName,
    activeImageIndex,
    setActiveImageIndex,
    CAROUSEL_LIMITER: 2,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  };
}
