'use client';

import { useState } from 'react';

// Hook - Proudly stolen from https://usehooks.com/useLocalStorage/ :)
export function useStorage<T>(
  key: string,
  initialValue: T,
  session?: boolean
): [T, (value: T) => void] {
  if (typeof window === 'undefined') {
    return [initialValue, () => undefined];
  }

  const storage = session ? window.sessionStorage : window.localStorage;
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [storedValue, setStoredValue] = useState<T>(() => {
    const item = storage.getItem(key);
    try {
      // Get from local storage by key
      // Parse stored json or if none return initialValue
      if (!item) {
        storage.setItem(key, JSON.stringify(initialValue));
        return initialValue;
      } else {
        return JSON.parse(item);
      }
    } catch (error) {
      // If error also return initialValue
      return item || initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      storage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
    }
  };
  return [storedValue, setValue];
}
