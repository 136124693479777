'use client';

import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { InstructionSteps } from './InstructionSteps';

export type InstructionsBlockProps = {
  heading: string;
  isMobile: boolean;
  steps: string[];
};

export const InstructionsBlock = ({
  heading,
  isMobile,
  steps
}: InstructionsBlockProps): JSX.Element | null => {
  let container = 'padding-40 margin-top-16';
  let header = 'padding-right-30 padding-left-30';

  if (isMobile) {
    container = 'flex-column padding-32 margin-top-10';
    header = `margin-bottom-16 padding-left-0`;
  }

  return (
    <PackageComponentWrapper>
      <div className={`d-flex bg-white ${container}`}>
        <h2 className={`column-4 ${header} h3 text-primary`}>{heading}</h2>
        <div className="column-6">
          <InstructionSteps steps={steps} />
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
