import Image from 'next/image';

import { imgPath } from '../../utils/imgPath';
import { ImageType } from '../../models/types';

import { CTAButton, CTAButtonProps } from '../CTAButton';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { Markdown } from '../Markdown';

import './product-card.scss';

export type ProductCardProps = {
  anchor?: string;
  ctaButton?: CTAButtonProps | null;
  features: Feature[];
  heading: string;
  image: ImageType;
};

export type Feature = {
  content: string;
};

export const ProductCard = ({
  anchor = 'product-card',
  ctaButton,
  features,
  image,
  heading
}: ProductCardProps): JSX.Element | null => {
  return (
    <PackageComponentWrapper>
      <div
        id={anchor}
        className="product-card"
        data-test="product-card-component">
        <div className="description-section" data-test="description-section">
          <div className="product-image">
            <Image
              src={imgPath(image.url)}
              data-test="product-image"
              alt={image.alternativeText || heading}
              width={image.width}
              height={image.height}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          </div>
          <div className="title-and-description container">
            <Markdown content={heading} />
            <div className="feature-section" data-test="feature-section">
              {features.length <= 4
                ? features.map((feature: Feature, i: number) => {
                    return (
                      <div
                        key={feature.content}
                        className={`${
                          i === 0 || i === 2 ? 'first-feature-set' : ''
                        }`}>
                        <Markdown
                          data-test="text-block-content"
                          content={feature.content}
                        />
                      </div>
                    );
                  })
                : null}
            </div>
            {ctaButton && ctaButton.text.length ? (
              <CTAButton data-test="product-card-cta" {...ctaButton} />
            ) : null}
          </div>
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
