'use client';

import { Loader } from './loader';
import { StorefrontBanners as Default } from './default';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { StorefrontImageBannerInitialProps } from './banners/ImageBanner';

import './storefront-banners.scss';

export type StorefrontBannersProps = {
  isLoading: boolean;
  banners: StorefrontImageBannerInitialProps[];
};

export const StorefrontBanners = ({
  props
}: {
  props: StorefrontBannersProps;
}): JSX.Element | null => {
  if (!props.banners.length && !props.isLoading) return null;
  return props.isLoading ? (
    <Loader />
  ) : (
    <ErrorBoundary component="storefront-banners">
      <Default {...props} />
    </ErrorBoundary>
  );
};
