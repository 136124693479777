import { Curaql_OrderType, DutchiePlus_Generated_PotencyUnit } from 'services';
import { FilterKeys } from '../components/NewEcomProductGrid/ProductSortFilter/FilterModal';

export const CAROUSEL_LIMITER = 2;

export const LARGE_PROMO_BANNER_THEMES = {
  DARK: 'DARK',
  LIGHT: 'LIGHT'
};

export const LAYOUT_OPTIONS: { [key: string]: 'LEFT' | 'RIGHT' } = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
};

export const MOBILE_WIDTH = 768;

export const SEARCH_QUERY_KEYS = [
  'b',
  'c',
  'e',
  'k',
  'o',
  'p',
  's',
  't',
  'w',
  'sub',
  'sort',
  'spec'
];

export const ORDER_TYPE: {
  [key in Curaql_OrderType]: string;
} = {
  CURBSIDE_PICKUP: 'Curbside-Pickup',
  DELIVERY: 'Delivery',
  DRIVE_THRU_PICKUP: 'Drive-Thru',
  PICKUP: 'Pickup',
  KIOSK: 'Kiosk',
  MED_DELIVERY: 'Delivery',
  AU_DELIVERY: 'Delivery'
};

export const SEARCH_QUERIES: { [key in FilterKeys]: string | undefined } = {
  Brands: 'b',
  Categories: 'c',
  Effects: 'e',
  Options: 'o',
  Potency: undefined,
  Strains: 's',
  Subcategories: 'sub',
  Terpenes: 't',
  Weights: 'w',
  Specials: 'spec'
};

export const ARTICLE_MAP: { [key: string]: string } = {
  blog: '/blog',
  recipe: '/cooking-with-cannabis',
  new: '/in-the-news',
  community: '/community'
};

export const UNIT_MAP: { [key in DutchiePlus_Generated_PotencyUnit]: string } =
  {
    MILLIGRAMS: 'mg',
    MILLIGRAMS_PER_GRAM: 'mg/g',
    MILLIGRAMS_PER_ML: 'mg/ml',
    PERCENTAGE: '%'
  };

export const SORT = [
  'alphabetized',
  'popular',
  'potency-percent-asc-rank',
  'potency-percent-desc-rank',
  'price-asc-rank',
  'price-desc-rank',
  'recommended'
];
