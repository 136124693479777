'use client';

import { StoreCardDispensary } from 'services';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { StoreList } from '../StoreListAccordion/StoreList';

export const StateStoreList = ({
  stores
}: {
  stores: StoreCardDispensary[];
}): JSX.Element | null => {
  if (!stores.length) return null;

  return (
    <PackageComponentWrapper>
      <StoreList stores={stores} />
    </PackageComponentWrapper>
  );
};
