import { Params } from 'next/dist/shared/lib/router/utils/route-matcher';
import { Loader } from './loader';
import { EcomProductGridProps, ProductViewType } from '../index';
import { FilterMenus } from './FilterModal';
import { ProductSortFilter as Default } from './default';
import { SortBy } from '../../../models/types';
import { EcomProductPageType } from '../../../models/productPage';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';

import './product-sort-filter.scss';

export type ProductSortFilterProps = {
  filters: FilterMenus;
  isLoading: false;
  onSelectFilter: EcomProductGridProps['onSelectFilter'];
  pageType: EcomProductPageType['type'];
  productView: ProductViewType;
  searchParams: Params;
  setProductView: (productView: ProductViewType) => void;
  shopLink: string;
  sortBy: SortBy;
};

export type LoadingProps = {
  isLoading: true;
  pageTitle: string;
};

export const ProductSortFilter = ({
  props
}: {
  props: ProductSortFilterProps | LoadingProps;
}): JSX.Element | null => {
  if (props.isLoading) {
    return <Loader />;
  } else {
    if (!props.filters) return null;
    return (
      <ErrorBoundary component="product-sort-filter">
        <Default {...props} />
      </ErrorBoundary>
    );
  }
};
