import { Accordion, AccordionTab } from 'primereact/accordion';

import { PackageComponentWrapper } from '../../../PackageComponentWrapper';

import { Curaql_Perk, Curaql_TierInfo } from 'services';
import { StatusBar } from '../../Loyalty/StatusBar';

import LoyaltyLeaf from '../../../../assets/svgs/loyalty/icon-loyalty-leaf-logo-green-bg.svg';
import LoyaltyPoints from '../../../../assets/svgs/loyalty/icon-loyalty-points.svg';
import LoyaltyCalendar from '../../../../assets/svgs/loyalty/icon-loyalty-calendar.svg';
import LoyaltySparkles from '../../../../assets/svgs/loyalty/icon-loyalty-sparkles.svg';
import LoyaltyDigitalContent from '../../../../assets/svgs/loyalty/icon-loyalty-digital-content.svg';
import LoyaltyDefault from '../../../../assets/svgs/loyalty/icon-loyalty-leaf-logo-minimal.svg';

type Props = {
  currentTier: Curaql_TierInfo;
  nextTier: Curaql_TierInfo | null;
  tierProgress: number;
};

const perkIconMap = (iconName: Curaql_Perk['iconName']) => {
  switch (iconName) {
    case 'Points': {
      return <LoyaltyPoints />;
    }
    case 'Calendar': {
      return <LoyaltyCalendar />;
    }
    case 'Sparkle': {
      return <LoyaltySparkles />;
    }
    case 'DigitalContent': {
      return <LoyaltyDigitalContent />;
    }
    default: {
      return <LoyaltyDefault />;
    }
  }
};

const PerkInfo = ({ perk, i }: { perk: Curaql_Perk; i: number }) => {
  return (
    <li className="margin-bottom-32" key={perk.title}>
      <div>{perkIconMap(perk.iconName)}</div>
      <p className="margin-top-16 margin-bottom-5">
        <strong className="uppercase">
          {i + 1}. {perk.title}
        </strong>
      </p>
      <p className="text-primary">{perk.description}</p>
    </li>
  );
};

export const PerksView = ({
  currentTier,
  nextTier,
  tierProgress
}: Props): JSX.Element | null => {
  return (
    <PackageComponentWrapper>
      <div className="loyalty-perks">
        <div className="sidebar-header">
          <h2 className="margin-bottom-32 text-primary h3">Perks</h2>
        </div>
        <div className="sidebar-form">
          <div className="member-status">
            <div className="d-flex align-items-center flex-column">
              <LoyaltyLeaf />
              <div className="current-status margin-top-16 margin-bottom-16">
                <b className="uppercase">Current Status:</b>
              </div>
              <h2 className="current-prestige text-primary margin-bottom-32 h3">
                {currentTier.prestige}
              </h2>
            </div>
            <StatusBar
              currentTierPrestige={currentTier.prestige}
              nextTierPrestige={
                nextTier ? nextTier.prestige : currentTier.prestige
              }
              tierProgress={tierProgress}
            />
          </div>
          <Accordion activeIndex={0}>
            <AccordionTab
              header={<strong className="uppercase">Current Benefits</strong>}>
              <p className="margin-bottom-16">
                Here&rsquo;s a few benefits about &quot;{currentTier.prestige}
                &quot;
              </p>
              <ul className="perk-list">
                {currentTier.perks.map((perk, i) => {
                  if (perk) return <PerkInfo perk={perk} i={i} key={i} />;
                })}
              </ul>
            </AccordionTab>
            {nextTier ? (
              <AccordionTab
                header={
                  <p className="margin-bottom-0">
                    <strong className="uppercase">Next Level Benefits</strong>
                  </p>
                }>
                <p className="margin-bottom-16">
                  Here&rsquo;s a few benefits about &quot;{nextTier.prestige}
                  &quot;
                </p>
                <ul className="perk-list">
                  {nextTier.perks.map((perk, i) => {
                    if (perk) return <PerkInfo key={i} perk={perk} i={i} />;
                  })}
                </ul>
              </AccordionTab>
            ) : null}
          </Accordion>
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
