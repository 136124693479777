import { Loader } from './loader';
import { OrderSummary as Default } from './default';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';

export type OrderSummaryPricing =
  | 'discounts'
  | 'subtotal'
  | 'taxes'
  | 'total'
  | 'fees';

export type OrderSummaryProps = {
  isLoading: false;
  pricing: {
    [key in OrderSummaryPricing]: number;
  };
  type: 'order' | 'cart';
};

export type LoadingProps = {
  isLoading: true;
};

export const OrderSummary = ({
  props
}: {
  props: OrderSummaryProps | LoadingProps;
}): JSX.Element | null =>
  props.isLoading ? (
    <Loader />
  ) : (
    <ErrorBoundary component="order-summary">
      <Default {...props} />
    </ErrorBoundary>
  );
