'use client';

import { Tree, TreeProps } from 'primereact/tree';
import { useState } from 'react';
import Link from 'next/link';

import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { DepartmentListing, JobListingProps, Listing } from './index';

import './job-listing.scss';

export const JobListing = ({
  careers,
  legal,
  title,
  subtitle
}: JobListingProps) => {
  const allCareers = careers;

  const [expandedKeys, setExpandedKeys] = useState({});
  const [search, setSearch] = useState<string>('');
  const [filteredCareers, setFilteredCareers] = useState(allCareers);

  careers.map((career) => (career.key = career.label));
  careers.find((career) => {
    if (legal) {
      if (legal.text.toLowerCase().includes(career.label.toLowerCase())) {
        career.legal = legal;
      }
    }
  });

  const handleChange = (search: string) => {
    if (search.length > 2) {
      const tfilteredCareers: JobListingProps['careers'] = [];
      allCareers.forEach((job) => {
        if (job.label.toLowerCase().includes(search.toLowerCase())) {
          tfilteredCareers.push(job);
        } else {
          const fchilds: DepartmentListing[] = [];
          job.children.forEach((nchild) => {
            if (
              nchild.department.toLowerCase().includes(search.toLowerCase())
            ) {
              fchilds.push(nchild);
            } else {
              const flisting: Listing[] = [];
              nchild.listing.forEach((nlisting) => {
                if (
                  nlisting.title.toLowerCase().includes(search.toLowerCase()) ||
                  nlisting.location.toLowerCase().includes(search.toLowerCase())
                ) {
                  flisting.push(nlisting);
                }
              });
              if (flisting && flisting.length > 0) {
                nchild.listing = flisting;
                fchilds.push(nchild);
              }
            }
          });
          if (fchilds && fchilds.length > 0) {
            job.children = fchilds;
            tfilteredCareers.push(job);
          }
        }
      });
      setFilteredCareers(tfilteredCareers);
    } else {
      setFilteredCareers(allCareers);
    }
    setSearch(search);
  };

  const template = (job: {
    label: string;
    listing: Listing[];
    department: string;
    legal: {
      text: string;
      link: string;
    };
  }) => {
    if (job.listing) {
      return (
        <div data-test="career-listing" className="career-listing">
          <h5 className="career-dept text-primary">{job.department}</h5>
          <div className="career-list">
            {job.listing.map((list, index) => (
              <Link
                className="underline text-primary"
                key={index}
                href={list.url}
                target="_blank">
                {`${list.title} - `}
                <b>{list.location}</b>
              </Link>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div tabIndex={0} data-test="career-state" className="career-state">
          {job.label}
          {job.legal ? (
            <div data-test="career-legal">
              <Link className="career-legal" href={job.legal.link}>
                {job.legal.text}
              </Link>
            </div>
          ) : null}
        </div>
      );
    }
  };

  return (
    <PackageComponentWrapper additionalClass="curaleaf">
      <div data-test="career-container" className="careers">
        <div className="career-header">
          {title ? (
            <h3 data-test="career-title" className="text-primary">
              {title}:
            </h3>
          ) : null}
          {subtitle ? <p data-test="career-subtitle">{subtitle}</p> : null}
        </div>
        <div className="search">
          <label>Search:</label>
          <input
            placeholder="Enter Job Name, Department, Title, Location"
            name="job-listing-name"
            data-test="input-test"
            className="search-job-listing-input"
            value={search}
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
        {filteredCareers && filteredCareers.length > 0 && (
          <Tree
            className="all-listing"
            data-test="career"
            value={filteredCareers as TreeProps['value']}
            nodeTemplate={template as unknown as TreeProps['nodeTemplate']}
            expandedKeys={expandedKeys}
            onToggle={(e) => setExpandedKeys(e.value)}
          />
        )}
      </div>
    </PackageComponentWrapper>
  );
};
