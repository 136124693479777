'use client';

import classNames from 'classnames';
import Link from 'next/link';
import Image from 'next/image';

import { ImageBlockProps } from './index';
import { isMP4 } from '../../utils/validators';
import { ImageType } from '../../models/types';
import { imgPath } from '../../utils/imgPath';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { CTAButton } from '../CTAButton';

import './image-block.scss';

export const ImageBlock = ({
  anchor = 'image-block',
  contain = false,
  cta,
  fullwidth = true,
  image,
  isMobile = false,
  minWidth = true,
  mobileImage
}: ImageBlockProps): JSX.Element | null => {
  const responsiveImage = isMobile && mobileImage ? mobileImage : image;

  const ImageComp = () => {
    return (
      <>
        {isMP4(responsiveImage.url) ? (
          <div className="video-container" data-test="image-block-video">
            <video autoPlay playsInline loop muted key={responsiveImage.url}>
              <source src={responsiveImage.url} type="video/mp4" />
              <track kind="captions" />
            </video>
          </div>
        ) : contain ? (
          <Image
            src={imgPath(responsiveImage.url)}
            height={(responsiveImage as ImageType).height}
            width={(responsiveImage as ImageType).width}
            alt={(responsiveImage as ImageType).alternativeText || ''}
            style={{
              height: 'auto',
              width: '100%',
              objectFit: 'contain'
            }}
          />
        ) : (
          <Image
            data-test="image-block-image"
            src={imgPath(responsiveImage.url)}
            width={(responsiveImage as ImageType).width}
            height={(responsiveImage as ImageType).height}
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'cover'
            }}
            alt={(responsiveImage as ImageType).alternativeText || ''}
          />
        )}
      </>
    );
  };

  if (cta) {
    switch (cta.__typename) {
      case 'CuraleafPageModularBlocksImageBlockBlockCtaCtaLink': {
        return (
          <PackageComponentWrapper minWidth={minWidth}>
            <Link
              href={cta.cta_link.link}
              aria-label={cta.cta_link.aria_label}
              id={anchor}
              data-test="image-block"
              className={classNames('cta-image-block', {
                'container-lr': !fullwidth
              })}>
              <ImageComp />
            </Link>
          </PackageComponentWrapper>
        );
      }
      case 'CuraleafPageModularBlocksImageBlockBlockCtaCtaButton': {
        return (
          <PackageComponentWrapper minWidth={minWidth}>
            <div
              className={classNames('cta-image-block', {
                'container-lr': !fullwidth
              })}>
              <ImageComp />
              <div className="cta-wrapper">
                <CTAButton
                  url={cta.cta_button.link}
                  text={cta.cta_button.text}
                  variant={cta.cta_button.variant}
                />
              </div>
            </div>
          </PackageComponentWrapper>
        );
      }
      default: {
        return null;
      }
    }
  }

  return (
    <PackageComponentWrapper minWidth={minWidth}>
      <div
        id={anchor}
        data-test="image-block"
        className={classNames('image-block', {
          'container-lr': !fullwidth
        })}>
        <ImageComp />
      </div>
    </PackageComponentWrapper>
  );
};
