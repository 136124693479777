import { Loader } from './loader';
import { CartSummary as Default } from './default';

import { ErrorBoundary } from '../../utils/nullErrorBoundary';

import './cart-summary.scss';

export type CartSummaryProps = {
  enabledLogin: boolean;
  isLoading: false;
  shopLink: string;
  userParam?: string;
};

export type LoadingProps = {
  isLoading: true;
};

export const CartSummary = ({
  props
}: {
  props: CartSummaryProps | LoadingProps;
}): JSX.Element | null =>
  props.isLoading ? (
    <Loader />
  ) : (
    <ErrorBoundary component="cart-summary">
      <Default {...props} />
    </ErrorBoundary>
  );
