import { Curaql_LoyaltyTierPrestige } from 'services';
import { PackageComponentWrapper } from '../../../PackageComponentWrapper';
import { ErrorBoundary } from '../../../../utils/nullErrorBoundary';

import './status-bar.scss';

type StatusBarProps = {
  currentTierPrestige: Curaql_LoyaltyTierPrestige;
  nextTierPrestige: Curaql_LoyaltyTierPrestige;
  tierProgress: number;
};

export const StatusBar = ({
  currentTierPrestige,
  nextTierPrestige,
  tierProgress
}: StatusBarProps) => {
  return (
    <PackageComponentWrapper>
      <ErrorBoundary component="loyalty-status-bar">
        <div className="loyalty-status-bar margin-bottom-32">
          <div className="status-bar-background bg-secondary-light">
            <div
              className="status-bar bg-primary"
              style={{ width: `${tierProgress}%` }}
              data-full-bar={tierProgress >= 100}></div>
          </div>
          <div className="d-flex justify-content-between sub-header">
            <span className="body-m subtitle">
              {currentTierPrestige === nextTierPrestige
                ? ''
                : currentTierPrestige}
            </span>
            <span className="body-m subtitle">{nextTierPrestige}</span>
          </div>
        </div>
      </ErrorBoundary>
    </PackageComponentWrapper>
  );
};
