import { Checkbox } from 'primereact/checkbox';

import { dutchieFriendlyName } from 'services';
import { FilterAccordionProps } from '../index';
import { FilterKeys, FilterMenuItem } from '../../index';

import Check from '../../../../../../assets/svgs/shared/check.svg';

export const FilterCheckboxItem = ({
  item,
  onSelect,
  header
}: {
  item: FilterMenuItem;
  onSelect: FilterAccordionProps['onSelect'];
  header: FilterKeys;
}) => {
  return (
    <div className="filter-item-checkbox d-flex flex-row align-items-center">
      <Checkbox
        icon={<Check />}
        tabIndex={0}
        data-test="checkbox"
        value={item.label}
        checked={item.isSelected}
        onChange={() => onSelect(header, item)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') onSelect(header, item);
        }}
      />
      <label>
        {header !== 'Brands' && header !== 'Options'
          ? dutchieFriendlyName(item.label)
          : // some logic to make apparel items uppercase
            // fortunately casing doesnt matter here
            header === 'Options' &&
              (item.label.includes('x') || item.label.length === 1)
            ? item.label.toUpperCase()
            : item.label}
      </label>
    </div>
  );
};
