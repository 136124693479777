'use client';

import { useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';

import {
  CarouselBanner,
  CarouselFunctionState,
  CarouselProps,
  CarouselSlideOptions
} from './types';

import { nextSlide, clickPrev, clickNext } from './helpers';

import './carousel.scss';

export const Carousel = ({
  data,
  children,
  states
}: CarouselProps): JSX.Element | null => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const {
    setSelectedItem,
    setClassName,
    activeImageIndex,
    setActiveImageIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  } = states;

  const functionStates: CarouselFunctionState & CarouselSlideOptions = {
    setActiveImageIndex,
    activeImageIndex,
    setClassName,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  };

  const slideOptions = {
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  };

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    const filtered = (banners: CarouselBanner[]) => {
      if (banners[activeImageIndex]?.id) {
        return banners.find(
          (item: CarouselBanner) => item.id === banners[activeImageIndex]?.id
        );
      }
      return banners[0];
    };

    const selectedItem = filtered(data);
    selectedItem ? setSelectedItem(selectedItem) : null;
  }, [activeImageIndex]);

  const timeToSlide = autoSlideSeconds ? autoSlideSeconds * 1000 : 5000;

  useEffect(() => {
    if (autoSlide) {
      resetTimeout();
      timeoutRef.current = setTimeout(
        () => nextSlide(data, functionStates),
        timeToSlide
      );
    }

    return () => {
      resetTimeout();
    };
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [activeImageIndex, autoSlide]);

  const config = {
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => clickNext(slideOptions, data, functionStates),
    onSwipedRight: () => clickPrev(slideOptions, data, functionStates),
    ...config
  });

  return (
    <section {...handlers} style={{}} className="carousel">
      {children}
    </section>
  );
};

export default Carousel;
