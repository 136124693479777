import { Dispatch, SetStateAction, useState } from 'react';
import { useRouter } from 'next/navigation';

import { DesktopNavigationProps } from './desktop';
import { Sublink } from '../sublink';
import { HeaderDrawer } from '../Drawer';
import { UserCart } from '../userCart';
import { Searchbar } from '../Search';

import CuraleafLogo from '../../../assets/svgs/header/full-logo-teal.svg';
import Hamburger from '../../../assets/svgs/header/hamburger.svg';

type MobileNavigationProps = DesktopNavigationProps & {
  toggleNav: (val: boolean) => void;
  setOpenSearch?: Dispatch<SetStateAction<boolean>>;
};

const MobileNavigation = (props: MobileNavigationProps) => {
  const { push } = useRouter();
  const [openSearch, setOpenSearch] = useState(false);

  return (
    <header
      data-search-variant={openSearch}
      className="c-header bg-white d-flex align-items-center justify-content-between"
      onKeyDown={(event) => event.key === 'Escape' && props.closeNav()}>
      {openSearch ? (
        <Searchbar setOpenSearch={setOpenSearch} />
      ) : (
        <div className="nav-container d-flex">
          <div className="nav-header d-flex justify-content-between align-items-center">
            <div
              role="navigation"
              tabIndex={0}
              className="mobile-nav-toggle padding-0 text-left pointer"
              onClick={() => props.toggleNav(true)}
              onKeyDown={(event) =>
                event.key === 'Enter' && props.toggleNav(true)
              }>
              <Hamburger
                aria-controls="navigation"
                aria-expanded={props.navOpen}
              />
            </div>
            <div
              tabIndex={0}
              className="pointer nav-logo margin-0 text-center"
              onClick={() => push('/')}
              onKeyDown={(event) => event.key === 'Enter' && push('/')}>
              <CuraleafLogo />
            </div>
            <UserCart
              search={{ openSearch, setOpenSearch }}
              setShowTooltip={props.setShowTooltip}
            />
          </div>
          <HeaderDrawer {...props} type="navigation">
            <Sublink {...props.sublinksProps} />
          </HeaderDrawer>
        </div>
      )}
    </header>
  );
};

export default MobileNavigation;
