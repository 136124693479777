'use client';

import { useSiteWideContext } from '../../hooks/siteWideContext';
import { useCarousel } from '../../hooks/useCarousel';

import { ArticleCard, ArticleCardProps } from './ArticleCard';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import Carousel from '../Carousel';

import './article-grid.scss';

export type ArticleGridProps = {
  articles: ArticleCardProps[];
  heading?: string;
};

export const ArticleGrid = ({
  articles,
  heading
}: ArticleGridProps): JSX.Element | null => {
  const { isMobile } = useSiteWideContext();

  const {
    setSelectedItem,
    setClassName,
    selectedItem,
    activeImageIndex,
    setActiveImageIndex,
    CAROUSEL_LIMITER
  } = useCarousel(articles, false, 0);

  const states = {
    setSelectedItem,
    setClassName,
    selectedItem,
    activeImageIndex,
    setActiveImageIndex
  };

  // Articles will stack on mobile if there are less items than the CAROUSEL_LIMITER
  if (articles.length > CAROUSEL_LIMITER && isMobile) {
    return (
      <PackageComponentWrapper>
        <div className="article-grid container">
          <div className="article-grid-container">
            {heading ? (
              <h2
                data-test="promo-grid-title"
                className="text-primary h2 margin-bottom-32">
                {heading}
              </h2>
            ) : null}
            <div className="carousel-container" data-test="promo-grid-carousel">
              {articles && (
                <Carousel data={articles} states={states}>
                  {articles.map((article) => {
                    return (
                      <div
                        key={article.id}
                        className="carousel-el"
                        style={{
                          transform: `translate3d(${
                            -activeImageIndex * 100
                          }%, 0, 0)`
                        }}>
                        <ArticleCard {...article} />
                      </div>
                    );
                  })}
                </Carousel>
              )}
            </div>
          </div>
        </div>
      </PackageComponentWrapper>
    );
  }
  return (
    <PackageComponentWrapper>
      <div className="article-grid container">
        <div
          className="grid-container article-grid-container"
          data-test="article-grid-container">
          {heading ? (
            <h2
              className="text-primary h2 margin-bottom-32"
              data-test="promo-grid-title">
              {heading}
            </h2>
          ) : null}
          <div className="article-container" data-test="article-container">
            {articles.map((element: ArticleCardProps) => (
              <ArticleCard
                {...element}
                data-test="grid-item"
                key={element.id}
              />
            ))}
          </div>
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
