import moment from 'moment';
import { AccountPreferencesFormFields } from '../templates/Account/settings';
import { AuthContextTypes, logout as logoutUser } from 'services';
import { UpdateUserProps } from '../hooks/graphs';
import { SiteWideWrapperProps } from '../hooks/siteWideContext';

// To supress warning for users birthday on account page
moment.suppressDeprecationWarnings = true;

export const userIntialize = (
  statesNdispensaries: {
    state: string;
    dispensaries: { name: string; id: string }[];
  }[],
  user: AuthContextTypes['user']
): AccountPreferencesFormFields | void => {
  if (user) {
    const matchingDispoList: string[] = [];
    if (user.dispensaryPreferences) {
      statesNdispensaries.forEach((state) => {
        for (const dispensary of state.dispensaries) {
          if (user.dispensaryPreferences?.includes(dispensary.id)) {
            matchingDispoList.push(dispensary.name);
          }
        }
      });
    }

    return {
      ...user,
      birthday: moment.utc(user.birthday).format('L'),
      mainSubscriptionStatus: user.mainSubscriptionStatus === 'opted_in',
      smsSubscriptionStatus: user.smsSubscriptionStatus || false,
      brandPreference: user.brandPreference || [],
      categoryPreference: user.categoryPreference || [],
      dispensaryPreferences: matchingDispoList
    };
  }
};

export const userParams = (
  values: AccountPreferencesFormFields,
  statesNdispensaries: {
    state: string;
    dispensaries: { name: string; id: string }[];
  }[]
): UpdateUserProps => {
  const queryParams = {
    ...values,
    mainSubscriptionStatus:
      typeof values.mainSubscriptionStatus === 'boolean' &&
      values.mainSubscriptionStatus
        ? 'opted_in'
        : 'unsubscribed'
  };

  if (values.state && values.dispensaryPreferences?.length) {
    const dispoList: { name: string; id: string }[] = statesNdispensaries
      .map((state) => {
        return state.dispensaries;
      })
      .filter((stateDispensaries) => {
        return stateDispensaries.length;
      })
      .flat();
    if (dispoList.length) {
      const matchingDispoIdList = [];
      for (const n of values.dispensaryPreferences) {
        if (typeof n === 'string') {
          const matchingDispo = dispoList.find((d) => d.name === n);
          if (matchingDispo) {
            matchingDispoIdList.push(matchingDispo.id);
          }
        }
      }
      queryParams.dispensaryPreferences = matchingDispoIdList;
    }
  }
  if (typeof values.birthday === 'string') {
    const birthdaySplit = values.birthday.split('/');
    queryParams.birthMonth = birthdaySplit[0];
    queryParams.birthDay = birthdaySplit[1];
    queryParams.birthYear = birthdaySplit[2];
  }

  return {
    ...queryParams
  } as UpdateUserProps;
};

export const logout = async (
  clearUser: SiteWideWrapperProps['clearUser'],
  clearAnonCart: SiteWideWrapperProps['clearAnonCart']
) => {
  return logoutUser().finally(() => {
    clearUser();
    clearAnonCart ? clearAnonCart() : null;
  });
};
