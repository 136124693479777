'use client';

import { Dispatch } from 'react';
import classNames from 'classnames';

import { capitalize, StoreCardDispensary } from 'services';
import { CTAButton, CTAButtonProps } from '../CTAButton';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { deliveryTypeCheck } from '../../utils/ecommerce';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';

import SelectedIcon from '../../assets/svgs/shared/icon-selected.svg';

import './store-card.scss';

export type StoreCardProps = {
  dispensary: Omit<StoreCardDispensary, 'deliveryOnly' | 'openTimes' | 'type'>;
  drawerType?: StoreCardDispensary['orderTypes'][number];
  openStoreDrawer?: Dispatch<boolean>;
  type: 'drawer' | 'location' | 'overview' | 'kiosk';
};

export const StoreCard = ({
  dispensary,
  drawerType,
  openStoreDrawer,
  type
}: StoreCardProps): JSX.Element | null => {
  const {
    clearCart,
    isMobile,
    selectedDispensaryID,
    setSelectedDispensaryID,
    setUserOrderType,
    userMenuType
  } = useSiteWideContext();

  if (!dispensary) return null;

  const handleShopClick = () => {
    if (openStoreDrawer) openStoreDrawer(false);
    if (
      drawerType &&
      drawerType === 'DELIVERY' &&
      dispensary.orderTypes?.length
    ) {
      const hasDelivery = deliveryTypeCheck(
        dispensary.orderTypes,
        userMenuType
      );
      setUserOrderType(hasDelivery ? drawerType : 'PICKUP');
    } else {
      setUserOrderType('PICKUP');
    }
    if (selectedDispensaryID !== dispensary.uid && clearCart) {
      clearCart();
      // Temp fix for older devices
      setSelectedDispensaryID(dispensary.uid);
    }
  };

  const handleDetailClick = () => {
    if (openStoreDrawer) openStoreDrawer(false);
  };

  const cardTags = () => {
    const tags: string[] = [];
    dispensary.menuTypes.map((mt) => {
      if (mt === 'RECREATIONAL') {
        tags.push('Adult-Use');
      } else {
        tags.push(capitalize(mt));
      }
    });
    dispensary.orderTypes.some((ot) => {
      if (ot.includes('DELIVERY')) {
        tags.push(
          ot.includes('_')
            ? ot.replace(
                /_(.+)/,
                (_, match) =>
                  `-${match.charAt(0).toUpperCase()}${match
                    .slice(1)
                    .toLowerCase()}`
              )
            : capitalize(ot)
        );
      }
    });

    if (tags.length) {
      return tags.map((tag, i) => {
        const delivery = tag.toLowerCase().includes('delivery');
        return (
          <span data-delivery-tag={delivery} key={i} className="tag body-sm">
            {tag}
          </span>
        );
      });
    }
    return null;
  };

  const isSelectedDispensary =
    type === 'drawer' && dispensary.uid === selectedDispensaryID;
  const prerelease = dispensary.isPrerelease;
  let shopBtn: CTAButtonProps = {
    callBackFunc: handleShopClick,
    url: dispensary.shopLink,
    text: 'Shop',
    variant: isSelectedDispensary ? 'tertiary' : 'secondary'
  };
  if (dispensary.externalUrl) {
    shopBtn = {
      text: 'Shop',
      url: dispensary.externalUrl,
      variant: 'secondary'
    };
  }
  if (prerelease) {
    shopBtn = {
      allowEmptyUrl: true,
      disabled: true,
      text: 'Coming Soon'
    };
  }

  return (
    <PackageComponentWrapper minWidth={false}>
      <ErrorBoundary component="store-drawer-card">
        <div
          data-selected={isSelectedDispensary}
          aria-label={`Shop at ${dispensary.friendlyName}`}
          tabIndex={0}
          className="store-card d-flex flex-column bg-white">
          {isSelectedDispensary ? (
            <div className="selected-icon">
              <SelectedIcon />
            </div>
          ) : null}
          <div className="d-flex justify-content-between align-items-end">
            <h3 className="body-xl text-primary margin-bottom-0">
              {dispensary.friendlyName}
            </h3>
            {dispensary.location.distance ? (
              <span className="body-m text-black">
                {dispensary.location.distance} mi
              </span>
            ) : null}
          </div>
          <div className="store-info d-flex flex-column">
            {dispensary.todaysHours ? (
              <div className="todays-hours">
                <span className="body-m font-bold text-primary">
                  Today&apos;s Hours:
                  {isMobile && dispensary.todaysHours.includes(', ') ? (
                    <br />
                  ) : null}
                  <span className="body-m text-black margin-left-5">
                    {dispensary.todaysHours}
                  </span>
                </span>
              </div>
            ) : null}
            <div className="tags d-flex body-sm">{cardTags()}</div>
            <address className="body-m address text-black">
              {dispensary.location.address}
              <br />
              {`${dispensary.location.city}, ${dispensary.location.state.abbreviation} ${dispensary.location.zipcode}`}
            </address>
          </div>
          <div
            className={classNames('store-card-buttons ', {
              'button-group d-flex gap-8': !prerelease
            })}>
            {type === 'overview' ? null : (
              <CTAButton
                {...{ ...shopBtn }}
                fullWidthMobile={prerelease}
                fullWidth={prerelease}
              />
            )}
            {prerelease || type === 'kiosk' ? null : (
              <CTAButton
                fullWidthMobile={false}
                text="Details"
                callBackFunc={handleDetailClick}
                url={`/dispensary/${dispensary.location.state.slug}/${dispensary.slug}`}
                variant={isSelectedDispensary ? 'secondary' : 'tertiary'}
              />
            )}
          </div>
        </div>
      </ErrorBoundary>
    </PackageComponentWrapper>
  );
};
