import { Dispatch, SetStateAction } from 'react';
import { Sidebar } from 'primereact/sidebar';

import { ErrorBoundary } from '../../../utils/nullErrorBoundary';
import CloseIcon from '../../../assets/svgs/close/close-teal.svg';

import {
  ProductListTile,
  ProductListTileOrderProps
} from '../../ProductListTile';
import { useSiteWideContext } from '../../../hooks/siteWideContext';
import {
  FontOption,
  SummaryBlock,
  SummaryBlockProps,
  ValueType
} from '../../EcomSummaryBlock';
import {
  SummaryCardHeader,
  SummaryCardHeaderProps
} from '../SummaryCardHeader';

import './order-history-drawer.scss';
import { Curaql_TransactionRecord } from 'services';

const itemsMap = (
  item: NonNullable<NonNullable<Curaql_TransactionRecord['items']>[number]>
) => {
  return {
    option: `${item.size}g`,
    price: item.itemUnitPrice,
    product: {
      brand: { name: item.brand },
      images: [{ url: item.imageUrl }],
      name: item.onlineTitle,
      strainType: item.lineage
    },
    productId: item.productId,
    quantity: item.quantity
      ? Math.floor(item.quantity as unknown as number)
      : 1, // TODO remove this, type was being weird
    subtotal: 0
  } as unknown as ProductListTileOrderProps['item'];
};

type Props = {
  transaction: Curaql_TransactionRecord | null;
  setVisibility: Dispatch<SetStateAction<boolean>>;
  visible: boolean;
};

export const OrderHistoryDrawer = ({
  transaction,
  setVisibility,
  visible
}: Props): JSX.Element | null => {
  const { isMobile } = useSiteWideContext();

  const closeDrawer = () => setVisibility(false);

  const mapTransactionSummary = (
    order: Curaql_TransactionRecord
  ): SummaryBlockProps['info'] => {
    const summaryInfo: SummaryBlockProps['info'] = [
      {
        keyName: 'Loyalty Points Earned',
        value: `${order.loyaltyPts} pts`,
        keyOptions: {
          tooltip: 'This is a test'
        },
        valueOptions: {
          type: ValueType.Chip
        }
      },
      {
        keyName: 'Subtotal',
        value: order.totalSales,
        valueOptions: {
          type: ValueType.Price
        },
        lineOptions: {
          separatorAbove: true
        }
      },
      {
        keyName: 'Discounts',
        value: order.totalDiscount,
        valueOptions: {
          type: ValueType.Discount
        },
        lineOptions: {
          skip: order.totalDiscount ? !order.totalDiscount : true
        }
      },
      {
        keyName: 'Loyalty Points Applied',
        value: order.loyaltyUsed,
        valueOptions: {
          type: ValueType.Discount
        },
        lineOptions: {
          skip: order.loyaltyUsed ? !order.loyaltyUsed : true,
          indent: true
        }
      },
      {
        keyName: 'Taxes',
        value: order.totalTax,
        valueOptions: {
          type: ValueType.Price
        }
      },
      {
        keyName: 'Total',
        value: order.finalTotal,
        valueOptions: {
          type: ValueType.Price
        },
        lineOptions: {
          separatorAbove: true,
          font: FontOption.Bold
        }
      }
    ];
    if (order.discountSummary?.length) {
      const mappedDiscounts: any[] = []; // todo: improve this type
      (order.discountSummary as any[])
        .filter((d) => d)
        .map((discount: { name: string; value: string }) => {
          if (!discount) return;

          mappedDiscounts.push({
            keyName: discount.name as string,
            value: discount.value as string,
            valueOptions: {
              type: ValueType.Discount
            },
            lineOptions: {
              indent: true
            }
          });
        });
      summaryInfo.splice(3, 0, ...mappedDiscounts);
    }
    return summaryInfo;
  };

  return (
    <Sidebar
      visible={visible}
      position={!isMobile ? 'right' : 'bottom'}
      className="curaleaf-ecommerce order-history-sidebar"
      onHide={closeDrawer}>
      <ErrorBoundary component="order-details-drawer">
        <div className="close-container d-flex justify-content-end">
          <button
            aria-label="Close order details drawer"
            aria-hidden="true"
            onClick={closeDrawer}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                closeDrawer();
              }
            }}
            className="close-icon pointer">
            <CloseIcon />
          </button>
        </div>
        <div className="sidebar-header">
          <h2 className="margin-bottom-32 text-primary h3">Order Details</h2>
        </div>
        {transaction &&
        transaction.transactionItemDateTime &&
        transaction.items &&
        transaction.finalTotal ? (
          <>
            <div className="information-summary-container d-flex flex-column">
              <SummaryCardHeader
                transactionDateTime={transaction.transactionItemDateTime}
                items={
                  transaction.items as unknown as SummaryCardHeaderProps['items']
                }
                totalPrice={transaction.finalTotal}
                loyalty={transaction.loyaltyPts}
              />
              <div className="order-status-information margin-inline-30">
                <div className="dispensary-order-information">
                  <p className="body-m margin-0">{transaction.location}</p>
                </div>
              </div>
            </div>
            <div className="tile-container padding-inline-30 d-flex flex-column">
              {transaction.items.map((item: any, i: number) => {
                const specialPrice =
                  parseFloat(item.itemUnitPrice) -
                  parseFloat(item.discount) / parseFloat(item.quantity);
                return (
                  <ProductListTile
                    key={i}
                    props={{
                      item: itemsMap(item),
                      isLoading: false,
                      variant: {
                        id: item.productId,
                        option: `${item.size}g`,
                        price: parseFloat(item.itemUnitPrice),
                        specialPrice,
                        isSpecial:
                          specialPrice !== parseFloat(item.itemUnitPrice)
                      },
                      type: 'order',
                      isOrderHistory: true
                    }}
                  />
                );
              })}
            </div>
            <div className="order-total-wrapper d-flex padding-inline-30">
              <div className="order-total margin-bottom-16 text-black">
                <SummaryBlock
                  props={{
                    isLoading: false,
                    info: mapTransactionSummary(transaction)
                  }}
                />
              </div>
            </div>
          </>
        ) : null}
      </ErrorBoundary>
    </Sidebar>
  );
};
