import { useEffect, useState } from 'react';

import { AuthStatus } from 'services';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';

import { PackageComponentWrapper } from '../../PackageComponentWrapper';

export type FormStatusProps = AuthStatus & {
  type: 'success' | 'error' | '';
};

export const FormStatus = ({ link, text, type }: FormStatusProps) => {
  const [disable, setDisable] = useState(false);
  const [hideError, setHideError] = useState(false);
  const [btn, setBtn] = useState({
    text,
    submit: false
  });

  useEffect(() => {
    if (link) {
      setBtn({
        text: link.text,
        submit: false
      });
    }
  }, [link]);

  if (!type || hideError) return null;

  return (
    <PackageComponentWrapper>
      <ErrorBoundary component="form-status">
        <div className={`status-${type}`}>
          <div className="d-flex justify-content-between align-items-center">
            <small className="d-flex">
              <i className="pi pi-info-circle" />
              {text}
            </small>
            <i className="pi pi-times" onClick={() => setHideError(true)} />
          </div>
          {link ? (
            <button
              type="button"
              className={`${type}-button`}
              disabled={disable}
              onClick={async () => {
                const cb = link.callback();
                if (cb instanceof Promise) {
                  cb.then(({ data }) => {
                    if (typeof data === 'string') {
                      setBtn({ text: data, submit: true });
                    }
                    setDisable(true);
                  }).catch((err) => {
                    console.error(err);
                  });
                }
              }}>
              {btn.submit ? <i className="pi pi-check" /> : null}
              {btn.text}
            </button>
          ) : null}
        </div>
      </ErrorBoundary>
    </PackageComponentWrapper>
  );
};
