import { LoadingSkeleton } from '../Loader/Skeleton';

export const Loader = () => {
  return (
    <a className="special-tile-container bg-white pointer d-flex align-items-center">
      <div className="special-icon">
        <LoadingSkeleton width="36px" height="36px" variant="round-img" />
      </div>
      <p className="special-title body-xl margin-0 text-primary">
        <LoadingSkeleton width="100%" />
      </p>
    </a>
  );
};
