import { SubcategoryTileProps } from '.';

export const SubcategoryTile = ({
  props
}: {
  props: SubcategoryTileProps;
}): JSX.Element | null => {
  const { filter, onSelectFilter } = props;

  return (
    <button
      data-selected={filter.isSelected}
      onClick={() => onSelectFilter('Subcategories', filter)}
      className="bg-white body-l font-bold text-primary subcategory-button sm-border-radius">
      {filter.label}
    </button>
  );
};
