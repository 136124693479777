import {
  ContactFormDataType,
  CustomerFormDataType,
  MarketingFormBannerDataType,
  MarketingFormDataType,
  PrereleaseFormDataType
} from 'services';

export const contactUsMap = (form: ContactFormDataType) => {
  return {
    brazeInstance:
      process.env.NODE_ENV === 'development'
        ? '[Dev] Curaleaf'
        : form.brazeInstance,
    formData: {
      dispensaryPreference: form.dispensary,
      email: form.email.trim(),
      firstName: form.firstName.trim(),
      lastName: form.lastName.trim(),
      optIn: !!form.optIn,
      phone: form.phone?.trim(),
      state: form.state,
      topic: form.topic,
      topicDescription: form.topicDescription
    },
    formUid: form.formUid,
    submitToPos: false,
    submitToCDP: !!form.optIn,
    customerIsCbd: false
  };
};

export const customerIntakesMap = (
  form: CustomerFormDataType,
  uniqueId: string
) => {
  return {
    brazeInstance:
      process.env.NODE_ENV === 'development' ? '[Dev] Curaleaf' : 'Curaleaf',
    dispensaryUid: uniqueId,
    formData: {
      birthday:
        form.birthday instanceof Date ? form.birthday.toUTCString() : undefined,
      bypassMedicalCard: !!form.bypassMedicalCard,
      customerType: form.customerType || '',
      dispensaryPreference: form.dispensary || undefined,
      hdyhau: form.hearAboutUs,
      email: form.email.trim(),
      medicalCard:
        form.customerType === 'Medical' && form.medicalCard
          ? form.medicalCard
          : undefined,
      optIn: !!form.optIn,
      phone: form.phone?.trim(),
      preferredMethodOfCommunication: {
        phone: form.optInMethod.includes('Text'),
        email: form.optInMethod.includes('Email')
      },
      state: form.state,
      zipCode: form.zipcode?.trim()
    },
    submitToPos: true,
    submitToCDP: !!form.optIn,
    customerIsCbd: false
  };
};

export const marketingIntakeMap = (form: MarketingFormDataType) => {
  return {
    brazeInstance:
      process.env.NODE_ENV === 'development'
        ? '[Dev] Curaleaf'
        : form.brazeInstance,
    formData: {
      dispensaryPreference: form.dispensary,
      email: form.email.trim(),
      optIn: !!form.optIn,
      preferredMethodOfCommunication: {
        email: form.optInMethod.includes('Email')
      },
      state: form.state
    },
    formUid: form.formUid,
    submitToPos: false,
    submitToCDP: !!form.optIn,
    customerIsCbd: false
  };
};

export const prereleaseMap = (form: PrereleaseFormDataType) => {
  return {
    brazeInstance:
      process.env.NODE_ENV === 'development' ? '[Dev] Curaleaf' : 'Curaleaf',
    formData: {
      cardStatus: form.cardStatus,
      email: form.email.trim(),
      optIn: !!form.optIn,
      phone: form.phone?.trim(),
      preferredMethodOfCommunication: {
        phone: form.optInMethod.includes('Text'),
        email: form.optInMethod.includes('Email')
      }
    },
    formUid: 'prerelease',
    submitToPos: false,
    submitToCDP: !!form.optIn,
    customerIsCbd: false
  };
};

export const marketingIntakeBannerMap = (form: MarketingFormBannerDataType) => {
  return {
    brazeInstance:
      process.env.NODE_ENV === 'development' ? '[Dev] Curaleaf' : 'Curaleaf',
    formData: {
      email: form.email.trim(),
      optIn: !!form.optIn,
      state: form.state,
      preferredMethodOfCommunication: {
        email: !!form.email
      }
    },
    formUid: 'opt-in-banner',
    submitToPos: false,
    submitToCDP: !!form.optIn,
    customerIsCbd: false
  };
};
