import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { ProductFilter } from './ProductFilter';
import { ProductListTile } from '../ProductListTile';
import { ProductTile } from '../ProductTile';

import { LoadingProps } from './index';
import { ProductSortFilter } from './ProductSortFilter';
import { TextBlock } from '../TextBlock';

export const Loader = ({
  pageData,
  view
}: LoadingProps): JSX.Element | null => {
  const emptyTileArray = new Array(8).fill(undefined);

  return (
    <PackageComponentWrapper additionalClass="bg-ecom">
      <div className="ecom-product-grid-wrapper">
        <ProductSortFilter
          props={{
            isLoading: true,
            pageTitle: pageData.pageTitle
          }}
        />
        <div className="d-flex">
          <div className="dt-product-filter-wrapper">
            <ProductFilter
              props={{
                isLoading: true
              }}
            />
          </div>
          <div className="ecom-grid-wrapper">
            {pageData.type === 'AllBrandsPage' ? (
              <div className="ecom-product-text-block-wrapper container-lr">
                <TextBlock
                  align="center"
                  content={[
                    '<h2 class="mh5 text-primary">Select a brand to get started!</h2>'
                  ]}
                />
              </div>
            ) : pageData.type == 'AllSpecialsPage' ? (
              <div className="ecom-product-text-block-wrapper container-lr">
                <TextBlock
                  align="center"
                  content={[
                    '<h2 class="mh5 text-primary">Select a special to get started!</h2>'
                  ]}
                />
              </div>
            ) : (
              <div
                className={`ecom-product-${view} bg-ecom-bg container-lr margin-top-30`}
                data-test="product-grid">
                {view === 'grid'
                  ? emptyTileArray.map((_, i) => (
                      <ProductTile
                        key={i}
                        props={{
                          isLoading: true
                        }}
                      />
                    ))
                  : emptyTileArray.map((_, i) => (
                      <ProductListTile
                        key={i}
                        props={{
                          isLoading: true,
                          type: 'product'
                        }}
                      />
                    ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
