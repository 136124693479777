'use client';

import classNames from 'classnames';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';

import { useSiteWideContext } from '../../hooks/siteWideContext';
import { ecommerceSanitizeQuery } from '../../utils/url';

import { Sublink } from './sublink';
import { HeaderDrawer } from './Drawer';
import { HeaderPopup } from './Popup';
import MobileNavigation from './View/mobile';
import { HeaderNavigationProps } from './index';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { ProductPopup } from '../ProductPopup';
import DesktopNavigation from './View/desktop';

import './header-navigation.scss';

export type SublinkState = {
  id?: number;
  show: boolean;
};

export const HeaderNavigation = ({
  navOpen,
  navLinks,
  socialLinks,
  toggleNav,
  userLinks
}: HeaderNavigationProps): JSX.Element | null => {
  const {
    categories,
    isLoggedIn,
    isMobile,
    popup,
    selectedDispensary,
    setAccountDrawerMode,
    setAccountDrawerVisible
  } = useSiteWideContext();
  const { push } = useRouter();
  const pathname = usePathname();
  const prevPath = useRef(pathname);
  const [showMore, setShowMore] = useState(false);
  const [showSublinks, setShowSublinks] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [sublinks, setSublinks] = useState<SublinkState>({
    id: undefined,
    show: false
  });
  const [headerLinks, setHeaderLinks] = useState([...navLinks]);

  useEffect(() => {
    if (showSublinks || showMore) {
      setShowTooltip(false);
    }
    if (pathname !== prevPath.current) {
      closeNav();
      prevPath.current = pathname;
    }
  }, [pathname, showSublinks]);

  useEffect(() => {
    if (!categories.loading && categories.data?.length && navLinks) {
      const newHeaderLinks = [...navLinks];
      newHeaderLinks.unshift({
        title: 'Shop',
        link: undefined,
        pixelLength: 41,
        sublinks: [
          ...categories.data,
          { title: 'Shop All', link: selectedDispensary?.shopLink }
        ].map((l) => ({
          title: l.title,
          link: l.link,
          sublinks: []
        }))
      });
      setHeaderLinks(newHeaderLinks);
    }
  }, [categories]);

  if (!navLinks) return null;

  const closeNav = () => {
    setSublinks({ show: false });
    setShowSublinks(false);
    setShowMore(false);
    setShowTooltip(false);
    toggleNav(false);
  };

  const openNav = () => {
    setShowSublinks(true);
    toggleNav(true);
  };

  const shopHome = () => {
    toggleNav(false);
    if (selectedDispensary) {
      push(ecommerceSanitizeQuery(selectedDispensary.shopLink));
    }
  };

  const toggleAccountDrawer = (mode: 'login' | 'create') => {
    if (isLoggedIn) {
      setShowTooltip(false);
      push('/account');
    } else {
      setAccountDrawerMode(mode);
      setAccountDrawerVisible(true);
      setShowTooltip(false);
    }
  };

  const hasSublinks = !!(sublinks.show && typeof sublinks.id === 'number');
  const sublinksProps =
    typeof sublinks.id === 'number' && isMobile
      ? {
          navLinks: hasSublinks
            ? // eslint-disable-next-line  @typescript-eslint/no-explicit-any
              (headerLinks[sublinks.id] as any).sublinks
            : headerLinks,
          show: hasSublinks,
          setSublinks
        }
      : {
          isPopup: true,
          navLinks: headerLinks,
          openNav,
          setShowMore,
          setSublinks
        };

  const headerProps = {
    closeNav,
    navOpen,
    setShowTooltip,
    shopHome,
    socialLinks,
    sublinksProps,
    userLinks
  };

  return (
    <PackageComponentWrapper additionalClass="bg-white header-navigation">
      {isMobile ? (
        <MobileNavigation {...headerProps} toggleNav={toggleNav} />
      ) : (
        <DesktopNavigation {...headerProps} />
      )}
      <HeaderDrawer
        closeNav={closeNav}
        navOpen={showSublinks || showMore}
        shopHome={shopHome}
        socialLinks={socialLinks}
        type="popup"
        userLinks={userLinks}>
        {(!isMobile && hasSublinks && typeof sublinks.id === 'number') ||
        showMore ? (
          <Sublink
            navLinks={
              (showMore && !hasSublinks) || typeof sublinks.id !== 'number'
                ? headerLinks
                : // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                  (headerLinks[sublinks.id as number] as any).sublinks
            }
            setSublinks={setSublinks}
            show={showMore && hasSublinks}
          />
        ) : null}
      </HeaderDrawer>
      {popup.show && popup.product ? (
        <div className="product-popup-container">
          <ProductPopup
            props={{
              item: { product: popup.product.product },
              variant: popup.product.variant
            }}
          />
        </div>
      ) : null}
      <div
        className={classNames({
          'bg-blur': navOpen && !showTooltip,
          'p-component-overlay p-component-overlay-enter':
            (isMobile ? navOpen : showMore || hasSublinks) || showTooltip
        })}
        data-tooltip-backdrop={showTooltip}
        onClick={closeNav}
        onKeyDown={(event) => {
          if (event.key === 'Escape') closeNav();
        }}
      />
      <HeaderPopup
        type="user"
        options={{
          closeNav,
          showTooltip,
          toggleAccountDrawer
        }}
      />
    </PackageComponentWrapper>
  );
};
