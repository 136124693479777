import { Loader } from './loader';
import { SpecialTile as Default } from './default';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';

import './special-tile.scss';

export type SpecialTileProps = {
  containingCarouselName?: string;
  containingPageType?: string;
  id: string;
  index: number;
  isLoading: false;
  onClick?: () => void;
  specialLocation?: string;
  title: string;
};

export type LoadingProps = {
  isLoading: true;
};

export const SpecialTile = ({
  props
}: {
  props: SpecialTileProps | LoadingProps;
}): JSX.Element | null => {
  return props.isLoading ? (
    <Loader />
  ) : (
    <ErrorBoundary component="special-tile">
      <Default {...props} />
    </ErrorBoundary>
  );
};
