import { FilterAccordionProps } from './index';
import { FilterCheckboxItem } from './FilterTypes/checkbox';
import { FilterSliderItem } from './FilterTypes/slider';
import { FilterRedirectItem } from './FilterTypes/redirect';
import { FilterMenuItem } from '..';

import { Accordion } from '../../../../Accordion';

export const FilterAccordion = ({
  defaultOpen = false,
  filters,
  header,
  onSelect,
  pageType,
  shopLink
}: FilterAccordionProps): JSX.Element | null => {
  if (!filters[header].length) return null;
  const redirectItem =
    (pageType === 'AllBrandsPage' && header === 'Brands') ||
    header === 'Specials';
  const count = Array.isArray(filters[header])
    ? filters[header].filter((f) => f.isSelected)
    : [];

  return (
    <div className="filter-accordion-wrapper" data-test="filter-accordion">
      <Accordion
        defaultOpen={defaultOpen}
        variant="secondary"
        additionalClass="filter-accordion"
        mode="light"
        header={
          <h3
            className="body-l font-bold text-black margin-0"
            data-test="heading">
            {header} {count.length > 0 ? `(${count.length})` : null}
          </h3>
        }
        content={
          <div
            data-redirect={redirectItem}
            className="filter-content d-flex flex-column">
            {filters[header]?.length
              ? filters[header]?.map((item: FilterMenuItem) => {
                  if (redirectItem) {
                    return (
                      <FilterRedirectItem
                        item={item}
                        key={item.id || item.label}
                        header={header}
                        pageType={pageType}
                        shopLink={shopLink}
                      />
                    );
                  } else if (header === 'Potency') {
                    return (
                      <FilterSliderItem
                        header={header}
                        item={item}
                        key={item.label}
                        onSelect={onSelect}
                      />
                    );
                  } else {
                    return (
                      <FilterCheckboxItem
                        header={header}
                        item={item}
                        key={item.label}
                        onSelect={onSelect}
                      />
                    );
                  }
                })
              : null}
          </div>
        }
      />
    </div>
  );
};
