import { PackageComponentWrapper } from '../PackageComponentWrapper';
import MessageIcon from '../../assets/svgs/shared/icon-chatbot.svg';

import './chatbot.scss';

export const Chatbot = (): JSX.Element | null => {
  if (process.env.NEXT_PUBLIC_SHOW_ZENDESK_CHATBOX !== 'true') {
    return null;
  }

  const openChatbox = () => {
    try {
      if (zE && typeof zE === 'function') {
        zE('messenger', 'open');
      }
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <PackageComponentWrapper>
      <div
        tabIndex={0}
        role="button"
        className="chatbox-tab pointer"
        onClick={openChatbox}
        onKeyDown={(event) => event.key === 'Enter' && openChatbox()}>
        <MessageIcon />
      </div>
    </PackageComponentWrapper>
  );
};
