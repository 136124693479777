import { Markdown } from '../../../Markdown';

import './disclaimer.scss';

type DisclaimerProps = {
  disclaimer?: string;
};

export const FormDisclaimer = ({ disclaimer }: DisclaimerProps) => {
  if (!disclaimer) return null;
  return (
    <div className="form-disclaimer margin-top-16">
      <Markdown content={`<small>${disclaimer}</small>`} />
    </div>
  );
};
