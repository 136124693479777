import Link from 'next/link';

import { DatalayerAnalytics, slugify } from 'services';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { CategoryTileProps } from './index';
import { ecommerceSanitizeQuery } from '../../utils/url';

import AccessoriesIcon from '../../assets/svgs/categories/accessories.svg';
import AllProductsIcon from '../../assets/svgs/categories/all-products.svg';
import ApparelIcon from '../../assets/svgs/categories/apparel.svg';
import ConcentrateIcon from '../../assets/svgs/categories/concentrate.svg';
import DrinksIcon from '../../assets/svgs/categories/drinks.svg';
import EdibleIcon from '../../assets/svgs/categories/edible.svg';
import FlowerIcon from '../../assets/svgs/categories/flower.svg';
import OralIcon from '../../assets/svgs/categories/oral.svg';
import PreRollIcon from '../../assets/svgs/categories/preroll.svg';
import TinctureIcon from '../../assets/svgs/categories/tincture.svg';
import TopicalIcon from '../../assets/svgs/categories/topical.svg';
import VaporizerIcon from '../../assets/svgs/categories/vaporizer.svg';
import { useDispensaryPathContext } from '../../hooks/dispensaryPathContextProvider';

const renderIcon = (category: string) => {
  switch (category) {
    case 'Apparel': {
      return <ApparelIcon />;
    }
    case 'Accessories': {
      return <AccessoriesIcon />;
    }
    case 'Concentrates':
    case 'Extracts': {
      return <ConcentrateIcon />;
    }
    case 'Drinks':
    case 'Liquids': {
      return <DrinksIcon />;
    }
    case 'Edibles': {
      return <EdibleIcon />;
    }
    case 'Flower': {
      return <FlowerIcon />;
    }
    case 'Orals': {
      return <OralIcon />;
    }
    case 'Pre Rolls': {
      return <PreRollIcon />;
    }
    case 'Tinctures': {
      return <TinctureIcon />;
    }
    case 'Topicals': {
      return <TopicalIcon />;
    }
    case 'Vaporizers': {
      return <VaporizerIcon />;
    }
    default:
      return <AllProductsIcon />;
  }
};

export const CategoryTile = ({
  link = '',
  title
}: Omit<CategoryTileProps, 'isLoading'>) => {
  const { selectedDispensary } = useSiteWideContext();
  const { currentPathDispensary } = useDispensaryPathContext();
  const shopLink = currentPathDispensary // currentPathDisp not available on dispensary overview page
    ? currentPathDispensary.shopLink
    : selectedDispensary?.shopLink;

  const url =
    shopLink && title && !link
      ? slugify(`${shopLink}/categories/${title}`).replace(/\/+/g, '/')
      : link;
  if (!url) return null;
  return (
    <div
      className="category-tile-container bg-white sm-border-radius"
      data-test="category-tile">
      <Link
        href={ecommerceSanitizeQuery(url)}
        className="tile flex-column align-items-center justify-content-center"
        prefetch={false}
        onClick={() =>
          DatalayerAnalytics.pushBasicEvent(
            'category_select',
            {
              link_category: 'category',
              link_text: title,
              link_url: url,
              click_location: 'category tile'
            },
            selectedDispensary || undefined
          )
        }>
        {title ? <div className="icon">{renderIcon(title)}</div> : null}
        <h5
          className="body-m font-bold text-primary margin-0"
          data-test="header">
          {title}
        </h5>
      </Link>
    </div>
  );
};
