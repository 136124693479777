'use client';

import { Dispatch, SetStateAction } from 'react';
import { useRouter } from 'next/navigation';
import { HeaderDrawer } from '../Drawer';
import { Sublink, SublinkProps } from '../sublink';
import { Searchbar } from '../Search';
import { UserCart } from '../userCart';
import { UserLink } from '../Drawer/userMenu';
import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { SocialMediaLinksData } from '../../../models/types';

import CuraleafLogo from '../../../assets/svgs/header/full-logo-teal.svg';

export type DesktopNavigationProps = {
  closeNav: () => void;
  navOpen: boolean;
  setShowTooltip: Dispatch<SetStateAction<boolean>>;
  shopHome: () => void;
  socialLinks: SocialMediaLinksData[];
  sublinksProps: SublinkProps;
  userLinks: UserLink[];
};

const DesktopNavigation = ({
  closeNav,
  navOpen,
  setShowTooltip,
  shopHome,
  socialLinks,
  sublinksProps,
  userLinks
}: DesktopNavigationProps) => {
  const { push } = useRouter();
  const { selectedDispensary } = useSiteWideContext();

  return (
    <header
      className="c-header bg-white d-flex align-items-center justify-content-between"
      onKeyDown={(event) => event.key === 'Escape' && closeNav()}>
      <div
        data-no-store={!selectedDispensary}
        className="nav-container d-flex align-items-center justify-content-between">
        <div className="nav-header d-flex">
          <div
            tabIndex={0}
            className="pointer"
            onClick={() => push('/')}
            onKeyDown={(event) => event.key === 'Enter' && push('/')}>
            <CuraleafLogo />
          </div>
        </div>
        <HeaderDrawer
          closeNav={closeNav}
          navOpen={navOpen}
          shopHome={shopHome}
          socialLinks={socialLinks}
          type="navigation"
          userLinks={userLinks}>
          <Sublink {...sublinksProps} />
        </HeaderDrawer>
      </div>
      <div
        data-no-store={!selectedDispensary}
        className="search-user-cart d-flex align-items-center justify-content-end">
        <Searchbar />
        <UserCart setShowTooltip={setShowTooltip} />
      </div>
    </header>
  );
};

export default DesktopNavigation;
