'use client';

import { Params } from 'next/dist/shared/lib/router/utils/route-matcher';

import Cookies from 'js-cookie';

import { DutchiePlus_Generated_Product_Brand } from 'services';

import { useEffect, useState } from 'react';
import { NewProductGridPage } from './newProductGridPage';
import { OldProductGridPage } from './oldProductGridPage';

export type PGPProps = {
  brandData?: DutchiePlus_Generated_Product_Brand | null;
  searchParams: Params;
};

export const ProductGridPage = ({ brandData, searchParams }: PGPProps) => {
  const [renderNew, setRenderNew] = useState(false);

  useEffect(() => {
    const cookieValue = Cookies.get('filterGroup');
    if (cookieValue === 'a') {
      setRenderNew(true);
    } else {
      setRenderNew(false);
    }
  }, []);

  return renderNew ? (
    <NewProductGridPage searchParams={searchParams} brandData={brandData} />
  ) : (
    <OldProductGridPage searchParams={searchParams} brandData={brandData} />
  );
};
