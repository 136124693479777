import Link from 'next/link';

import { useLoyaltyData } from '../../../hooks/graphs';
import { useSiteWideContext } from '../../../hooks/siteWideContext';

import { LoadingSkeleton } from '../../Loader/Skeleton';
import { CTAButton } from '../../CTAButton';
import { PackageComponentWrapper } from '../../PackageComponentWrapper';

import AccountIcon from '../../../assets/svgs/account-banner/icon-account-banner-profile.svg';
import LoyaltyIcon from '../../../assets/svgs/account-banner/icon-account-banner-sparkle.svg';
import ReferralIcon from '../../../assets/svgs/account-banner/icon-account-banner-refer.svg';
import ReferIcon from '../../../assets/svgs/account-banner/icon-refer-friend.svg';
import CheckoutIcon from '../../../assets/svgs/account-banner/icon-checkout.svg';
import PointsIcon from '../../../assets/svgs/account-banner/icon-points.svg';

import './account-banner.scss';

export const AccountBanner = (): JSX.Element | null => {
  const {
    isLoggedIn,
    setAccountDrawerMode,
    setAccountDrawerVisible,
    user,
    width
  } = useSiteWideContext();

  const { loyaltyMemberInfo, loading, error } = useLoyaltyData(isLoggedIn);

  return (
    <PackageComponentWrapper additionalClass="bg-primary">
      {isLoggedIn && !error ? (
        <table className="account-banner logged-in">
          <tbody>
            <tr>
              <td className="box-wrapper">
                <div className="d-flex align-items-center bg-white box">
                  <div className="icon">
                    <AccountIcon />
                  </div>
                  <div className="text-content">
                    <div className="text-upper">
                      <span className="body-xl ellipsis">
                        Welcome back, {user?.firstName}
                      </span>
                      <div className="mobile-loyalty-points">
                        <Link
                          href="/account/loyalty"
                          className="body-sm loyalty-points bg-primary text-white">
                          {loading ? (
                            <LoadingSkeleton width="50px" />
                          ) : (
                            loyaltyMemberInfo?.totalPointsAvailable
                          )}
                          pts
                        </Link>
                      </div>
                    </div>
                    {width && width > 767 ? (
                      <Link href="/account" className="text-primary body-m">
                        My Account
                      </Link>
                    ) : (
                      <div className="account-links">
                        <Link href="/account" className="text-primary body-m">
                          My Account
                        </Link>
                        <Link
                          href="/account/loyalty"
                          className="text-primary body-m">
                          View Rewards
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </td>
              <td className="box-wrapper mobile-hide">
                <div className="d-flex align-items-center bg-white box">
                  <div className="icon">
                    <LoyaltyIcon />
                  </div>
                  <div className="text-content">
                    <div>
                      <span className="body-xl">
                        {loading ? (
                          <LoadingSkeleton width="50px" />
                        ) : (
                          `${loyaltyMemberInfo?.totalPointsAvailable} Points`
                        )}
                      </span>
                    </div>
                    <Link
                      href="/account/loyalty"
                      className="text-primary body-m">
                      View Rewards
                    </Link>
                  </div>
                </div>
              </td>
              <td className="box-wrapper mobile-hide">
                <div className="d-flex align-items-center bg-white box">
                  <div className="icon">
                    <ReferralIcon />
                  </div>
                  <div className="text-content">
                    <div>
                      <span className="body-xl">Refer a Friend</span>
                    </div>
                    <Link
                      href="/account/best-buds"
                      className="text-primary body-m">
                      View
                    </Link>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <div className="account-banner d-flex align-items-center justify-content-between logged-out">
          <div className="left-col">
            <h2 className="text-white title-l text-center margin-0">
              Sign In for the Best Experience
            </h2>
          </div>
          <div className="d-flex justify-content-center align-items-center flex-column center-col">
            <CTAButton
              text="Sign In"
              callBackFunc={() => {
                setAccountDrawerMode('login');
                setAccountDrawerVisible(true);
              }}
              variant="tertiary"
            />
          </div>
          <div className="d-flex align-items-center justify-content-between right-col">
            <div className="text-center">
              <div className="margin-bottom-16">
                <PointsIcon />
              </div>
              <span className="text-white body-m">Earn Points</span>
            </div>
            <div className="text-center">
              <div className="margin-bottom-16">
                <CheckoutIcon />
              </div>
              <span className="text-white body-m">Faster Checkout</span>
            </div>
            <div className="text-center">
              <div className="margin-bottom-16">
                <ReferIcon />
              </div>
              <span className="text-white body-m">Refer Friends</span>
            </div>
          </div>
        </div>
      )}
    </PackageComponentWrapper>
  );
};
