import { ProductTile } from '../ProductTile';

import { LoadingProps } from './index';
import { ProductSortFilter } from './ProductSortFilter';
import { TextBlock } from '../TextBlock';

export const Loader = ({
  pageData,
  view
}: LoadingProps): JSX.Element | null => {
  const emptyTileArray = new Array(8).fill(undefined);

  return (
    <div className="ecom-product-grid-wrapper margin-bottom-32">
      <ProductSortFilter
        props={{
          isLoading: true,
          pageTitle: pageData.pageTitle
        }}
      />
      <div className="d-flex">
        <div className="ecom-grid-wrapper">
          {pageData.type === 'AllBrandsPage' ? (
            <div className="ecom-product-text-block-wrapper">
              <TextBlock
                align="center"
                content={[
                  '<h2 class="h3 text-primary">Select a brand to get started!</h2>'
                ]}
              />
            </div>
          ) : pageData.type == 'AllSpecialsPage' ? (
            <div className="ecom-product-text-block-wrapper">
              <TextBlock
                align="center"
                content={[
                  '<h2 class="h3 text-primary">Select a special to get started!</h2>'
                ]}
              />
            </div>
          ) : (
            <div
              className={`ecom-product-${view} margin-top-32`}
              data-test="product-grid">
              {emptyTileArray.map((_, i) => (
                <ProductTile
                  key={i}
                  props={{
                    isLoading: true,
                    view
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
