import { Consolidate_Product, capitalize } from 'services';

import Tag from '../../../assets/svgs/product/strain-tag.svg';

import './strain-tag.scss';

export type StrainTagProps = {
  strain: Consolidate_Product['strainType'];
};

const getStrain = (
  strainType: Consolidate_Product['strainType']
): string | undefined => {
  if (strainType) {
    switch (strainType) {
      case 'INDICA_HYBRID': {
        return 'Indica';
      }
      case 'SATIVA_HYBRID': {
        return 'Sativa';
      }
      case 'HYBRID':
      case 'INDICA':
      case 'SATIVA': {
        return capitalize(strainType?.toLowerCase());
      }
      default: {
        return undefined;
      }
    }
  }
};

// Note: make sure to make the parent relative
export const StrainTag = ({ strain }: StrainTagProps): JSX.Element | null => {
  const strainParsed = getStrain(strain);
  if (strainParsed) {
    return (
      <>
        <div
          className={`strain-tag ${strainParsed.toLowerCase()}`}
          data-test="strain">
          <Tag />
        </div>
        <span className="strain-name caption font-bold text-white">
          {strainParsed}
        </span>
      </>
    );
  }
  return null;
};
