'use client';

import { Dialog } from 'primereact/dialog';
import { useRouter } from 'next/navigation';
import { useState } from 'react';

import { CTAButton } from '../../CTAButton';
import { SiteWideDispensary } from 'services';

import GeoAltIcon from '../../../assets/svgs/header/geo-alt.svg';
import SmGeoAltIcon from '../../../assets/svgs/header/geo-alt-sm.svg';

import './clear-dispensary-modal.scss';

type ClearDispensaryModalProps = {
  isMobile: boolean;
  selectedDispensary?: SiteWideDispensary | null;
};

export const ClearDispensaryModal = ({
  isMobile,
  selectedDispensary
}: ClearDispensaryModalProps): JSX.Element | null => {
  const [show, setShow] = useState(false);
  const [clicks, setClicks] = useState<number[]>([]);
  const { push } = useRouter();

  return (
    <>
      {show ? (
        <Dialog
          className="clear-dispensary-modal disable-scroll"
          draggable={false}
          header={<h2 className="text-error-dark h3">Clear dispensary?</h2>}
          footer={
            <div className="d-flex">
              <CTAButton
                text="Clear"
                variant="tertiary"
                callBackFunc={() => {
                  setShow(false);
                  push('/?clear=true');
                }}
              />
              <CTAButton
                text="Cancel"
                variant="secondary"
                callBackFunc={() => setShow(false)}
              />
            </div>
          }
          onHide={() => setShow(false)}
          resizable={false}
          visible={show}
        />
      ) : null}
      {selectedDispensary ? (
        <div className="d-flex align-items-center">
          <button
            className="clear-dispensary-button padding-inline-5"
            onClick={() => {
              const now = new Date().getTime();
              const currClicks = [...clicks, now];
              const threeClicks =
                currClicks.length > 3
                  ? currClicks.slice(currClicks.length - 3)
                  : currClicks;
              setClicks(threeClicks);
              if (
                threeClicks.filter((click) => now - click <= 3000).length === 3
              ) {
                setShow(true);
              }
            }}>
            {isMobile ? <SmGeoAltIcon /> : <GeoAltIcon />}
            <span className="selected-store body-m text-white padding-left-5">
              Shopping at {selectedDispensary.friendlyName}
            </span>
          </button>
        </div>
      ) : null}
    </>
  );
};
