import { useState, useEffect } from 'react';
import classNames from 'classnames';

import { getIcon } from '../../../../utils/getIcon';

interface Props {
  label: string;
  tag: string;
  updatePreference: (name: string) => void;
  isActive: boolean;
}

const Toggle = (props: Props): JSX.Element | null => {
  const [active, setactive] = useState<boolean>();

  useEffect(() => {
    if (props.isActive) {
      props.updatePreference(props.tag);
      setactive(props.isActive);
    }
  }, [props.isActive]);

  const handleToggle = () => {
    props.updatePreference(props.tag);
    setactive(!active);
  };
  return (
    <div className="toggle align-items-center" data-test="toggle">
      <div className="text-section d-flex flex-row align-items-center">
        {getIcon(props.tag, false, 0)}
        <p className="toggle-heading font-bold" data-test="lable">
          {props.label}
        </p>
      </div>
      <div
        tabIndex={0}
        className={classNames('toggle-button', {
          active
        })}
        data-test="toggleButton"
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleToggle();
          }
        }}
        onClick={() => handleToggle()}>
        <div
          className="toggle-slider"
          data-test="toggleSlider"
          style={{ right: active ? '0' : '' }}></div>
      </div>
    </div>
  );
};

export default Toggle;
