'use client';

import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { LoadingSkeleton } from '../Loader/Skeleton';

export const Loader = (): JSX.Element | null => {
  return (
    <PackageComponentWrapper>
      <nav
        aria-label="breadcrumb"
        role="navigation"
        data-test="breadcrumb-container"
        className="Breadcrumbs">
        <ul className="d-flex align-items-center margin-0">
          <li className="link-container d-flex align-items-center">
            <p className="breadcrumb-name margin-0">
              <LoadingSkeleton height="24px" width="60px" />
            </p>
            <span className="slash body-l text-secondary-dark">/</span>
          </li>
          <li className="link-container d-flex align-items-center">
            <p className="breadcrumb-name margin-0">
              <LoadingSkeleton height="24px" width="60px" />
            </p>
          </li>
        </ul>
      </nav>
    </PackageComponentWrapper>
  );
};
