import Image from 'next/image';
import Link from 'next/link';
import { BrandTileProps } from '.';

export const BrandTile = ({
  props
}: {
  props: BrandTileProps;
}): JSX.Element | null => {
  const { brand_name, icon, url } = props;

  return (
    <div className="brand-tile-container" data-test="brand-tile">
      <Link
        href={url}
        className="tile flex-column align-items-center justify-content-center">
        <Image
          alt={brand_name}
          src={icon.url}
          width={icon.width}
          height={icon.height}
        />
        <h5
          className="body-l font-bold text-primary margin-0 capitalize"
          data-test="header">
          {brand_name}
        </h5>
      </Link>
    </div>
  );
};
