import classNames from 'classnames';

import { ErrorBoundary } from '../../../utils/nullErrorBoundary';

import XSCart from '../../../assets/svgs/product/cart-xs.svg';
import Cart from '../../../assets/svgs/product/cart.svg';

export type CartButtonProps = {
  additionalClass?: string;
  callBackFunc: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  loading?: boolean;
  showCartIcon?: boolean;
  text: string;
  variant: 'primary' | 'secondary';
  size?: 'xs' | 'sm' | 'lg';
};

import './cart-button.scss';

export const CartButton = ({
  additionalClass,
  callBackFunc,
  disabled,
  loading = false,
  showCartIcon = false,
  size = 'sm',
  text,
  variant = 'primary'
}: CartButtonProps): JSX.Element | null => {
  return (
    <ErrorBoundary component="cart-button">
      <button
        data-button-disabled={disabled}
        onClick={(e) => {
          if (!loading) {
            callBackFunc(e);
          }
        }}
        disabled={disabled}
        className={classNames(
          `cart-button btn ${disabled ? '' : variant} pointer ${additionalClass} ${size}`,
          {
            'body-sm font-bold': size === 'xs',
            'body-m font-bold': size === 'sm',
            'body-l font-bold': size === 'lg'
          }
        )}
        data-test="cart-button"
        title={text}>
        {loading ? (
          <div className="spinner-icon">
            <div className="loading-spinner" />
          </div>
        ) : showCartIcon ? (
          <>
            {size === 'xs' ? <XSCart /> : <Cart />}
            {text}
          </>
        ) : (
          text
        )}
      </button>
    </ErrorBoundary>
  );
};
