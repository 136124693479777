'use client';

import { Dispatch, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { usePathname } from 'next/navigation';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ACTIVE_REFERRALS,
  AuthUser,
  Consolidate_ProductVariant,
  Curaql_Cart,
  Curaql_LoyaltyMemberInfo,
  Curaql_LoyaltyReferralInfo,
  Curaql,
  DutchiePlus_OrderType,
  REFERRAL_URL,
  SUBMIT_REFERRAL,
  DatalayerAnalytics,
  Consolidate_Product,
  Curaql_OrderType
} from 'services';

import { snackbarMessage } from '../../utils/snackbar';
import { addCartCompletion } from '../../utils/ecommerce';
import { checkoutMetaMap } from '../../utils/checkoutMetaMap';
import { useStorage } from '../useStorage';
import { SiteWideWrapperProps } from '../siteWideContext';

import {
  ProductListTileCartProps,
  ProductListTilePopupProps
} from '../../components/ProductListTile';
import { closeSnackbar } from 'notistack';

export type AxiosData<T> = {
  data: {
    data: T;
    errors?: any;
  };
};

export type UpdateUserProps = Omit<AuthUser, '_id' | 'optIn'>;

type UpdateUserData = {
  user: Omit<AuthUser, 'brazeId' | 'optIn' | 'optInSms' | '_id'>;
};

// Account Preferences
export const useUserData = () => {
  const updateUser = useMutation({
    mutationFn: (variables: Omit<AuthUser, '_id' | 'optIn'>) => {
      return axios({
        url: process.env.CURAQL_BASEURL,
        method: 'post',
        data: {
          query: Curaql.UPDATE_USER,
          variables
        },
        withCredentials: true
      });
    },
    onSuccess: ({ data }: AxiosData<UpdateUserData>) => {
      if (data.errors?.length) {
        if (data.errors[0]?.message === 'Failed to add item to cart (2)') {
          Cookies.remove(Curaql.CART_META_KEY);
        } else {
          throw new Error(data.errors[0]?.message);
        }
      } else {
        return data.data.user;
      }
    },
    onError: (error: any) => {
      console.error('updateUser: ', error);
      const message = error.response?.data?.errors?.length
        ? error.response.data.errors[0].message
        : error.toString();
      DatalayerAnalytics.pushErrorEvent({
        category: 'api',
        location: 'updateUser',
        description: message
      });
      snackbarMessage('Something went wrong, please try again.');
    }
  });
  return {
    updateUser: updateUser.mutateAsync
  };
};

type LoyaltyReferralData = {
  activeLoyaltyReferrals: Curaql_LoyaltyReferralInfo[];
};

type LoyaltyURLData = {
  loyaltyReferralUrl: {
    token: string;
  };
};

type SubmitLoyaltyReferralData = {
  loyaltyReferral: {
    message: string;
    success: boolean;
  };
};

// Loyalty Referrals
export const useLoyaltyReferrals = () => {
  const authToken = Cookies.get('curaleafAccountId');
  const isAuthenticated = authToken?.length;

  const {
    isFetching,
    isLoading,
    data: test,
    refetch: refetchReferrals
  } = useQuery({
    queryKey: ['loyaltyReferrals'],
    queryFn: async (): Promise<Curaql_LoyaltyReferralInfo[] | null> => {
      try {
        return axios({
          url: process.env.CURAQL_BASEURL,
          method: 'post',
          data: {
            query: ACTIVE_REFERRALS
          },
          withCredentials: true
        }).then(({ data }: AxiosData<LoyaltyReferralData>) => {
          if (data.errors?.length) {
            throw new Error(data.errors[0].message);
          }
          return data.data.activeLoyaltyReferrals;
        });
      } catch (error) {
        console.error('get loyalty info: ', error);
        return null;
      }
    },
    enabled: !!isAuthenticated
  });

  const {
    isFetching: isURLFetching,
    isLoading: isURLLoading,
    error: urlError,
    data: referralURLData
  } = useQuery({
    queryKey: ['referralToken'],
    queryFn: async (): Promise<string | null> => {
      try {
        return axios({
          url: process.env.CURAQL_BASEURL,
          method: 'post',
          data: {
            query: REFERRAL_URL
          },
          withCredentials: true
        }).then(({ data }: AxiosData<LoyaltyURLData>) => {
          if (data.errors?.length) {
            throw new Error(data.errors[0].message);
          }
          if (data.data.loyaltyReferralUrl?.token) {
            return data.data.loyaltyReferralUrl?.token;
          } else {
            throw new Error('200 Error: missing token');
          }
        });
      } catch (error) {
        console.error('get referral token: ', error);
        return null;
      }
    },
    enabled: !!isAuthenticated
  });

  const submitLoyaltyReferrals = useMutation({
    mutationFn: (variables: { emailToRefer: string; path: string }) => {
      return axios({
        url: process.env.CURAQL_BASEURL,
        method: 'post',
        data: {
          query: SUBMIT_REFERRAL,
          variables
        },
        withCredentials: true
      });
    },
    onSuccess: ({ data }: AxiosData<SubmitLoyaltyReferralData>) => {
      if (data.errors?.length) {
        throw new Error(data.errors[0]?.message);
      } else {
        return data.data.loyaltyReferral;
      }
    },
    onError: (error: any) => {
      console.error('submitLoyaltyReferrals: ', error);

      const message = error.response?.data?.errors?.length
        ? error.response.data.errors[0].message
        : error.toString();
      DatalayerAnalytics.pushErrorEvent({
        category: 'api',
        location: 'useLoyaltyReferrals',
        description: message
      });
      snackbarMessage(
        message && (message.includes('Failed') || message.includes('Sorry'))
          ? message.replace('Error: ', '')
          : 'Something went wrong, please try again.'
      );
    }
  });

  return {
    isAuthenticated,
    loyaltyReferrals: {
      referrals: test,
      loading: isFetching && isLoading,
      refetchReferrals
    },
    loyaltyUrl: {
      token: referralURLData,
      urlError,
      urlLoading: isURLLoading && isURLFetching
    },
    submit: {
      submitLoyaltyReferrals,
      submittingReferrals: submitLoyaltyReferrals.isPending
    }
  };
};

// Loyalty
export const useLoyaltyData = (isLoggedIn: boolean) => {
  const {
    isPending,
    data: loyaltyMemberInfo,
    error,
    refetch: refetchLoyaltyInfo
  } = useQuery({
    queryKey: ['loyaltyInfo'],
    queryFn: async (): Promise<Curaql_LoyaltyMemberInfo | null> => {
      try {
        return axios({
          url: process.env.CURAQL_BASEURL,
          method: 'post',
          data: {
            query: Curaql.GET_LOYALTY_DATA
          },
          withCredentials: true
        }).then(
          ({
            data
          }: {
            data: {
              data: { loyaltyMemberInfo: Curaql_LoyaltyMemberInfo };
              errors: any;
            };
          }) => {
            if (data.errors?.length) {
              throw new Error(data.errors[0].message);
            }
            return data.data.loyaltyMemberInfo;
          }
        );
      } catch (error) {
        console.error('get loyalty info: ', error);
        return null;
      }
    },
    enabled: !!isLoggedIn
  });

  const isNewMember = loyaltyMemberInfo
    ? loyaltyMemberInfo?.currentTier?.prestige === 'Member' &&
      loyaltyMemberInfo.tierProgress === 0 &&
      parseInt(loyaltyMemberInfo.totalPointsAvailable) === 0
    : true;

  useEffect(() => {
    const authToken = Cookies.get('curaleafAccountId');
    if (authToken?.length) {
      refetchLoyaltyInfo();
    }
  }, [isLoggedIn]);

  return {
    loyaltyMemberInfo,
    loading: isPending,
    error,
    isNewMember
  };
};

// Cart
export type AddToCartProps = {
  product: ProductListTilePopupProps['item']['product'];
  quantity: number;
  variant: Consolidate_ProductVariant;
  analyticsContext: {
    list: {
      index?: number;
      id: string;
      name: string;
    };
  };
  baseCartUrl: string;
};

type AddToCartData = {
  addItemToCart: Omit<Curaql_Cart, 'address'>;
};

export type RemoveFromCartProps = {
  item: ProductListTileCartProps['item'];
  selectedVariant?: Consolidate_ProductVariant;
};

export type RemoveFromCartData = {
  removeItemFromCart: Curaql_Cart;
};

export type UpdateOrderTypeProps = {
  orderType: DutchiePlus_OrderType;
};

export type UpdateOrderTypeData = {
  updateOrderType: Omit<
    Curaql_Cart,
    'address' | 'dispensaryUniqueId' | 'updatedAt'
  >;
};

export type MergeCartData = {
  mergeCart: Omit<Curaql_Cart, 'address' | 'dispensaryUniqueId'>;
};

type CartDataProps = Pick<
  SiteWideWrapperProps,
  | 'isLoggedIn'
  | 'selectedDispensary'
  | 'selectedDispensaryID'
  | 'selectedDispensaryLoading'
  | 'user'
  | 'userMenuType'
> & {
  setPopupProduct: SiteWideWrapperProps['popup']['setProduct'];
  setShowPopup: SiteWideWrapperProps['popup']['setShow'];
  updateUserOrderTypeLocalStorage: Dispatch<Curaql_OrderType>;
};

export const useCartData = ({
  isLoggedIn,
  setPopupProduct,
  setShowPopup,
  selectedDispensary,
  selectedDispensaryID,
  selectedDispensaryLoading,
  updateUserOrderTypeLocalStorage,
  user,
  userMenuType
}: CartDataProps) => {
  const queryClient = useQueryClient();
  const [emails] = useStorage<string[]>('employeeEmails', []);

  const asPath = usePathname();

  const defaultMenuType =
    Array.isArray(selectedDispensary?.menuTypes) &&
    selectedDispensary.menuTypes.includes(userMenuType)
      ? userMenuType
      : selectedDispensary?.menuTypes[0];
  let cartMeta = Cookies.get(Curaql.CART_META_KEY);
  let checkoutId =
    cartMeta && JSON.parse(cartMeta).checkoutId
      ? JSON.parse(cartMeta).checkoutId
      : '';
  let dispensaryUniqueId =
    cartMeta && JSON.parse(cartMeta).dispensaryUniqueId
      ? JSON.parse(cartMeta).dispensaryUniqueId
      : selectedDispensaryID;
  let menuType =
    cartMeta && JSON.parse(cartMeta).menuType
      ? JSON.parse(cartMeta).menuType
      : defaultMenuType;

  const {
    data: cart,
    error,
    isFetching,
    isLoading,
    refetch
  } = useQuery({
    queryKey: ['cart'],
    queryFn: async (): Promise<Curaql_Cart | null> => {
      if (!cartMeta) {
        return null;
      }
      return axios({
        url: process.env.CURAQL_BASEURL,
        method: 'post',
        data: {
          query: Curaql.GET_USER_CART,
          variables: {
            dispensaryUniqueId,
            checkoutId
          }
        },
        withCredentials: true
      })
        .then(
          ({
            data
          }: {
            data: {
              data: { cart: Curaql_Cart };
              errors: any;
            };
          }) => {
            if (data.errors?.length) {
              if (data.errors[0].message === 'Not Found') {
                clearAnonCart();
              } else {
                throw new Error(data.errors[0].message);
              }
            }
            return data.data.cart;
          }
        )
        .catch((err) => {
          console.error('get cart: ', err);
          return null;
        });
    },
    enabled: !!cartMeta
  });

  const removeItem = useMutation({
    mutationFn: (variables: RemoveFromCartProps) => {
      return axios({
        url: process.env.CURAQL_BASEURL,
        method: 'post',
        data: {
          query: Curaql.REMOVE_ITEM_FROM_USER_CART,
          variables: {
            dispensaryUniqueId: cart?.dispensaryUniqueId,
            checkoutId: cart?.id,
            itemId: variables.item.id
          }
        },
        withCredentials: true
      });
    },
    onSuccess: (
      { data }: AxiosData<RemoveFromCartData>,
      variables: RemoveFromCartProps
    ) => {
      if (data.errors?.length) {
        throw new Error(data.errors[0]?.message);
      } else {
        Cookies.set(
          Curaql.CART_META_KEY,
          JSON.stringify({
            checkoutId: data.data.removeItemFromCart.id,
            dispensaryUniqueId: data.data.removeItemFromCart.dispensaryUniqueId,
            items: data.data.removeItemFromCart.items.length,
            menuType: data.data.removeItemFromCart.pricingType,
            redirectUrl: data.data.removeItemFromCart.redirectUrl,
            updatedAt: data.data.removeItemFromCart.updatedAt
          })
        );
        queryClient.setQueryData(['cart'], data.data.removeItemFromCart);
        if (variables.selectedVariant) {
          // Is a datalayer purge, not sure if it is necessary
          DatalayerAnalytics.pushBasicEvent('', {
            ecommerce: undefined
          });
        }
      }
    },
    onError: (error: any) => {
      console.error('removeItem: ', error);
      const message = error.response?.data?.errors?.length
        ? error.response.data.errors[0].message
        : error.toString();
      DatalayerAnalytics.pushErrorEvent({
        category: 'api',
        location: 'removeItem',
        description: message,
        consolidateDispensary: selectedDispensary || undefined
      });
    }
  });

  const updateOrderType = useMutation({
    mutationFn: (variables: UpdateOrderTypeProps) => {
      return axios({
        url: process.env.CURAQL_BASEURL,
        method: 'post',
        data: {
          query: Curaql.UPDATE_ORDER_TYPE,
          variables: {
            dispensaryUniqueId: selectedDispensaryID,
            pricingType: menuType,
            checkoutId,
            orderType:
              process.env.IS_KIOSK === 'true' ? 'KIOSK' : variables.orderType,
            metadata: checkoutMetaMap(user)
          }
        },
        withCredentials: true
      });
    },
    onSuccess: (
      { data }: AxiosData<UpdateOrderTypeData>,
      variables: UpdateOrderTypeProps
    ) => {
      if (data.errors?.length) {
        throw new Error(data.errors[0]?.message);
      } else {
        updateUserOrderTypeLocalStorage(variables.orderType);
        queryClient.setQueryData(['cart'], (oldCart: Curaql_Cart) =>
          oldCart
            ? {
                ...oldCart,
                orderType: data.data.updateOrderType.orderType
              }
            : oldCart
        );
      }
    },
    onError: (error: any) => {
      console.error('updateOrderType: ', error);
      const message = error.response.data.errors.length
        ? error.response.data.errors[0].message
        : error.toString();
      DatalayerAnalytics.pushErrorEvent({
        category: 'api',
        location: 'updateOrderType',
        description: message,
        consolidateDispensary: selectedDispensary || undefined
      });
      snackbarMessage(
        message && (message.includes('Failed') || message.includes('Sorry'))
          ? message
          : 'Something went wrong, please try again.'
      );
    }
  });

  const mergeCart = useMutation({
    mutationFn: () => {
      return axios({
        url: process.env.CURAQL_BASEURL,
        method: 'post',
        data: {
          query: Curaql.UPDATE_USER_CART,
          variables: {
            dispensaryUniqueId: selectedDispensaryID,
            orderType: process.env.IS_KIOSK === 'true' ? 'KIOSK' : 'PICKUP',
            pricingType: menuType,
            checkoutId
          }
        },
        withCredentials: true
      });
    },
    onSuccess: ({ data }: AxiosData<MergeCartData>) => {
      if (data.errors?.length) {
        throw new Error(data.errors[0]?.message);
      } else {
        queryClient.setQueryData(['cart'], (oldCart: Curaql_Cart) =>
          oldCart
            ? {
                ...oldCart,
                ...data.data.mergeCart
              }
            : null
        );
      }
    },
    onError: (error: any) => {
      if (error.response.data.errors) {
        console.error(
          '500 - mergeCart: ',
          error.response.data.errors[0].message
        );
        const message = error.response?.data?.errors?.length
          ? error.response.data.errors[0].message
          : error.toString();
        DatalayerAnalytics.pushErrorEvent({
          category: 'api',
          location: 'mergeCart',
          description: `500 - ${message}`,
          consolidateDispensary: selectedDispensary || undefined
        });
        clearUserCart.mutate();
      } else {
        const message = error.response?.data?.errors?.length
          ? error.response.data.errors[0].message
          : error.toString();
        DatalayerAnalytics.pushErrorEvent({
          category: 'api',
          location: 'mergeCart',
          description: `200 - ${message}`,
          consolidateDispensary: selectedDispensary || undefined
        });
        console.error('200 - mergeCart: ', error);
      }
    }
  });

  const clearUserCart = useMutation({
    mutationFn: () => {
      return axios({
        url: process.env.CURAQL_BASEURL,
        method: 'post',
        data: {
          query: Curaql.CLEAR_USER_CART
        },
        withCredentials: true
      });
    },
    onSuccess: ({ data }) => {
      if (data.errors?.length) {
        throw new Error(data.errors[0]?.message);
      }
    },
    onError: (error: Error | any) => {
      console.error('clearUserCart: ', error);
      const message = error.response.data.errors.length
        ? error.response.data.errors[0].message
        : error.toString();
      DatalayerAnalytics.pushErrorEvent({
        category: 'api',
        location: 'clearUserCart',
        description: message,
        consolidateDispensary: selectedDispensary || undefined
      });
      snackbarMessage(
        message && (message.includes('Failed') || message.includes('Sorry'))
          ? message
          : 'Something went wrong, please try again.'
      );
    },
    onSettled: () => {
      clearAnonCart();
    }
  });

  const clearCart = async (cb?: () => void) => {
    if (isLoggedIn) {
      await clearUserCart.mutateAsync().finally(() => (cb ? cb() : null));
    } else {
      clearAnonCart(cb);
    }
  };

  const clearAnonCart = (cb?: () => void) => {
    Cookies.remove(Curaql.CART_META_KEY);
    cartMeta = undefined;
    checkoutId = undefined;
    dispensaryUniqueId = selectedDispensaryID;
    menuType = userMenuType;
    // set timeout because race condition for cartMeta
    setTimeout(() => {
      queryClient.resetQueries({
        queryKey: ['cart'],
        exact: true
      });
    }, 100);
    cb ? cb() : null;
  };

  const addItems = useMutation({
    mutationFn: (variables: AddToCartProps) => {
      return axios({
        url: process.env.CURAQL_BASEURL,
        method: 'post',
        data: {
          query: Curaql.ADD_ITEM_TO_CART,
          variables: {
            dispensaryUniqueId,
            orderType: process.env.IS_KIOSK === 'true' ? 'KIOSK' : 'PICKUP',
            pricingType: menuType,
            checkoutId,
            productId: variables.product.id,
            quantity: variables.quantity,
            option: variables.variant.option,
            baseCartUrl: variables.baseCartUrl,
            metadata: checkoutMetaMap(user, emails)
          }
        },
        withCredentials: true
      });
    },
    onSuccess: async (
      { data }: AxiosData<AddToCartData>,
      { analyticsContext, product, variant }: AddToCartProps
    ) => {
      if (data.errors?.length) {
        if (data.errors[0]?.message === 'Failed to add item to cart (2)') {
          Cookies.remove(Curaql.CART_META_KEY);
        } else {
          const error = data.errors[0];
          console.error('addItems: ', error);
          DatalayerAnalytics.pushErrorEvent({
            category: error.message.includes('purchase limit')
              ? 'expected'
              : 'api',
            location: 'addToCart',
            description: error.message,
            consolidateDispensary: selectedDispensary || undefined
          });
          const key = snackbarMessage(
            error?.message &&
              (error.message.includes('Failed') ||
                error.message.includes('Sorry'))
              ? error.message.replace('Error: ', '')
              : 'Something went wrong, please try again.',
            'error',
            {
              actionText: 'Clear cart?',
              callback: () => {
                clearCart(() =>
                  snackbarMessage('Successfully cleared cart!', 'success')
                );
                closeSnackbar(key);
                refetch();
                Cookies.remove(Curaql.CART_META_KEY);
              }
            }
          );
        }
      } else {
        const oldData = Cookies.get(Curaql.CART_META_KEY);
        const oldQuantity = oldData ? JSON.parse(oldData).itemQuantity : 0;
        let newQuantity = 0;
        data.data.addItemToCart.items.map((item) => {
          newQuantity += item.quantity;
        });
        Cookies.set(
          Curaql.CART_META_KEY,
          JSON.stringify({
            checkoutId: data.data.addItemToCart.id,
            dispensaryUniqueId,
            items: data.data.addItemToCart.items.length,
            itemQuantity: newQuantity,
            menuType,
            redirectUrl: data.data.addItemToCart.redirectUrl,
            updatedAt: data.data.addItemToCart.updatedAt
          })
        );

        queryClient.setQueryData(['cart'], (oldCart: Curaql_Cart) =>
          oldCart
            ? {
                ...oldCart,
                items: data.data.addItemToCart.items,
                quantity: newQuantity,
                priceSummary: data.data.addItemToCart.priceSummary,
                updatedAt: data.data.addItemToCart.updatedAt
              }
            : oldCart
        );
        addCartCompletion(
          data.data.addItemToCart,
          product,
          variant,
          newQuantity,
          setPopupProduct,
          setShowPopup
        );

        if (newQuantity > oldQuantity) {
          DatalayerAnalytics.pushAddToCart({
            product: product as Consolidate_Product,
            context: {
              quantity: newQuantity,
              item_variant: variant,
              item_list_id: analyticsContext.list.id,
              item_list_name: analyticsContext.list.name,
              index: analyticsContext.list.index
            },
            consolidateDispensary: selectedDispensary
          });
        } else {
          DatalayerAnalytics.pushRemoveFromCart({
            product: product as Consolidate_Product,
            context: {
              quantity: newQuantity,
              item_variant: variant,
              item_list_id: analyticsContext.list.id,
              item_list_name: analyticsContext.list.name,
              index: analyticsContext.list.index
            },
            consolidateDispensary: selectedDispensary
          });
        }
      }
    },
    onError: (error: any) => {
      console.error('addItems: ', error);

      const message = error.response?.data?.errors?.length
        ? error.response.data.errors[0].message
        : error.toString();
      DatalayerAnalytics.pushErrorEvent({
        category: message.includes('purchase limit') ? 'expected' : 'api',
        location: 'addToCart',
        description: message,
        consolidateDispensary: selectedDispensary || undefined
      });
      snackbarMessage(
        message && (message.includes('Failed') || message.includes('Sorry'))
          ? message.replace('Error: ', '')
          : 'Something went wrong, please try again.'
      );
    }
  });

  const addItem = (variables: AddToCartProps, cb: () => void) => {
    if (cart) {
      const matchingCartItem = cart.items.find(
        (item) => item.product.id === variables.product.id
      );
      if (
        matchingCartItem &&
        variables.variant.quantity &&
        variables.variant.quantity <
          matchingCartItem.quantity + variables.quantity
      ) {
        cb();
        return snackbarMessage('Added quantity exceeds product inventory.');
      }
    }
    return addItems.mutateAsync(variables).finally(() => cb());
  };

  useEffect(() => {
    const currentCartMeta = Cookies.get(Curaql.CART_META_KEY);
    if (!currentCartMeta) {
      checkoutId = '';
      cartMeta = currentCartMeta;
    }
  }, [isLoggedIn, user]);

  useEffect(() => {
    if (
      cart &&
      !selectedDispensaryLoading &&
      (cart.dispensaryUniqueId !== selectedDispensaryID ||
        asPath.includes('order-confirmation'))
    ) {
      clearUserCart.mutate();
    }
  }, [asPath, userMenuType, selectedDispensaryID]);

  return {
    cart: {
      data: cart,
      error,
      loading: isLoading && isFetching,
      refetch
    },
    checkoutId,
    addItemToCart: addItem,
    clearAnonCart,
    clearCart,
    mergeCart,
    removeItemFromCart: removeItem,
    updateOrderType: updateOrderType
  };
};
