import { capitalize } from 'services';
import { LoadingSkeleton } from '../Loader/Skeleton';

export const Loader = (): JSX.Element | null => {
  return (
    <>
      {['subtotal', 'taxes', 'total'].map((ps) => {
        const bold = ps === 'total' ? 'font-bold' : '';
        return (
          <div key={ps} className="margin-bottom-10">
            <div className={bold ? 'hairline' : ''} />
            <div className="d-flex justify-content-between margin-bottom-10">
              <span className={bold}>{capitalize(ps)}</span>
              <span>
                <LoadingSkeleton width="50px" />
              </span>
            </div>
          </div>
        );
      })}
    </>
  );
};
