import {
  CS_CuraleafPage_ImageBlock_CTAButton,
  CS_CuraleafPage_ImageBlock_CTALink
} from 'services';
import { ImageType, MP4Type } from '../../models/types';
import { ImageBlock as Default } from './default';

import './image-block.scss';

export type ImageBlockProps = {
  isMobile: boolean;
  anchor?: string;
  contain?: boolean;
  fullwidth?: boolean;
  image: ImageType | MP4Type;
  minWidth?: boolean;
  mobileImage?: ImageType | MP4Type | null;
  cta?:
    | CS_CuraleafPage_ImageBlock_CTALink
    | CS_CuraleafPage_ImageBlock_CTAButton
    | null;
};

export const ImageBlock = (props: ImageBlockProps): JSX.Element | null => {
  if (!props.image) return null;

  return <Default {...props} />;
};
