import { CTAButton, CTAButtonProps } from '../CTAButton';
import { PackageComponentWrapper } from '../PackageComponentWrapper';

import DefaultBGImage from '../../assets/svgs/shared/simple-leaves.svg';
import Close from '../../assets/svgs/close/close-teal.svg';

import './alert.scss';

export type AlertProps = {
  button: CTAButtonProps;
  close?: () => void;
  detail?: string;
  title: string;
};

export const Alert = ({
  button,
  close,
  detail,
  title
}: AlertProps): JSX.Element | null => {
  return (
    <PackageComponentWrapper>
      <div className="alert" data-test="alert-container">
        <div className="alert-container bg-white">
          <DefaultBGImage className="default-image" data-test="default-image" />
          <div>
            <button
              aria-label="Close alert popup"
              className="close-icon pointer"
              onClick={close || button.callBackFunc}>
              <Close />
            </button>
            <div className="text-container text-primary text-center">
              <h2 className="h3" data-test="alert-title">
                {title}
              </h2>
              {detail ? (
                <p className="text-primary" data-test="alert-detail">
                  {detail}
                </p>
              ) : null}
            </div>
            <div className="alert-buttons d-flex flex-column align-items-center padding-0">
              <CTAButton {...button} data-test="alert-button" />
            </div>
          </div>
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
