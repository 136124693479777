'use client';

import { useEffect, useState } from 'react';

import { useStorage } from '../../hooks/useStorage';

import CloseIcon from '../../assets/svgs/close/icon-close.svg';

import './cookie-acceptance-banner.scss';

export const CookieAcceptanceBanner = (): JSX.Element | null => {
  const [confirmedCookie, setConfirmedCookie] = useStorage<'0' | '1'>(
    'confirmedCookies',
    '0'
  );
  const [show, setShow] = useState(false);

  useEffect(() => {
    // use storage is delayed a frame so have to useEffect here
    if (confirmedCookie === '0' && !show) {
      setShow(true);
    }
  }, [confirmedCookie]);

  if (show) {
    return (
      <div
        id="cookie-banner"
        className="cookie-banner-wrapper d-flex justify-content-center">
        <div className="cookie-banner d-flex align-items-center text-center justify-content-center">
          <div className="text-wrapper">
            <span className="body-l">
              We use cookies to improve your browsing experience and provide
              personalized content. By continuing to use this website, you agree
              to our use of cookies. For more information, please review our{' '}
              <a className="body-l font-bold underline" href="/privacy-policy">
                Privacy Policy
              </a>
              .
            </span>
          </div>
          <button
            onClick={() => {
              setConfirmedCookie('1');
              setShow(false);
            }}
            className="close-cookie-banner pointer">
            <CloseIcon />
          </button>
        </div>
      </div>
    );
  }

  return null;
};
