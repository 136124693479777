import {
  CategoryLink,
  GetCategories
} from '@cura/next-server/GetCategoriesServerAction';
import { Dispatch, SetStateAction } from 'react';
import {
  DatalayerAnalytics,
  DutchiePlus_PricingType,
  OverviewPageDispensary,
  SiteWideDispensary
} from 'services';

export const fetchDispoCategories = async (
  selectedDispensary: SiteWideDispensary | OverviewPageDispensary,
  menuType: DutchiePlus_PricingType,
  isPreview: boolean,
  setLoading?: Dispatch<SetStateAction<boolean>>,
  setCategories?: Dispatch<
    SetStateAction<
      | {
          categories: CategoryLink[];
          dispensaryId: string;
        }
      | undefined
    >
  >
) => {
  const categories = await GetCategories(
    selectedDispensary.uid,
    menuType,
    isPreview
  )
    .then((categories) => {
      if (setCategories) {
        setCategories({
          categories: categories ? categories : [],
          dispensaryId: selectedDispensary.uid
        });
      }
      return categories;
    })
    .catch((error) => {
      if (setCategories) {
        setCategories(undefined);
        DatalayerAnalytics.pushErrorEvent({
          category: 'api',
          location: 'getCategories',
          description:
            (error as string) || 'Get categories api error in sitewide context',
          consolidateDispensary: selectedDispensary as SiteWideDispensary
        });
      }
      return undefined;
    })
    .finally(() => setLoading && setLoading(false));

  return categories || undefined;
};
