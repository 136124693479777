import classNames from 'classnames';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

import { CartButton } from '../../ProductDescription/CartButton';
import { FilterAccordion } from './FilterAccordion';
import { ProductFilterProps } from './index';
import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { SEARCH_QUERY_KEYS } from '../../../utils/constants';

import CloseIcon from '../../../assets/svgs/close/close-teal.svg';

export const FilterAccordionWrapper = ({
  filters,
  onSelect,
  pageType,
  productLength,
  shopLink,
  sortBy,
  onClose,
  searchParams
}: ProductFilterProps) => {
  const { push, refresh } = useRouter();
  const { width } = useSiteWideContext();
  const pathname = usePathname();

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (searchParams) {
      let counter = 0;
      SEARCH_QUERY_KEYS.map((qk) => {
        const key = searchParams[qk];
        if (key && typeof key === 'string') {
          const value = qk === 'p' ? 1 : key.split(',').length;
          counter += value;
        }
      });
      setCount(counter);
    }
  }, [searchParams]);

  const isTablet = width && width < 1024;

  const filtersWithSort = isTablet
    ? {
        Sort: [],
        ...filters
      }
    : filters;

  return (
    <PackageComponentWrapper minWidth={false}>
      <div
        className={classNames('product-filter bg-white', {
          'd-flex flex-column': isTablet
        })}
        data-test="filter-accordion-wrapper">
        {isTablet ? (
          <div className="d-flex padding-top-20 mobile-accordion-header align-items-center">
            <h5 className="tbody16-bold text-center text-dark-grey margin-0">
              Sort + Filter {count ? `(${count})` : null}
            </h5>
            <button
              aria-label="Close filter drawer"
              onClick={onClose}
              className="filter-close-icon pointer">
              <CloseIcon />
            </button>
          </div>
        ) : null}
        {Object.keys(filtersWithSort).map((menu) => {
          const key = menu as keyof typeof filters;
          if (key && filtersWithSort[key]) {
            return (
              <FilterAccordion
                key={key}
                props={{
                  isLoading: false,
                  filters: filtersWithSort,
                  onSelect,
                  header: key,
                  pageType,
                  shopLink,
                  sortBy
                }}
              />
            );
          }
        })}
      </div>
      {isTablet && onClose ? (
        <div className="results-button d-flex flex-column bg-white-grey padding-20">
          <CartButton
            text={
              productLength && count
                ? `Show ${productLength} Results`
                : 'Show Results'
            }
            callBackFunc={onClose}
            variant="secondary"
          />
          <CartButton
            text="Clear All"
            callBackFunc={() => {
              push(pathname);
              refresh();
            }}
            variant="primary"
          />
        </div>
      ) : null}
    </PackageComponentWrapper>
  );
};

export const ProductFilter = ({
  filters,
  onClose,
  onSelect,
  pageType,
  shopLink,
  searchParams,
  sortBy
}: ProductFilterProps): JSX.Element | null => {
  if (!filters) return null;
  return (
    <div data-test="filter-dt" className="filter-wrapper">
      <FilterAccordionWrapper
        isLoading={false}
        filters={filters}
        pageType={pageType}
        onSelect={onSelect}
        shopLink={shopLink}
        onClose={onClose}
        searchParams={searchParams}
        sortBy={sortBy}
      />
    </div>
  );
};
