import classNames from 'classnames';

import { CTAButton, CTAButtonProps } from '../CTAButton';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { Markdown } from '../Markdown';
import { ImageBlock } from '../ImageBlock';
import { ImageType, MP4Type } from '../../models/types';

import './text-image-block.scss';

export type TextImageBlockProps = {
  anchor?: string;
  ctabutton?: CTAButtonProps | null;
  content: string;
  image: ImageType | MP4Type;
  isMobile: boolean;
  layout: 'left' | 'right';
};

export const TextImageBlock = ({
  anchor = 'text-image-block',
  content,
  ctabutton,
  image,
  isMobile = false,
  layout
}: TextImageBlockProps): JSX.Element | null => {
  if (!image || !layout) return null;

  return (
    <PackageComponentWrapper additionalClass="m-container">
      <section
        id={anchor}
        className="text-img-block"
        data-test="text-img-block-container">
        <div
          data-test={`text-img-block-${layout}`}
          className={classNames('column d-flex mt-0 px-6', {
            reverse: layout === 'left',
            'tib-row': layout !== 'left'
          })}>
          <div className={`text-col align block-align-left col-12 col-md-5`}>
            <div>
              {content && (
                <section data-test="text">
                  <Markdown content={content} />
                </section>
              )}
            </div>
            {ctabutton ? (
              <div className="tib-cta-button-container d-flex justify-content-between">
                <CTAButton {...ctabutton} data-test="cta-button" />
              </div>
            ) : null}
          </div>
          <div className="col-12 col-md-7 align" data-test="img">
            <ImageBlock
              isMobile={isMobile}
              image={image}
              fullwidth={true}
              minWidth={false}
            />
          </div>
        </div>
      </section>
    </PackageComponentWrapper>
  );
};
