'use client';

import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import { Loader } from '../../Account/Loader/loader';
import { StoreCard } from '../../StoreCard';

import { ErrorBoundary } from '../../../utils/nullErrorBoundary';
import { StoreCardDispensary } from 'services';

import './store-list.scss';

export type StoreListProps = {
  anchor?: string;
  loading?: boolean;
  stores: StoreCardDispensary[];
};

export const StoreList = ({
  anchor = 'storelist',
  loading = false,
  stores
}: StoreListProps): JSX.Element | null => {
  const hasMultipleStores = stores.length >= 4;
  const hasSplitHours =
    stores.some((store) => store.todaysHours?.includes(', ')) ?? false;

  return (
    <PackageComponentWrapper additionalClass="m-container">
      <ErrorBoundary component="store-list">
        <div
          data-loading={loading}
          data-multiple-stores={hasMultipleStores}
          data-split-hours={hasSplitHours}
          className="store-list"
          id={anchor}
          data-test="flat-list">
          {loading ? (
            <Loader />
          ) : (
            stores.map((store, i) => (
              <StoreCard
                key={`${store.friendlyName}-${i}`}
                dispensary={store}
                type="location"
              />
            ))
          )}
        </div>
      </ErrorBoundary>
    </PackageComponentWrapper>
  );
};
