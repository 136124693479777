import { FormikProps } from 'formik';

import { DefaultFormik } from '../../../../utils/forms';
import { DateInput } from './input';
import { PackageComponentWrapper } from '../../../PackageComponentWrapper';

import '../form.scss';

export type DateInputProps = {
  field: string;
  dateType?: 'month' | 'date';
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  required?: boolean;
  title?: string;
};

export const DateField = (props: DateInputProps): JSX.Element | null => {
  const { required = false, disabled = false } = props;
  if (!props.field || !props.field.length) return null;
  if (!props.formik) {
    props.formik = DefaultFormik();
  }

  if (typeof props.field !== 'string') return null;

  return (
    <PackageComponentWrapper minWidth={false}>
      <DateInput {...props} disabled={disabled} required={required} />
    </PackageComponentWrapper>
  );
};
