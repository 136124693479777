import { Loader } from './loader';
import { FilterAccordion as Default } from './default';
import { EcomProductPageType } from '../../../../../models/productPage';
import { SortBy } from '../../../../../models/types';
import { ErrorBoundary } from '../../../../../utils/nullErrorBoundary';
import { FilterKeys, FilterMenuItem, FilterMenus } from '..';

import './filter-accordion.scss';

export type FilterAccordionProps = {
  defaultOpen?: boolean;
  filters: FilterMenus;
  header: FilterKeys;
  isLoading: false;
  onSelect: (header: FilterKeys, filter: FilterMenuItem, sort?: SortBy) => void;
  pageType: EcomProductPageType['type'];
  shopLink: string;
  sortBy: SortBy;
};

export type LoadingProps = {
  isLoading: true;
};

export const FilterAccordion = ({
  props
}: {
  props: FilterAccordionProps | LoadingProps;
}): JSX.Element | null =>
  props.isLoading ? (
    <Loader />
  ) : (
    <ErrorBoundary component="filter-accordion">
      <Default {...props} />
    </ErrorBoundary>
  );
