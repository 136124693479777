import { useState } from 'react';
import { Slider } from 'primereact/slider';

import { FilterAccordionProps } from '../index';
import { FilterKeys, FilterMenuItem } from '../../index';

export const FilterSliderItem = ({
  header,
  item,
  onSelect
}: {
  header: FilterKeys;
  item: FilterMenuItem;
  onSelect: FilterAccordionProps['onSelect'];
}) => {
  const [current, setCurrent] = useState<[number, number]>(
    item.values?.current || [0, 0]
  );
  if (!item.values) return null;
  const [labelMin, labelMax] = current;
  return (
    <div className="ecom-slider-container">
      <div className="ecom-slider-title">
        <label id={item.label} className="ecom-slider-label body-m font-bold">
          {item.label}
        </label>
        <span className="body-m">
          : {labelMin}
          {item.unit} - {labelMax}
          {item.unit}
        </span>
      </div>
      <Slider
        aria-labelledby={item.label}
        tabIndex={0}
        min={item.values.min}
        max={item.values.max}
        step={item.label === 'CBD' ? 0.01 : 1}
        value={current}
        className="ecom-slider"
        onChange={(e) => {
          if (e?.value && Array.isArray(e.value) && e.value.length === 2) {
            // sort because the max can become the min
            const current = e.value.sort((a, b) => a - b) as [number, number];
            setCurrent(current);
          }
        }}
        onSlideEnd={(e) => {
          const isMinSlider =
            (e.originalEvent.target as any).getAttribute('class') ===
            'd-flex overflow-hidden';
          if (e.value === undefined && isMinSlider && item.values) {
            // when the mouse on the min slider goes outside of the component region to the left, the value turns undefined instead of 0.
            e.value = [item.values.min, item.values.current[1]];
          }
          if (e?.value && Array.isArray(e.value) && e.value.length === 2) {
            const endValue = e.value.sort((a, b) => a - b) as [number, number];
            if (item.values) {
              const menuItem: FilterMenuItem = {
                ...item,
                isSelected: true,
                values: { ...item.values, current: endValue }
              };
              onSelect(header, menuItem);
            }
          }
        }}
        range
      />
    </div>
  );
};
