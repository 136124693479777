import { Loader } from './loader';
import { BrandDescription as Default } from './default';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';
import { Consolidate_Product } from 'services';

import './brand-description.scss';

export type BrandDescriptionProps = {
  isLoading: false;
  productBrand?: Consolidate_Product['brand'];
};

export type LoadingProps = {
  isLoading: true;
  showMoreAbout?: boolean;
};

export const BrandDescription = ({
  props
}: {
  props: BrandDescriptionProps | LoadingProps;
}): JSX.Element | null =>
  props.isLoading ? (
    <Loader {...props} />
  ) : (
    <ErrorBoundary component="brand-description">
      <Default {...props} />
    </ErrorBoundary>
  );
