import { Tooltip } from 'primereact/tooltip';
import InformationIcon from '../../assets/svgs/shared/icon-info.svg';

import { SummaryBlockProps } from '.';

export const SummaryBlock = ({
  info
}: SummaryBlockProps): JSX.Element | null => {
  if (!info) return null;

  const valueFunc = (line: any, bold: string) => {
    switch (line.valueOptions?.type) {
      case 'discount':
        return <span className={`${bold}text-success`}>-${line.value}</span>;
      case 'chip':
        return (
          <span className="points-tag body-sm text-primary bg-success-light subtitle padding-inline-10 d-flex align-items-center">
            {line.value}
          </span>
        );
      default:
        return <span className={bold}>${line.value}</span>;
    }
  };

  return (
    <>
      {info.map((lineItem, i) => {
        const bold = lineItem.lineOptions?.font == 'bold' ? 'font-bold' : '';
        const name = lineItem.keyName;
        const indent = lineItem.lineOptions?.indent ? 'indent-line' : '';

        return lineItem.lineOptions?.skip ? null : (
          <div key={i} className="margin-bottom-10">
            <div
              className={lineItem.lineOptions?.separatorAbove ? 'hairline' : ''}
            />
            <div
              className={`d-flex justify-content-between text-black margin-bottom-10 ${indent}`}>
              <span className={bold}>
                {name}{' '}
                {lineItem.keyOptions?.tooltip ? (
                  <span data-test="tooltip">
                    <Tooltip target=".tooltip-info" position="bottom" />
                    <span
                      className="tooltip-info margin-inline-5 pointer"
                      data-pr-tooltip={lineItem.keyOptions.tooltip}>
                      <InformationIcon data-test="information-icon" />
                    </span>
                  </span>
                ) : null}
              </span>
              {valueFunc(lineItem, bold)}
            </div>
          </div>
        );
      })}
    </>
  );
};
