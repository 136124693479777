'use client';

import Link from 'next/link';

import { ecommerceSanitizeQuery } from '../../utils/url';
import { BreadcrumbProps } from './index';
import { PackageComponentWrapper } from '../PackageComponentWrapper';

import './breadcrumbs.scss';

export const BreadCrumbs = ({
  breadcrumb,
  currentPage
}: BreadcrumbProps): JSX.Element | null => {
  const link = {
    container: 'link-container d-flex align-items-center',
    text: `breadcrumb-name margin-0 body-l`
  };

  return (
    <PackageComponentWrapper>
      <nav
        aria-label="breadcrumb"
        role="navigation"
        data-test="breadcrumb-container"
        className="Breadcrumbs">
        <ul className="d-flex align-items-center margin-0">
          {breadcrumb.map((element) => {
            return element.urlFragment && element.urlFragment.length ? (
              <li
                className={link.container}
                data-test="link-container"
                key={element.id}>
                <Link
                  href={ecommerceSanitizeQuery(element.urlFragment)}
                  className={`${link.text} font-bold text-black pointer underline`}
                  data-test="breadcrumb-name">
                  {element.breadcrumbName}
                </Link>
                <span className="slash body-l text-secondary-dark">/</span>
              </li>
            ) : (
              <li
                key={element.id}
                className={link.container}
                data-test="link-container">
                <p
                  className={`${link.text} font-bold text-black margin-0`}
                  data-test="breadcrumb-name">
                  {element.breadcrumbName}
                </p>
                <span className="slash body-l text-secondary-dark">/</span>
              </li>
            );
          })}
          {currentPage ? (
            <li className={link.container}>
              <p className={`${link.text} text-black margin-0`}>
                {currentPage}
              </p>
            </li>
          ) : null}
        </ul>
      </nav>
    </PackageComponentWrapper>
  );
};
