import { UserPopup, UserPopupType } from './user';
import { useSiteWideContext } from '../../../hooks/siteWideContext';

import CloseIcon from '../../../assets/svgs/close/close-teal.svg';

export const HeaderPopup = ({ options, type }: UserPopupType) => {
  const { isMobile, selectedDispensary } = useSiteWideContext();

  if ((!isMobile && !options.showTooltip) || !type) return null;

  const handleClose = () => {
    options.closeNav();
  };

  return (
    <div data-visible={options.showTooltip} className={`header-${type}-popup`}>
      <div
        role="region"
        aria-live="polite"
        data-no-cart-icon={!selectedDispensary}
        data-visible={options.showTooltip}
        className="popup-container d-flex flex-column bg-white text-center">
        {!isMobile ? (
          <div
            className="tail-container"
            aria-hidden="true"
            role="presentation">
            <div className="popup-tail bg-white" />
          </div>
        ) : (
          <button
            tabIndex={0}
            className="close-icon d-flex justify-content-end"
            onClick={handleClose}
            onKeyDown={(event) => {
              if (event.key === 'Enter') handleClose();
            }}>
            <CloseIcon
              aria-controls="primary-navigation"
              aria-expanded={options.showTooltip}
            />
          </button>
        )}
        <UserPopup options={{ ...options, closeNav: options.closeNav }} />
      </div>
    </div>
  );
};
