import { Loader } from './loader';
import { BreadCrumbs as Default } from './default';

export type BreadcrumbType = {
  breadcrumbName: string;
  urlFragment: string;
  id: number;
  params?: [string, string][];
};

export type BreadcrumbProps = {
  breadcrumb: BreadcrumbType[];
  currentPage?: string;
  isLoading: false;
};

export type LoadingProps = {
  isLoading: true;
};

export const BreadCrumbs = ({
  props
}: {
  props: BreadcrumbProps | LoadingProps;
}): JSX.Element | null => {
  if (props.isLoading) {
    return <Loader />;
  } else {
    return <Default {...props} />;
  }
};
