import { SnackbarMessage, enqueueSnackbar } from 'notistack';

export const snackbarMessage = (
  err: SnackbarMessage,
  type?: 'error' | 'success',
  action?: {
    actionText: string;
    callback: () => void;
  }
) =>
  enqueueSnackbar(err, {
    preventDuplicate: true,
    anchorOrigin: {
      horizontal: 'left',
      vertical: 'bottom'
    },
    variant: type || 'error',
    autoHideDuration: 5000,
    action: () => {
      return action ? (
        <button
          className="cart-button body-m btn primary pointer"
          style={{ height: '30px' }}
          onClick={action?.callback}>
          {action?.actionText}
        </button>
      ) : null;
    }
  });
