import { PackageComponentWrapper } from '../../PackageComponentWrapper';

import LeafIcon from '../../../assets/svgs/article/icon-author-leaf.svg';
import ClockIcon from '../../../assets/svgs/article/icon-clock.svg';

import './article-header.scss';

export type ArticleHeaderProps = {
  author: string;
  heading: string;
  readinglength?: string | number;
};

export const ArticleHeader = ({
  author,
  heading,
  readinglength
}: ArticleHeaderProps): JSX.Element | null => {
  if (!heading || !author || !readinglength) return null;
  return (
    <PackageComponentWrapper>
      <section className="article-header">
        <h1 className="heading" data-test="article-heading">
          {heading}
        </h1>
        <div className="author-reading-time">
          <div className="author">
            <div className="icon">
              <LeafIcon />
            </div>
            <small className="item" data-test="article-author">
              {author}
            </small>
          </div>
          <div className="reading-time">
            <div className="icon">
              <ClockIcon />
            </div>
            <small className="item" data-test="article-readinglength">
              {readinglength} min read
            </small>
          </div>
        </div>
      </section>
    </PackageComponentWrapper>
  );
};
