import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Tooltip } from 'primereact/tooltip';
import classNames from 'classnames';

import { CarouselDirection, StorefrontCarouselProps } from './index';
import { CarouselTile } from './components/tile';
import { CarouselArrow } from './components/arrow';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { useDispensaryPathContext } from '../../hooks/dispensaryPathContextProvider';

import InformationIcon from '../../assets/svgs/shared/icon-info.svg';

export const StorefrontCarousel = ({
  banners,
  helpText,
  link,
  title,
  type
}: StorefrontCarouselProps): JSX.Element | null => {
  const sliderRef = useRef<HTMLDivElement | null>(null);

  const { selectedDispensary, isMobile } = useSiteWideContext();

  const [activeIndex, setIndex] = useState(0);
  const [slideWidth, setWidth] = useState(0);
  const [visibleItems, setVisibleItems] = useState(0);

  const { currentPathDispensary } = useDispensaryPathContext();

  const shopLink =
    currentPathDispensary &&
    typeof currentPathDispensary.shopLink === 'string' &&
    typeof currentPathDispensary.shopLink.length
      ? currentPathDispensary.shopLink
      : selectedDispensary?.shopLink;

  // Check if the slider shows the last slide
  const lastSlide = useMemo(
    () =>
      sliderRef.current
        ? sliderRef.current.scrollWidth -
            sliderRef.current.scrollLeft -
            sliderRef.current.clientWidth <=
          0
        : false,
    [activeIndex, slideWidth]
  );

  // Handles click events to scroll the slider
  const handleClick = useCallback(
    (direction: CarouselDirection) => {
      if (!sliderRef.current) return;

      const increment = direction === 'next' ? 1 : -1;
      let scrollDistance = Math.abs(slideWidth) * visibleItems;
      if (type === 'products') {
        scrollDistance = Math.abs(slideWidth) * (visibleItems - 1);
      }

      sliderRef.current.scrollTo({
        left: sliderRef.current.scrollLeft + increment * scrollDistance,
        behavior: 'smooth'
      });
    },
    [slideWidth, visibleItems]
  );

  useEffect(() => {
    updateCarouselLayout();
    window.addEventListener('resize', updateCarouselLayout);
    return () => window.removeEventListener('resize', updateCarouselLayout);
  }, [sliderRef.current, type]);

  const updateCarouselLayout = () => {
    if (sliderRef.current) {
      // Getting slide width and number of visible items from stylesheet
      const styles = window.getComputedStyle(sliderRef.current);
      const gap = parseInt(styles.gap || styles.getPropertyValue('gap'));
      const carouselItem = document.querySelector(`.item[data-type=${type}]`);
      if (carouselItem) {
        const itemStyles = window.getComputedStyle(carouselItem);
        const width = parseInt(
          itemStyles.width || itemStyles.getPropertyValue('width')
        );
        const numberOfItems = parseInt(
          itemStyles.getPropertyValue('--number-of-items')
        );
        setVisibleItems(numberOfItems);
        setWidth(width + gap);
      }
    }
  };

  const showArrows = !isMobile && process.env.IS_KIOSK !== 'true';

  return (
    <>
      {shopLink?.length ? (
        <PackageComponentWrapper
          minWidth={type !== 'subcategories'}
          additionalClass={type !== 'subcategories' ? 'container-lr' : ''}>
          <div className="storefront-carousel d-flex flex-column gap-24">
            {title || showArrows ? (
              <div className="header d-flex justify-content-between align-items-center">
                <div>
                  {title && link ? (
                    <a href={link} className="underline">
                      <h2 className="title title-l margin-0">
                        {title}
                        {helpText ? (
                          <span data-test="tooltip">
                            <Tooltip target=".tooltip-info" position="right" />
                            <span
                              className="tooltip-info margin-inline-5 pointer"
                              data-pr-tooltip={helpText}>
                              <InformationIcon data-test="information-icon" />
                            </span>
                          </span>
                        ) : null}
                      </h2>
                    </a>
                  ) : title ? (
                    <h2 className="title title-l text-primary margin-0">
                      {title}
                      {helpText ? (
                        <span data-test="tooltip">
                          <Tooltip target=".tooltip-info" position="right" />
                          <span
                            className="tooltip-info margin-inline-5 pointer"
                            data-pr-tooltip={helpText}>
                            <InformationIcon data-test="information-icon" />
                          </span>
                        </span>
                      ) : null}
                    </h2>
                  ) : null}
                </div>
                <div className="d-flex arrows gap-16">
                  {activeIndex > 60 && showArrows
                    ? CarouselArrow('previous', handleClick)
                    : null}
                  {!lastSlide && showArrows
                    ? CarouselArrow('next', handleClick)
                    : null}
                </div>
              </div>
            ) : null}
            <div
              ref={sliderRef}
              onScroll={(event) => setIndex(event.currentTarget.scrollLeft)}
              className={classNames('slider d-flex', {
                'gap-24': type !== 'subcategories',
                'gap-12': type === 'subcategories'
              })}>
              {banners.map((item, id) => (
                <div key={id} data-type={type} className="item">
                  {CarouselTile(
                    type,
                    { ...item, containingCarouselName: title },
                    false,
                    isMobile,
                    id,
                    selectedDispensary
                  )}
                </div>
              ))}
            </div>
          </div>
        </PackageComponentWrapper>
      ) : null}
    </>
  );
};
