import { useEffect, useState } from 'react';
import Image from 'next/image';

import { CTAButton } from '../../CTAButton';
import { SummaryCardHeader } from '../SummaryCardHeader';

import ProductDefaultImage from '../../../assets/images/product-placeholder.png';

import { Curaql_TransactionRecord } from 'services';

import './order-history-summary-card.scss';

export const OrderHistorySummaryCard = ({
  orderSummary,
  transactionItemQuantity,
  viewOrderFunc
}: {
  orderSummary: Curaql_TransactionRecord;
  transactionItemQuantity: number;
  viewOrderFunc: (transaction: Curaql_TransactionRecord) => void;
}) => {
  const [imageContainerCapacity, setImageContainerCapacity] = useState(0);

  useEffect(() => {
    const getImageContainerWidth = () => {
      const element = document.getElementsByClassName(
        'order-summary-image-container'
      )[0];
      const rect = element?.getBoundingClientRect();
      if (rect) {
        setImageContainerCapacity(Math.trunc(rect.width / 130) - 1);
      }
    };
    getImageContainerWidth();
    window.addEventListener('resize', getImageContainerWidth);
    return () => window.removeEventListener('resize', getImageContainerWidth);
  }, []);

  const orderImages = () => {
    return orderSummary.items?.map((item: any, i: number) => {
      if (i > imageContainerCapacity) {
        transactionItemQuantity += +item.quantity;
        return null;
      }
      return (
        <div className="image-container" key={i}>
          <Image
            src={item.imageUrl ? item.imageUrl : ProductDefaultImage}
            height={100}
            width={100}
            alt="Product image"
          />
          {+item.quantity > 1 ? (
            <div className="quantity-tag bg-primary padding-top-5 text-white text-center body-sm">
              {Math.floor(+item.quantity)}
            </div>
          ) : null}
        </div>
      );
    });
  };

  return (
    <div className="order-history-card d-flex flex-column bg-white padding-32">
      <div className="information-summary-container d-flex">
        <SummaryCardHeader
          transactionDateTime={orderSummary.transactionItemDateTime!}
          items={orderSummary.items as any} // todo make happy
          totalPrice={orderSummary.finalTotal!}
          loyalty={orderSummary.loyaltyPts}
        />
        <div className="button-container margin-bottom-10">
          <CTAButton
            text="View Order Details"
            callBackFunc={() => {
              viewOrderFunc(orderSummary);
            }}
            type="button"
            variant="tertiary"
          />
        </div>
      </div>
      <div className="order-details">
        <p className="body-m margin-0">{orderSummary.location}</p>
        <div className="order-summary-image-container d-flex justify-content-between">
          <div className="order-summary-images d-flex">{orderImages()}</div>
          {transactionItemQuantity ? (
            <div className="item-image-overflow body-l">{`+${transactionItemQuantity}${
              imageContainerCapacity > 4 ? ' Items' : ''
            }`}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
