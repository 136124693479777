import Link from 'next/link';

import { ErrorBoundary } from '../../../utils/nullErrorBoundary';

import IndicaIcon from '../../../assets/svgs/product-details/icon-product-highlights-indica.svg';
import SativaIcon from '../../../assets/svgs/product-details/icon-product-highlights-sativa.svg';
import HybridIcon from '../../../assets/svgs/product-details/icon-product-highlights-hybrid.svg';
import TerpenesIcon from '../../../assets/svgs/product-details/icon-product-terpenes.svg';
import EfffectsIcon from '../../../assets/svgs/product-details/icon-product-effects.svg';

import './product-details-accordion.scss';

export type ProductDetailsAccordionProps = {
  content: {
    value: string;
    tooltip?: string;
  }[];
  variant: 'comma' | 'bullet';
  icon: 'effects' | 'terpenes' | 'sativa' | 'indica' | 'hybrid' | string;
};

const renderIcon = (icon: ProductDetailsAccordionProps['icon']) => {
  switch (icon) {
    case 'hybrid': {
      return <HybridIcon />;
    }
    case 'sativa': {
      return <SativaIcon />;
    }
    case 'indica': {
      return <IndicaIcon />;
    }
    case 'terpenes': {
      return <TerpenesIcon />;
    }
    case 'effects': {
      return <EfffectsIcon />;
    }
    default: {
      return null;
    }
  }
};

export const ProductDetailsAccordion = ({
  content,
  icon,
  variant
}: ProductDetailsAccordionProps) => {
  let contentCopy = [];
  if (content.length >= 3) {
    contentCopy = content.slice(0, 3);
  } else {
    contentCopy = content;
  }
  return (
    <ErrorBoundary component="product-details-accordion">
      <div
        className="product-details d-flex margin-bottom-16"
        data-test="product-details-accordion">
        <div className="margin-right-10">{renderIcon(icon)}</div>
        <ul className={`d-flex ${variant} flex-wrap`}>
          {contentCopy.map((c) => (
            <li key={c.value} data-test="content" className="body-m text-black">
              {c.value}
            </li>
          ))}
          {content.length > 3 ? (
            <li key="more" data-test="content" className="body-m text-black">
              <Link
                href="#specifications"
                className="text-decoration-none more-link body-m">
                More
              </Link>
            </li>
          ) : null}
        </ul>
      </div>
    </ErrorBoundary>
  );
};
