import './curaleaf-spinner.scss';

import LeafLogo from '../../../assets/svgs/loyalty/icon-loyalty-leaf-logo.svg';

export const CuraleafSpinner = () => {
  return (
    <div className="CuraleafSpinner">
      <svg
        className="spinner"
        width="75px"
        height="75px"
        viewBox="0 0 76 76"
        xmlns="http://www.w3.org/2000/svg">
        <circle
          className="path"
          fill="none"
          strokeLinecap="round"
          cx="38"
          cy="38"
          r="35"></circle>
      </svg>
      <LeafLogo />
    </div>
  );
};
