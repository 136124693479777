import { FormikProps } from 'formik';
import { useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { FloatLabel } from 'primereact/floatlabel';

import {
  DefaultFormik,
  getFormErrorMessage,
  isFormFieldValid
} from '../../../../utils/forms';

import '../form.scss';

export type MultiselectProps = {
  disabled?: boolean;
  field: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: FormikProps<any>;
  multiselect_options:
    | {
        label: string;
      }[]
    | {
        label: string;
        items: {
          label: string;
        }[];
      }[];
  required?: boolean;
  sort?: boolean;
  title: string;
  grouped?: boolean;
};

export const MultiselectAccount = ({
  disabled,
  field,
  formik,
  multiselect_options,
  required,
  sort = false,
  title,
  grouped = false
}: MultiselectProps) => {
  const [open, setOpen] = useState(false);

  if (!formik) {
    formik = DefaultFormik();
  }

  if (!multiselect_options || !multiselect_options.length) return null;

  if (sort) {
    multiselect_options.sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    });
  }

  return (
    <div className="account-multiselect-curaleaf margin-bottom-16 d-flex flex-column justify-content-end">
      {required ? (
        <span className="body-sm text-secondary-dark d-flex justify-content-end padding-bottom-3">
          Required
        </span>
      ) : null}
      <FloatLabel>
        <MultiSelect
          tabIndex={0}
          data-test="multiselect"
          className={
            isFormFieldValid(field, formik) && formik.submitCount > 0
              ? 'multiselect-error'
              : 'multiselect'
          }
          panelClassName="multiselect-panel-curaleaf"
          name={field}
          value={formik.values[field]}
          optionGroupLabel={grouped ? 'label' : undefined}
          optionGroupChildren={grouped ? 'items' : undefined}
          options={
            grouped
              ? multiselect_options
              : multiselect_options.map((option) => ({
                  label: option.label
                }))
          }
          scrollHeight={
            multiselect_options.length <= 4
              ? `${50 * multiselect_options.length + 25}px`
              : ' 225px'
          }
          inputId={field}
          optionValue="label"
          onChange={formik.handleChange}
          onShow={() => setOpen(true)}
          onHide={() => setOpen(false)}
          dropdownIcon={open ? 'pi pi-chevron-up' : 'pi pi-chevron-down'}
          disabled={disabled}
          showSelectAll={false}
        />
        <label htmlFor={field}>{title}</label>
      </FloatLabel>
      {formik.submitCount > 0 ? getFormErrorMessage(field, formik) : null}
    </div>
  );
};
