'use client';

import { useState } from 'react';
import Image from 'next/image';
import { isIOS, osVersion } from 'react-device-detect';

import { CS_StateAgeVerification } from 'services';
import { ImageType } from '../../models/types';

import { CTAButton } from '../CTAButton';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { Select } from '../Select';

import DefaultBGImage from '../../assets/svgs/shared/simple-leaves.svg';

import './age-verification.scss';

export type AgeVerificationProps = {
  backgroundImage?: ImageType | null;
  exitLinkText: string;
  header: string;
  stateConfig: CS_StateAgeVerification['state_config'];
  submit: () => void;
  titles: string[];
};

export const AgeVerification = ({
  backgroundImage,
  exitLinkText,
  header,
  stateConfig,
  submit,
  titles
}: AgeVerificationProps): JSX.Element | null => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedState, setSelectedState] = useState<
    CS_StateAgeVerification['state_config'][number] | null
  >(null);

  // Detects known problematic iOS versions
  const oldBrowser = (isIOS && parseFloat(osVersion) < 16) || false;

  const isStateSite =
    process.env.CONTENTSTACK_ENVIRONMENT.includes('ct') ||
    process.env.CONTENTSTACK_ENVIRONMENT.includes('nd');

  const legacyDropdown = () => {
    return (
      <>
        <div className="dropdown-container">
          <div
            className="dropdown p-dropdown p-component p-inputwrapper"
            data-p-disabled="false"
            data-p-focus="false"
            data-testid="dropdown"
            data-pc-name="dropdown"
            data-pc-section="root"
            onClick={() => {
              setOpenDropdown(!openDropdown);
            }}>
            <div
              className="p-hidden-accessible"
              data-pc-section="hiddenselectedmessage">
              <input
                id="state"
                aria-haspopup="listbox"
                tabIndex={0}
                data-pc-section="input"
                type="text"
              />
            </div>
            <div
              className="p-hidden-accessible p-dropdown-hidden-select"
              data-pc-section="hiddenselectedmessage">
              <select
                required={false}
                name="Please select your state"
                tabIndex={-1}
                aria-hidden="true"
                data-pc-section="select">
                <option value="" data-pc-section="option">
                  {selectedState?.display_name || 'State'}
                </option>
              </select>
            </div>
            <span
              className="p-dropdown-label p-inputtext p-placeholder"
              tabIndex={-1}
              data-pc-section="input">
              {selectedState?.display_name || 'State'}
            </span>
            <div
              className="p-dropdown-trigger"
              role="button"
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-label="State"
              data-pc-section="trigger">
              <span
                className={`p-dropdown-trigger-icon p-clickable pi pi-chevron-${openDropdown ? 'up' : 'down'}`}
                data-pr-overlay-visible="true"
                data-pc-section="dropdownicon"></span>
            </div>
          </div>
        </div>
        {openDropdown ? (
          <div className="dropdown-list-container">
            <div
              className="p-dropdown-panel p-component p-dropdown-panel p-overlay-hidden p-input-overlay p-shadow"
              data-pc-section="panel">
              {stateConfig.map((state) => {
                return (
                  <li
                    key={state.display_name}
                    onClick={() => {
                      setSelectedState(state);
                      setOpenDropdown(false);
                      const matchingState = stateConfig.filter(
                        (s) => s.display_name === state.display_name
                      );
                      if (matchingState[0]) {
                        setSelectedState(matchingState[0]);
                      }
                    }}>
                    {state.display_name}
                  </li>
                );
              })}
            </div>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <PackageComponentWrapper maxWidth={false}>
      <div className="age-verification">
        <div data-browser-version={oldBrowser} className="age-container">
          {backgroundImage ? (
            <Image
              src={backgroundImage.url}
              layout="fill"
              data-testid="age_background_image"
              width={backgroundImage.width}
              height={backgroundImage.height}
              alt={backgroundImage.alternativeText || ''}
              style={{
                objectPosition: 'center',
                objectFit: 'cover'
              }}
            />
          ) : (
            <DefaultBGImage />
          )}
          <div className="age-text text-center">
            {titles.map((title) => (
              <h2 key={title} className="age-title" data-testid="age_title">
                {title}
              </h2>
            ))}
          </div>
          <div className="age-buttons d-flex align-items-center flex-column">
            {stateConfig && stateConfig.length ? (
              <>
                {oldBrowser ? (
                  legacyDropdown()
                ) : (
                  <Select
                    id="state"
                    label={header.slice(0, header.length - 1)}
                    placeholder="State"
                    onSelect={(state: string) => {
                      const matchingState = stateConfig.filter(
                        (s) => s.display_name === state
                      );
                      if (matchingState[0]) {
                        setSelectedState(matchingState[0]);
                      }
                    }}
                    menuItems={stateConfig.map((state) => ({
                      label: state.display_name,
                      value: state.display_name
                    }))}
                    selected={selectedState ? selectedState.display_name : ''}
                  />
                )}
                {selectedState ? (
                  <div className="state-container text-center text-primary">
                    <p>{selectedState.header}</p>
                    <p>{selectedState.legal_subtext}</p>
                    {/* CTAButton for whatever reason had issues on windows... */}
                    <button
                      className="state-button bg-primary text-white margin-bottom-16"
                      data-testid="submit"
                      onClick={submit}>
                      {selectedState?.button_text}
                    </button>
                  </div>
                ) : null}
              </>
            ) : (
              <div className="state-container text-center text-primary margin-inline-30">
                {isStateSite ? <p>{header}</p> : null}
                <CTAButton
                  data-testid="confirm"
                  text="Confirm"
                  callBackFunc={submit}
                  variant="secondary"
                />
              </div>
            )}
            <a
              href={
                selectedState
                  ? selectedState.exit_url
                  : 'https://www.google.com'
              }
              className="under-age"
              data-testid="under_age">
              {exitLinkText}
            </a>
          </div>
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
