'use client';

import { useEffect } from 'react';
import { DatalayerAnalytics } from 'services';

export function Analytics({ event }: { event: string }) {
  const pathname = typeof window !== 'undefined' ? window.location.href : '';
  const eventParams: { [key: string]: string } = {};
  const isShopPage = pathname.includes('/shop');

  useEffect(() => {
    if (event === '404') {
      eventParams.path = pathname;
      // eventParams.referrer = ''; TODO
      const splitPath = pathname.split('/');
      if (isShopPage && pathname.includes('/products')) {
        eventParams.type = 'product';
      } else if (isShopPage && pathname.includes('/specials')) {
        eventParams.type = 'special';
      } else if (isShopPage && splitPath.length === 6) {
        eventParams.type = 'dispensary';
      } else {
        eventParams.type = 'page';
      }
    }
    if (typeof window !== 'undefined') {
      if (!window.dataLayer) {
        window.dataLayer = [];
      }
      DatalayerAnalytics.pushBasicEvent(event, eventParams);
    }
  }, []);

  return null;
}
