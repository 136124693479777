/***
 * when an image url is returned by strapi, and that image is stored with the strapi site rather than an external location
 * e.g url: '/upload/image/file.jpeg' when stored locally and
 *     url: 'https:/a.website/upload/file.jpeg' when stored externally
 * then we need to prefix the url with the strapi host
 * @param src
 */
export const imgPath = (src: string): string => {
  if (
    process.env.NEXT_PUBLIC_STRAPI_API_URL !== undefined &&
    src !== undefined &&
    !src.toString().startsWith('https://')
  ) {
    return `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${src}`;
  } else {
    return src;
  }
};
