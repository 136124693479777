import Carousel from '../../Carousel';
import { CarouselDots } from '../../Carousel/components';
import { useCarousel } from '../../../hooks/useCarousel';

export type StoreImageCarouselProps = {
  banners: {
    id: number;
    url: string;
  }[];
};

const StoreImageCarousel = ({
  banners
}: StoreImageCarouselProps): JSX.Element | null => {
  const {
    setSelectedItem,
    setClassName,
    selectedItem,
    activeImageIndex,
    setActiveImageIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  } = useCarousel(banners, true, 10);

  const states = {
    setSelectedItem,
    setClassName,
    selectedItem,
    activeImageIndex,
    setActiveImageIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  };

  if (!banners || !banners.length) return null;

  return (
    <div className="overview-image-carousel">
      <Carousel data={banners} states={states}>
        {banners.map((banner) => (
          <div
            key={banner.id}
            className="carousel-el"
            style={{
              transform: `translate3d(${-activeImageIndex * 100}%, 0, 0)`
            }}>
            {/* can't use next/image; banner does not contain width&height */}
            {/* eslint-disable-next-line  @next/next/no-img-element */}
            <img
              src={banner.url}
              alt="Dispensary Storefront"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center'
              }}
            />
          </div>
        ))}
      </Carousel>
      <div className="thumbs">
        {banners && banners.length > 1 && (
          <CarouselDots data={banners} states={states} dotName="sm-dot" />
        )}
      </div>
    </div>
  );
};

export default StoreImageCarousel;
