import './instruction-steps.scss';

export const InstructionSteps = ({
  steps
}: {
  steps: string[];
}): JSX.Element | null => {
  return (
    <ol className="instruction-steps margin-0">
      {steps.map((step, i) => (
        <li key={`steps-${i}`} className="d-flex text-black">
          <span>{step}</span>
        </li>
      ))}
    </ol>
  );
};
