'use client';

import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';

import {
  AccountMode,
  AuthStatus,
  AuthUser,
  DatalayerAnalytics
} from 'services';

import { UnauthorizedPage } from './unauthorized';
import { AccountNavigation } from '../../components/Account/Navigation';
import { AccountPreferencesForm } from '../../components/FormBuilder/account-preferences';
import { FormConfirmation } from '../../components/FormBuilder/components/Confirmation';
import { Loader } from '../../components/Account/Loader/loader';
import { PackageComponentWrapper } from '../../components/PackageComponentWrapper';
import { BreadCrumbs } from '../../components/Breadcrumbs';
import {
  AuthDrawer,
  AuthDrawerForm
} from '../../components/Account/Drawer/auth';

import { isStringValid, isPhoneValid } from '../../utils/validators';
import { validationErrorCheck } from '../../utils/forms';
import { userIntialize, userParams } from '../../utils/account';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { useUserData } from '../../hooks/graphs';

import './account-preferences.scss';

export type AccountPreferencesFormFields = {
  birthday?: string;
  brandPreference?: string[];
  categoryPreference?: string[];
  dispensaryPreferences?: string[] | { label: string }[];
  email: string;
  firstName: string;
  lastName: string;
  mainSubscriptionStatus?: boolean | 'unsubscribed' | 'subscribed' | 'opted_in';
  smsSubscriptionStatus?: boolean;
  phone: string;
  state: string;
  // TODO: DELETE AFTER TYPE UPDATE
  birthMonth?: string;
  birthDay?: string;
  birthYear?: string;
};

type AccountSettingsPageProps = {
  brandPreferences?: { label: string }[];
  categoryPreferences?: { label: string }[];
  confirmation: AuthDrawerForm['confirmation'];
  copy: {
    brand: string;
    product_categories: string;
  };
  disclaimer?: string;
  initValues?: AccountPreferencesFormFields;
  optInContent?: string;
  perferredTitle?: string;
  statesNdispensaries: {
    state: string;
    dispensaries: { name: string; id: string }[];
  }[];
};

const FORM_GA_TYPE = 'account_preferences';

export const AccountSettingsPage = ({
  brandPreferences,
  categoryPreferences,
  confirmation,
  copy,
  disclaimer,
  statesNdispensaries
}: AccountSettingsPageProps) => {
  const {
    isLoggedIn,
    loading: userLoading,
    setAccountDrawerMode,
    setAccountDrawerUserEmail,
    setUser,
    user,
    websiteUrl
  } = useSiteWideContext();

  useEffect(() => {
    DatalayerAnalytics.pushPageView({
      page_type: 'account'
    });
  }, []);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [initialPageLoad, setLoading] = useState(true);
  const [visible, setVisibility] = useState(false);
  const [error, setError] = useState<AuthStatus>({
    text: ''
  });
  const [initialValues, setInitialValues] =
    useState<AccountPreferencesFormFields>({
      birthday: '',
      brandPreference: [],
      categoryPreference: [],
      dispensaryPreferences: [],
      email: '',
      firstName: '',
      lastName: '',
      mainSubscriptionStatus: true,
      phone: '',
      smsSubscriptionStatus: true,
      state: ''
    });

  const { updateUser } = useUserData();

  useEffect(() => {
    if (userLoading !== initialPageLoad) {
      setLoading(userLoading);
    }
  }, [userLoading]);

  useEffect(() => {
    if (user && isLoggedIn) {
      const formData = userIntialize(statesNdispensaries, user);
      if (formData) setInitialValues(formData);
    }
  }, [isLoggedIn]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValues
    },
    validateOnChange: false,
    validate: async (data: AccountPreferencesFormFields) => {
      const ageLimit = moment().subtract(18, 'years');
      const errors = {
        firstName: !data.firstName
          ? 'Please enter your first name.'
          : !isStringValid(data.firstName, 2, 32)
            ? 'Invalid first name.'
            : '',
        lastName: !data.lastName
          ? 'Please enter your last name.'
          : !isStringValid(data.lastName, 2, 32)
            ? 'Invalid last name.'
            : '',
        phone: !data.phone
          ? 'Please enter a valid phone number.'
          : !(await isPhoneValid(data.phone, websiteUrl))
            ? 'Invalid phone number.'
            : '',
        birthday: !data.birthday
          ? 'Please enter your birthday.'
          : moment(data.birthday).isAfter(ageLimit)
            ? 'You must be of legal age to shop'
            : ''
      };
      return validationErrorCheck(errors);
    },
    onSubmit: async (values: AccountPreferencesFormFields) => {
      setLoading(true);
      DatalayerAnalytics.pushBasicEvent('form_submit', {
        action: 'edit_account',
        form_type: FORM_GA_TYPE
      });
      DatalayerAnalytics.pushAccountEvent({
        action: 'edit_account'
      });
      await updateUser(userParams(values, statesNdispensaries))
        .then(({ data }) => {
          const user = data.data.user;
          if (user) {
            setUser({
              ...(user as unknown as AuthUser),
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone
            });

            setInitialValues({
              ...values,
              smsSubscriptionStatus: user.smsSubscriptionStatus || false,
              mainSubscriptionStatus: user.mainSubscriptionStatus === 'opted_in'
            });
            setShowConfirmation(true);
            setVisibility(false);
          }
        })
        .catch(() => {
          setError({ text: 'Failed to save changes. Please try again later.' });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  const formatPhoneNumber = (number: string): string => {
    const cleaned = ('' + number).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, match[2], '-', match[3], '-', match[4]].join('');
    }
    return number;
  };

  const handleClick = (mode: AccountMode) => {
    setAccountDrawerMode(mode);
    setAccountDrawerUserEmail(formik.values.email);
    setVisibility(true);
  };

  if (!isLoggedIn && !initialPageLoad) {
    return <UnauthorizedPage />;
  }

  return (
    <>
      <BreadCrumbs
        props={{
          breadcrumb: [
            {
              breadcrumbName: 'Home',
              urlFragment: '/',
              id: 0
            }
          ],
          currentPage: 'Account',
          isLoading: false
        }}
      />
      <PackageComponentWrapper additionalClass="account-preferences-wrapper">
        <FormConfirmation
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          confirmation={confirmation}
        />
        <AccountNavigation currentPage="settings" />
        <div className="account-preferences">
          <h2 className="account-heading text-primary h3">Settings</h2>
          {initialPageLoad ? (
            <div className="personal-info-wrapper">
              <Loader />
            </div>
          ) : (
            <>
              <div className="personal-info-wrapper">
                <h5 className="text-black bb-secondary-primary margin-bottom-16 padding-bottom-16 body-xl">
                  Personal Information
                </h5>
                <div className="d-flex justify-content-between">
                  <div className="personal-info">
                    <p className="capitalize">
                      {initialValues.firstName} {initialValues.lastName}
                    </p>
                    <p>{initialValues.email}</p>
                    <p>{formatPhoneNumber(initialValues.phone)}</p>
                    <p>{initialValues.birthday}</p>
                  </div>
                  <div className="change-pw d-flex flex-column justify-content-between">
                    <a
                      className="text-right text-primary"
                      tabIndex={0}
                      onClick={() => handleClick('edit')}>
                      Edit
                    </a>
                    <a
                      tabIndex={0}
                      className="text-right text-primary"
                      onClick={() => handleClick('forgot')}>
                      Reset Password
                    </a>
                  </div>
                </div>
              </div>
              <div className="preferences-wrapper">
                <AccountPreferencesForm
                  brandPreferences={brandPreferences}
                  buttonText={userLoading ? 'Saving...' : 'Save Changes'}
                  categoryPreferences={categoryPreferences}
                  copy={copy}
                  disclaimer={disclaimer}
                  formik={formik}
                  loading={userLoading}
                  statesNdispensaries={statesNdispensaries}
                />
              </div>
            </>
          )}
        </div>
        <AuthDrawer
          form={{
            confirmation,
            error,
            formik,
            loading: userLoading
          }}
          setVisibility={setVisibility}
          visible={visible}
        />
      </PackageComponentWrapper>
    </>
  );
};
