'use client';

import {
  LinkedinShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton
} from 'react-share';

import { imgPath } from '../../../utils/imgPath';
import { PackageComponentWrapper } from '../../PackageComponentWrapper';

import IconFacebook from '../../../assets/svgs/social/facebook.svg';
import IconTwitter from '../../../assets/svgs/social/x.svg';
import IconLinkedin from '../../../assets/svgs/social/linkedin.svg';
import IconPinterest from '../../../assets/svgs/social/icon-pinterest.svg';

import './social-sharing.scss';

export type SocialSharingProps = {
  pageData: {
    title: string;
    thumbnail?: string;
  };
};

export const SocialSharing = ({
  pageData
}: SocialSharingProps): JSX.Element | null => {
  const pageUrl = typeof window !== 'undefined' ? window.location.href : '';

  if (!pageData || !pageData.title) return null;
  return (
    <PackageComponentWrapper>
      <div
        data-test="social-sharing-container"
        className="social-sharing d-flex justify-content-evenly align-items-center container">
        <FacebookShareButton url={pageUrl} className="button">
          <div className="icon">
            <IconFacebook />
            <span>Share</span>
          </div>
        </FacebookShareButton>
        <TwitterShareButton
          url={pageUrl}
          className="button"
          title={pageData.title}>
          <div className="icon">
            <IconTwitter />
            <span>Tweet</span>
          </div>
        </TwitterShareButton>
        <PinterestShareButton
          data-test="pinterest-share"
          url={pageUrl}
          className="button"
          description={pageData.title}
          media={imgPath(
            pageData.thumbnail
              ? pageData.thumbnail
              : 'https://images.contentstack.io/v3/assets/blt1244f6272732d4ea/blt20b475f24a3a7363/61e70bb31746542491f08b11/Curaleaf-Media-page-new.jpeg'
          )}>
          <div className="icon">
            <IconPinterest />
            <span>Pin</span>
          </div>
        </PinterestShareButton>
        <LinkedinShareButton
          url={pageUrl}
          className="button"
          title={pageData.title}
          source={pageUrl}>
          <div className="icon">
            <IconLinkedin />
            <span>Share</span>
          </div>
        </LinkedinShareButton>
      </div>
    </PackageComponentWrapper>
  );
};
