import classNames from 'classnames';

import PlusIcon from '../../assets/svgs/quantity-select/icon-quantity-plus.svg';
import MinusIcon from '../../assets/svgs/quantity-select/icon-quantity-minus.svg';

import './quantity-select.scss';

type Props = {
  max?: number | null;
  value: number;
  onDecrement: () => void;
  onIncrement: () => void;
};

export const QuantitySelect = ({
  max,
  value,
  onDecrement,
  onIncrement
}: Props) => {
  return (
    <div>
      <div className="quantity-select bg-white d-flex align-items-center justify-content-evenly">
        <input
          className="body-m text-black"
          type="number"
          id="inputQuantitySelector"
          aria-live="polite"
          data-bs-step="counter"
          name="quantity"
          title="quantity"
          value={value}
          readOnly
          min="1"
          max={max ?? undefined}
          step="1"
          aria-label="Quantity selector"
        />
        <button
          type="button"
          className={classNames('quantity-btn', {
            disabled: value <= 1
          })}
          aria-describedby="inputQuantitySelector"
          onClick={() => {
            if (value > 1) {
              onDecrement();
            }
          }}>
          <MinusIcon />
          <span className="visually-hidden">Step down</span>
        </button>
        <button
          type="button"
          className={classNames('quantity-btn', {
            disabled: max && value >= max
          })}
          aria-describedby="inputQuantitySelector"
          onClick={() => {
            if (max && value < max) {
              onIncrement();
            }
          }}>
          <PlusIcon />
          <span className="visually-hidden">Step up</span>
        </button>
      </div>
    </div>
  );
};
