'use client';

import { articleCardMap } from '../../utils/contentstackMap';

import { CS_Article_Component, CS_Article_Full } from 'services';
import { ImageBlock } from '../ImageBlock';
import { TextBlock } from '../TextBlock';
import { ArticleGrid } from '../ArticleGrid';
import { ArticleHeader } from './ArticleHeader';
import { useSiteWideContext } from '../../hooks/siteWideContext';

type ArticleBuilderProps = {
  article: CS_Article_Full;
  component: CS_Article_Component;
  id: number;
};

export const ArticleBuilder = ({
  article,
  component,
  id
}: ArticleBuilderProps) => {
  const { isMobile } = useSiteWideContext();
  try {
    switch (component.__typename) {
      case 'ArticlePageArticleHeader': {
        // case 'CommunityNewsItemPageArticleHeader': {
        return (
          <ArticleHeader
            author={article.author}
            heading={article.title}
            key={`${component.__typename}-${id}`}
            readinglength={component.article_header.reading_length}
          />
        );
      }
      case 'ArticlePageRelatedArticles': {
        return (
          <ArticleGrid
            heading={component.related_articles.heading}
            articles={component.related_articles.articlesConnection.edges.map(
              (article, i) => articleCardMap(article.node, i)
            )}
            key={`${component.__typename}-${id}`}
          />
        );
      }
      // case 'CommunityNewsItemPageImageBlock':
      case 'ArticlePageImageBlock': {
        return (
          <ImageBlock
            isMobile={isMobile}
            image={{
              alternativeText:
                component.image_block.imageConnection.edges[0].node
                  .description || '',
              height:
                component.image_block.imageConnection.edges[0].node.dimension
                  .height,
              url: component.image_block.imageConnection.edges[0].node.url,
              width:
                component.image_block.imageConnection.edges[0].node.dimension
                  .width
            }}
            fullwidth={component.image_block.full_width}
            key={`${component.__typename}-${id}`}
          />
        );
      }
      // case 'CommunityNewsItemPageTextBlock':
      case 'ArticlePageTextBlock': {
        return (
          <TextBlock
            align="left"
            content={[component.text_block.content]}
            key={`${component.__typename}-${id}`}
          />
        );
      }
      default: {
        console.warn('Component Error: Missing component');
        return null;
      }
    }
  } catch (err) {
    return null;
  }
};
