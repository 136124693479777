import { capitalize } from 'services';
import { CarouselDirection } from '../index';

import ArrowLeft from '../../../assets/svgs/arrows/shop-carousel-left-arrow.svg';
import ArrowRight from '../../../assets/svgs/arrows/shop-carousel-right-arrow.svg';

export type CategoryTileProps = {
  name: string;
  url: string;
};

export const CarouselArrow = (
  direction: CarouselDirection,
  handleClick: (direction: CarouselDirection) => void
) => (
  <button
    role="button"
    aria-label={`${capitalize(direction)} Slide`}
    onClick={() => handleClick(direction)}
    onKeyDown={(event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleClick(direction);
      }
    }}
    className={`${direction} pointer`}>
    {direction === 'previous' ? (
      <ArrowLeft aria-hidden="true" />
    ) : (
      <ArrowRight aria-hidden="true" />
    )}
  </button>
);
