import classNames from 'classnames';
import Image from 'next/image';

import { ImageType, MP4Type } from '../../../models/types';
import { CTAButtonProps, CTAButton } from '../../CTAButton';
import { isMP4 } from '../../../utils/validators';
import { imgPath } from '../../../utils/imgPath';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';
import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import { Markdown } from '../../Markdown';

import './hero-banner.scss';

export type HeroBannerProps = {
  content: string;
  ctabuttons?: CTAButtonProps[];
  heading: string;
  id: number;
  image: {
    desktop: ImageType | MP4Type;
    mobile?: ImageType | null;
  };
  mobileVariant: 'absolute' | 'static';
  variant: 'light' | 'dark' | 'light-overlay';
};

export const HeroBanner = ({
  content,
  ctabuttons,
  heading,
  image,
  isMobile,
  mobileVariant,
  variant = 'light'
}: HeroBannerProps & { isMobile: boolean }): JSX.Element | null => {
  const responsiveImage =
    isMobile && image.mobile ? image.mobile : image.desktop;
  const isVideo = isMP4(responsiveImage.url);
  return (
    <PackageComponentWrapper>
      <ErrorBoundary component="hero-banner-carousel">
        <div
          data-test="hero-banner"
          className={classNames('hero-banner', {
            'hero-image': !isVideo && mobileVariant !== 'static',
            'hero-video': isVideo,
            'text-below': isMobile && mobileVariant === 'static'
          })}>
          <div className="media-container">
            {isVideo ? (
              <video
                className="hero-mp4"
                autoPlay
                playsInline
                loop
                muted
                key={responsiveImage.url}>
                <source src={responsiveImage.url} type="video/mp4" />
                <track kind="captions" />
              </video>
            ) : (
              <Image
                src={imgPath(responsiveImage.url)}
                alt={(responsiveImage as ImageType).alternativeText || ''}
                width={(responsiveImage as ImageType).width}
                height={(responsiveImage as ImageType).height}
                priority
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center'
                }}
              />
            )}
          </div>
          {variant === 'light-overlay' ? (
            <div className="hero-overlay"></div>
          ) : null}
          <div
            className={classNames(
              'th-text-container d-flex flex-column justify-content-center',
              {
                'bg-primary': variant === 'dark' && isMobile
              }
            )}>
            <h1
              className={classNames('landing-hero-heading', {
                'text-white':
                  variant === 'light' ||
                  variant === 'light-overlay' ||
                  (variant === 'dark' && isMobile),
                'text-primary':
                  (variant === 'dark' && !isMobile) ||
                  (variant === 'light' && isMobile)
              })}
              data-test="th-heading">
              {heading}
            </h1>
            {content ? (
              <div
                className={classNames({
                  'text-white':
                    variant === 'light' ||
                    variant === 'light-overlay' ||
                    (variant === 'dark' && isMobile),
                  'text-primary':
                    (variant === 'dark' && !isMobile) ||
                    (variant === 'light' && isMobile)
                })}>
                <Markdown content={content} />
              </div>
            ) : null}
            {ctabuttons
              ? ctabuttons.map((cta) => (
                  <CTAButton
                    {...cta}
                    variant={
                      variant === 'dark' && isMobile
                        ? 'quintenary'
                        : cta.variant
                    }
                    key={cta.url}
                  />
                ))
              : null}
          </div>
          {isMobile && mobileVariant !== 'static' ? (
            <div
              className={classNames(
                'th-text-placeholder d-flex flex-column justify-content-center'
              )}>
              <h1 className={'landing-hero-heading'} data-test="th-heading">
                {heading}
              </h1>
              {content ? (
                <div>
                  <Markdown content={content} />
                </div>
              ) : null}
              {ctabuttons
                ? ctabuttons.map((cta) => <CTAButton {...cta} key={cta.url} />)
                : null}
            </div>
          ) : null}
        </div>
      </ErrorBoundary>
    </PackageComponentWrapper>
  );
};
