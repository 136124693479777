'use client';

import { useParams } from 'next/navigation';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { Params } from 'next/dist/shared/lib/router/utils/route-matcher';

import {
  DatalayerAnalytics,
  DutchiePlus_Generated_Product_Brand,
  ProductTileType,
  slugify
} from 'services';
import { BreadCrumbs } from '../components/Breadcrumbs';
import {
  ProductViewType,
  EcomProductGrid
} from '../components/NewEcomProductGrid';
import {
  FilterMenus,
  FilterMenuItem,
  FilterKeys
} from '../components/NewEcomProductGrid/ProductSortFilter/FilterModal';
import { StorefrontCarousel } from '../components/StorefrontCarousel';
import { SortBy } from '../models/types';
import { getPageType } from '../utils/shopPageData';
import { useDispensaryPathContext } from '../hooks/dispensaryPathContextProvider';
import { EcomProductPageType } from '../models/productPage';
import { useSiteWideContext } from '../hooks/siteWideContext';
import { breadcrumbsJsonLD } from '../utils/jsonLD';
import { PackageComponentWrapper } from '../components/PackageComponentWrapper';
import { SEARCH_QUERIES, SORT } from '../utils/constants';
import { BrandDescription } from '../components/ProductDescription/BrandDescription/default';
import { moodiDayScript } from '../utils/moodiDayScript';
import { ProductsData } from '@cura/next-server/GetProducts';

import './new-product-grid-page.scss';

export type PGPProps = {
  brandData?: DutchiePlus_Generated_Product_Brand | null;
  searchParams: Params;
};

export const NewProductGridPage = ({ brandData, searchParams }: PGPProps) => {
  const params = useParams();
  const pathname = usePathname();
  const { isMobile, websiteUrl, width } = useSiteWideContext();
  const { currentPathDispensary, defaultMenuType, offerList } =
    useDispensaryPathContext();

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<ProductTileType[]>([]);
  const [sortBy, setSortBy] = useState<SortBy>();
  const [productView, setProductView] = useState<ProductViewType>('list');
  const [filters, setFilters] = useState<FilterMenus>();
  let matchingSpecial = undefined;
  if (offerList && params.specialId) {
    matchingSpecial = offerList.find((ol) => ol.id === params.specialId);
  }
  const [pageData, setPageData] = useState<EcomProductPageType>(
    getPageType(pathname, searchParams, {
      offer: matchingSpecial,
      brand: brandData
    })
  );
  const [offset, setOffset] = useState(0);
  const [totalProductCount, setTotalProductCount] = useState(0);
  const TOTAL_PRODUCTS_PER_PAGE = 20;

  useEffect(() => {
    if (currentPathDispensary?.location?.state) {
      DatalayerAnalytics.pushPageView({
        page_type: `shop/${pageData.type}`,
        consolidateDispensary: currentPathDispensary
      });
    }
  }, [pathname, currentPathDispensary, productView]);

  const fetchProducts = (
    sortBy: SortBy | undefined,
    newOffset: number,
    filterParams: Params,
    callBacks?: {
      onSuccess?: (data: ProductsData) => void;
      onSettled?: () => void;
    }
  ) => {
    const updatedSearchParams = filterParams;
    if (params.categoryKey && typeof params.categoryKey === 'string') {
      updatedSearchParams.set('c', params.categoryKey);
    } else if (params.brandName && typeof params.brandName === 'string') {
      updatedSearchParams.set('b', params.brandName);
    } else if (params.specialId && typeof params.specialId === 'string') {
      updatedSearchParams.set('spec', params.specialId);
    }
    updatedSearchParams.delete('sort');
    updatedSearchParams.delete('page');

    const filterString = updatedSearchParams.toString();
    if (
      // don't fetch if special/id page and there is no matching special in offerList
      (pageData.type === 'SubSpecialPage' &&
        pageData.pageTitle === 'Special') ||
      // don't fetch if brands/name page and there is no brandData
      (pageData.type === 'SubBrandPage' && !brandData) ||
      !currentPathDispensary
    ) {
      setLoading(false);
      setFilters(undefined);
      setProducts([]);
      setTotalProductCount(0);
    } else {
      return fetch(
        `${websiteUrl}/api/products/${currentPathDispensary.retailerId}?menuType=${defaultMenuType}&pageType=${pageData.type}${sortBy ? `&sort=${sortBy}` : ''}&offset=${newOffset}&sort=recommended${filterString.length ? `&${filterString}` : ''}`,
        { next: { revalidate: process.env.DISABLE_REDIS ? 1 : 300 } }
      )
        .then((res) => res.json())
        .then(({ data }: { data: ProductsData }) => {
          if (data) {
            setFilters(data.filters);
            setProducts(data.products);
            setTotalProductCount(data.totalProductCount);
            callBacks?.onSuccess && callBacks.onSuccess(data);
          }
        })
        .catch((error) => {
          DatalayerAnalytics.pushErrorEvent({
            category: 'api',
            location: 'fetchProducts',
            description:
              (error as string) ||
              'Error fetching products for product grid page',
            consolidateDispensary: currentPathDispensary
          });
        })
        .finally(() => {
          callBacks?.onSettled && callBacks.onSettled();
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const currView = window.localStorage.getItem('productView');
    if (currView && ['list', 'grid'].includes(currView)) {
      setProductView(currView as ProductViewType);
    } else {
      window.localStorage.setItem('productView', 'list');
    }
  }, []);

  useEffect(() => {
    //set offset from page number
    const page = parseInt(searchParams.page);
    let newOffset = 0;
    if (typeof page === 'number') {
      if (page > 1) {
        newOffset = page * TOTAL_PRODUCTS_PER_PAGE;
        setOffset(newOffset);
      } else if (page <= 0) {
        // if page number is not valid, reset to page 1
        const updatedSearchParams = new URLSearchParams({ ...searchParams });
        updatedSearchParams.set('page', '1');
        window.history.pushState(
          null,
          '',
          `?${updatedSearchParams.toString()}`
        );
      }
    }
    // grab initial product set & filters
    const filteredSearchParams = new URLSearchParams({ ...searchParams });
    fetchProducts(sortBy, newOffset, filteredSearchParams, {
      onSuccess: (data: ProductsData) => {
        const currentPage = searchParams.page;
        if (currentPage) {
          const lastPage = Math.floor(
            data.totalProductCount / TOTAL_PRODUCTS_PER_PAGE
          );
          // if current page exceeds actual amount of pages reset to page 1
          if (data.totalProductCount > 0 && parseInt(currentPage) > lastPage) {
            const updatedSearchParams = new URLSearchParams({
              ...searchParams
            });
            updatedSearchParams.set('page', '1');
            offset !== 0 ? setOffset(0) : null;
            window.history.pushState(
              null,
              '',
              `?${updatedSearchParams.toString()}`
            );
            setLoading(true);
            fetchProducts(sortBy, 0, filteredSearchParams);
          }
        }
      }
    });
  }, [searchParams, pathname]);

  useEffect(() => {
    if (searchParams.k && searchParams.k !== pageData.seoTitle) {
      setPageData(getPageType(pathname, searchParams));
      onPageChange(0);
    }
  }, [searchParams.k]);

  useEffect(() => {
    // MOODIDAY after products load in
    if (!loading) {
      moodiDayScript();
    }
  }, [loading, productView]);

  const onSelectFilter = (
    header: FilterKeys | 'Sort',
    filter: FilterMenuItem,
    sort?: SortBy
  ) => {
    if (sort && header === 'Sort') {
      return onSort(sort);
    } else if (header !== 'Sort') {
      if (filters && filters[header].length) {
        const matchingIndex = filters[header].findIndex(
          (f) => f.label === filter.label
        );
        const newFilters = { ...filters };
        if (matchingIndex >= 0 && newFilters[header][matchingIndex]) {
          if (
            header === 'Potency' &&
            newFilters[header][matchingIndex]?.values?.current &&
            filter.values?.current
          ) {
            newFilters[header][matchingIndex]!.values!.current =
              filter.values.current;
          } else {
            newFilters[header][matchingIndex]!.isSelected =
              !filters[header][matchingIndex]?.isSelected;
          }
          setLoading(true);
          setFilters(newFilters);
          // on new filter select, reset to page 1
          onPageChange(0, {
            header,
            item: filter
          });
        }
      }
    }
  };

  const onSort = (sort: SortBy) => {
    if (sort !== sortBy && SORT.includes(sort)) {
      setLoading(true);
      offset > 0 ? setOffset(0) : null;
      const updatedSearchParams = new URLSearchParams(window.location.search);
      updatedSearchParams.set('sort', sort);
      if (updatedSearchParams.get('page')) {
        // on new sort reset to page 1
        updatedSearchParams.set('page', '1');
      }
      setSortBy(sort);
      window.history.pushState(null, '', `?${updatedSearchParams.toString()}`);

      try {
        DatalayerAnalytics.pushBasicEvent('category_sort', {
          sort_style: sort
        });
      } catch (err) {
        console.error('analytics sort event', err);
      }
      fetchProducts(sort, 0, updatedSearchParams);
    }
  };

  const onPageChange = (
    newOffset: number,
    filterItem?: {
      header: FilterKeys;
      item: FilterMenuItem;
    }
  ) => {
    if (newOffset >= 0 || newOffset <= totalProductCount) {
      setLoading(true);
      setOffset(newOffset);
      const newPage =
        newOffset >= TOTAL_PRODUCTS_PER_PAGE
          ? Math.floor(newOffset / TOTAL_PRODUCTS_PER_PAGE) + 1
          : 1;
      const updatedSearchParams = new URLSearchParams(window.location.search);
      const pageParam = updatedSearchParams.get('page');
      if (pageParam || newOffset > 0) {
        updatedSearchParams.set('page', newPage.toString());
      }
      if (filterItem) {
        let queryKey: string | undefined = SEARCH_QUERIES[filterItem.header];

        if (filterItem.header === 'Potency') {
          if (filterItem.item.label === 'CBD') {
            queryKey = 'cbd';
          } else if (filterItem.item.label.includes('THC')) {
            queryKey = 'thc';
          }
          if (filterItem.item.values && typeof queryKey === 'string') {
            const matchingFilter = filters
              ? filters.Potency.find((f) =>
                  f.label.toLowerCase().includes(queryKey!)
                )
              : undefined;
            if (
              // delete potency query param if it is equal to the min & max
              matchingFilter?.values &&
              filterItem.item.values.current[0] === matchingFilter.values.min &&
              filterItem.item.values.current[1] === matchingFilter.values.max
            ) {
              updatedSearchParams.delete(queryKey);
            } else {
              updatedSearchParams.set(
                queryKey,
                encodeURI(filterItem.item.values.current.join(','))
              );
            }
          }
        } else if (typeof queryKey === 'string') {
          let current = updatedSearchParams.get(queryKey);
          if (filterItem.item.isSelected) {
            // ADD FILTER
            if (current) {
              current += `,${filterItem.item.key}`;
              updatedSearchParams.set(queryKey, encodeURI(current));
            } else {
              updatedSearchParams.set(queryKey, encodeURI(filterItem.item.key));
            }
          } else {
            // REMOVE FILTER
            if (SEARCH_QUERIES[filterItem.header] && current) {
              const currSearchParam = current.split(',');
              const matchingIdx = currSearchParam?.indexOf(filterItem.item.key);
              if (currSearchParam.length > 1 && matchingIdx >= 0) {
                currSearchParam.splice(matchingIdx, 1).join(',');
                updatedSearchParams.set(
                  queryKey,
                  encodeURI(currSearchParam.join(','))
                );
              } else if (currSearchParam.length === 1 && matchingIdx === 0) {
                updatedSearchParams.delete(queryKey);
              }
            }
          }
        }
      }
      window.history.pushState(null, '', `?${updatedSearchParams.toString()}`);
      fetchProducts(sortBy, newOffset, updatedSearchParams);
    }
  };

  const breadcrumbs = () => {
    const bc = [
      {
        id: 0,
        urlFragment: currentPathDispensary.shopLink,
        breadcrumbName: 'Home'
      }
    ];
    if (pageData.type === 'SubBrandPage') {
      bc.push({
        id: 1,
        urlFragment: `${currentPathDispensary.shopLink}/brands`,
        breadcrumbName: 'Brands'
      });
    } else if (pageData.type === 'SubCategoryPage') {
      bc.push({
        id: 1,
        urlFragment: `${currentPathDispensary.shopLink}#categories`,
        breadcrumbName: 'Categories'
      });
    } else if (pageData.type === 'SubSpecialPage') {
      bc.push({
        id: 1,
        urlFragment: `${currentPathDispensary.shopLink}/specials`,
        breadcrumbName: 'Specials'
      });
    }
    return bc;
  };

  return (
    <>
      {process.env.IS_KIOSK !== 'true' ? (
        <section>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(
                breadcrumbsJsonLD(pageData.pageTitle, breadcrumbs(), pathname)
              )
            }}
          />
        </section>
      ) : null}
      <BreadCrumbs
        props={{
          breadcrumb: breadcrumbs(),
          currentPage: pageData.pageTitle,
          isLoading: false
        }}
      />
      <PackageComponentWrapper additionalClass="padding-bottom-40 container-lr">
        <div className="d-flex flex-column gap-24">
          <h1 className="h3 text-left margin-bottom-0">{pageData.pageTitle}</h1>
          {pathname.includes('/specials') && (offerList?.length || loading) ? (
            <StorefrontCarousel
              banners={offerList}
              isLoading={loading}
              isMobile={isMobile}
              type="specials"
            />
          ) : null}
          {loading ? (
            <EcomProductGrid
              props={{
                isLoading: true,
                pageData,
                view: width && width <= 1024 ? 'list' : productView
              }}
            />
          ) : (
            <EcomProductGrid
              props={{
                filters,
                isLoading: false,
                isMobile,
                offersLength: offerList?.length ?? 0,
                onSelectFilter,
                pageTitle: pageData.pageTitle,
                pageType: pageData.type,
                products: products,
                searchParams,
                setProductView,
                shopLink: currentPathDispensary?.shopLink || '',
                sortBy: sortBy || 'recommended',
                totalProductCount,
                view: width && width <= 1024 ? 'list' : productView
              }}
            />
          )}
          {loading || totalProductCount <= TOTAL_PRODUCTS_PER_PAGE ? null : (
            <div className="d-flex justify-content-center align-items-end pagination">
              <button
                aria-label="first-page"
                disabled={offset < TOTAL_PRODUCTS_PER_PAGE}
                onClick={() => onPageChange(0)}>
                <i className="pi pi-angle-double-left" />
              </button>
              <button
                aria-label="previous-page"
                disabled={offset < TOTAL_PRODUCTS_PER_PAGE}
                onClick={() => onPageChange(offset - TOTAL_PRODUCTS_PER_PAGE)}>
                <i className="pi pi-angle-left" />
              </button>
              <div className="current-page">
                <span
                  className="body-l font-bold margin-inline-5"
                  aria-label="current-page">
                  {offset >= TOTAL_PRODUCTS_PER_PAGE
                    ? Math.floor(offset / TOTAL_PRODUCTS_PER_PAGE) + 1
                    : 1}
                </span>
              </div>
              <button
                aria-label="next-page"
                disabled={offset >= totalProductCount - TOTAL_PRODUCTS_PER_PAGE}
                onClick={() => onPageChange(offset + TOTAL_PRODUCTS_PER_PAGE)}>
                <i className="pi pi-angle-right" />
              </button>
              <button
                aria-label="last-page"
                onClick={() =>
                  onPageChange(
                    Math.floor(totalProductCount / TOTAL_PRODUCTS_PER_PAGE) *
                      TOTAL_PRODUCTS_PER_PAGE
                  )
                }
                disabled={
                  offset >= totalProductCount - TOTAL_PRODUCTS_PER_PAGE
                }>
                <i className="pi pi-angle-double-right" />
              </button>
            </div>
          )}
          {pageData.type === 'SubBrandPage' && brandData ? (
            <div>
              <BrandDescription
                isLoading={false}
                productBrand={{
                  ...brandData,
                  slug: slugify(brandData.name)
                }}
              />
            </div>
          ) : null}
        </div>
      </PackageComponentWrapper>
    </>
  );
};
