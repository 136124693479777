import { PackageComponentWrapper } from '../../../PackageComponentWrapper';
import { Markdown } from '../../../Markdown';

export type ConfirmationProps = {
  content: string;
  title: string;
};

export const AccountConfirmationForm = ({
  content,
  title
}: ConfirmationProps) => {
  return (
    <PackageComponentWrapper>
      <div>
        <div className="sidebar-header">
          <h2 className="margin-bottom-32 text-primary h3">{title}</h2>
        </div>
        <div className="sidebar-form-padded text-primary">
          <Markdown content={content} />
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
