import Image from 'next/image';
import Link from 'next/link';

import { ImageType } from '../../../models/types';
import { imgPath } from '../../../utils/imgPath';
import { getArticleUrlFor, isExternalUrl } from '../../../utils/url';

import './article-card.scss';

import CuraleafArrow from '../../../assets/svgs/arrows/transparent-arrow.svg';

export type ArticleCardProps = {
  author: string | null;
  date: string; // Dec 14, 2023
  id: number;
  slug: string;
  thumbnail: ImageType;
  title: string;
  type: 'news' | 'blog';
};

export function ArticleCard({
  author,
  date,
  slug,
  thumbnail,
  title,
  type
}: ArticleCardProps): JSX.Element {
  const articleLink = getArticleUrlFor(slug, type);

  return (
    <section className="card article-card" data-test="card-container">
      <div className="image">
        <Image
          className="card-img-top"
          data-test="img"
          src={imgPath(thumbnail.url)}
          alt={title}
          width={thumbnail.width}
          height={thumbnail.height}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
      </div>
      <div className="card-body" data-test="text-container">
        <div className="upper">
          <div>
            {author && author.length ? (
              <small
                data-test="author"
                className="card-text">{`${author.substring(0, 20)} - `}</small>
            ) : null}
            {date && (
              <small className="card-text" data-test="date">
                {date}
              </small>
            )}
          </div>
          <Link
            href={articleLink}
            data-test="title-link"
            target={isExternalUrl(slug) ? '_blank' : ''}
            aria-label="Go to article">
            <h3 className="card-title h3" data-test="title">
              {title}
            </h3>
          </Link>
        </div>
      </div>
      <Link
        href={articleLink}
        data-test="curaleaf-icon-link"
        className="icon-link-curaleaf"
        target={isExternalUrl(slug) ? '_blank' : ''}
        aria-label="Go to article">
        <CuraleafArrow data-test="icon" aria-label="Go to article" />
      </Link>
    </section>
  );
}
