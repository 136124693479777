import { Curaql_Cart, Consolidate_Cart, Consolidate_Product } from 'services';

export const apolloCartToConsolidateCartMap = (
  cart: Curaql_Cart
): Consolidate_Cart | undefined => {
  try {
    return {
      ...cart,
      pricingType: cart.pricingType,
      items: cart.items.map((item) => {
        return {
          basePrice: item.basePrice,
          discounts: item.discounts,
          id: item.id,
          option: item.option,
          product: {
            brand: item.product.brand
              ? {
                  name: item.product.brand.name
                }
              : null,
            cardDescription: item.product.cardDescription || '',
            category: item.product.category.key,
            effects: item.product.effects.map((e) => ({
              key: e.key,
              displayName: e.displayName
            })),
            subcategory: item.product.subcategory?.key,
            id: item.product.id,
            images: item.product.images,
            name: item.product.name,
            offers: item.product.offers
              ? item.product.offers.reduce(
                  (arr: Consolidate_Product['offers'], offer) => {
                    if (offer?.id && offer.title) {
                      arr.push({
                        id: offer.id,
                        isLoading: false,
                        title: offer.title
                      });
                    }
                    return arr;
                  },
                  []
                )
              : [],
            strainType: item.product.strain
              ? item.product.strain.key
              : 'NOT_APPLICABLE',
            variants: item.product.variants.map((v) => {
              return {
                id: v.id,
                option: v.option,
                isSpecial: !!v.isSpecial,
                percentOff: v.percentOff ?? 0,
                price: v.price ?? 0,
                quantity: v.quantity ?? 0,
                specialPrice: v.specialPrice ?? 0,
                specialConditionIds: v.specialConditionIds,
                specialRewardIds: v.specialRewardIds
              };
            })
          },
          productId: item.productId,
          quantity: item.quantity
        } as Consolidate_Cart['items'][number];
      })
    };
  } catch (err) {
    return undefined;
  }
};

export const optInBoolean = (optInString: string) => {
  return optInString !== 'subscribed' && optInString !== 'unsubscribed';
};
