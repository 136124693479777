import { Loader } from './loader';
import { SpecificationsAccordion as Default } from './default';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';
import { ProductSpecificationProps } from '../default';
import { Consolidate_ProductVariant } from 'services';

import './specifications-accordion.scss';

export type SpecificationsProps = {
  isLoading: boolean;
  specifications?: ProductSpecificationProps;
  variants?: Consolidate_ProductVariant[];
  coaUrl?: string;
  onCoaClick: () => void;
};

export const SpecificationsAccordion = ({
  props
}: {
  props: SpecificationsProps;
}): JSX.Element | null => {
  return props.isLoading ? (
    <Loader />
  ) : props.specifications ? (
    <ErrorBoundary component="specification-description">
      <Default {...props} />
    </ErrorBoundary>
  ) : null;
};
