import { useRef, useState } from 'react';
import moment from 'moment';
import Image from 'next/image';

import { CurrentPathDispensary, DAYS_OF_WEEK, capitalize } from 'services';

import { CTAButton } from '../../CTAButton';
import useOutsideClick from '../../../hooks/useOutsideClick';

import Clock from '../../../assets/svgs/overview/clock.svg';
import SmArrow from '../../../assets/svgs/arrows/accordion-arrow-sm.svg';

import './overview-card.scss';

type OverviewCardProps = {
  dispensary: CurrentPathDispensary;
  width?: number;
};

const cleanTimeStr = (timeStr: string) => {
  return timeStr.replace(/:00/g, '').replace(/ /g, '');
};

const HoursContent = ({
  openTimes
}: {
  openTimes: CurrentPathDispensary['openTimes'];
}) => {
  const dayOfWeek = moment().format('dddd').toLowerCase();
  let hoursByDays: any[] = [dayOfWeek];
  const currDayInd = DAYS_OF_WEEK.findIndex((d) => d === dayOfWeek);
  hoursByDays = hoursByDays
    .concat([...DAYS_OF_WEEK.slice(currDayInd + 1)])
    .concat([...DAYS_OF_WEEK.slice(0, currDayInd)]);

  const todayClassName = (i: number) =>
    i === 0 ? 'body-m font-bold' : 'body-m';

  return (
    <ul>
      {hoursByDays.map((d: (typeof DAYS_OF_WEEK)[number], i) => {
        return (
          <li className="d-flex justify-content-between" key={d}>
            <span className={todayClassName(i)}>
              {i === 0 ? 'Today' : capitalize(d)}
            </span>
            <span className={todayClassName(i)}>
              {openTimes[d]
                ? openTimes[d]
                    .map((v) =>
                      v.startTime && v.endTime
                        ? `${cleanTimeStr(v.startTime)}-${cleanTimeStr(v.endTime)}`
                        : 'CLOSED'
                    )
                    .filter(Boolean)
                    .join(', ')
                : null}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export const OverviewCard = ({
  dispensary,
  width
}: OverviewCardProps): JSX.Element | null => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const refOutsideClick = useOutsideClick(ref, () => setOpen(false));
  if (!dispensary) return null;

  return (
    <div className="overview-card-container">
      {(width && width > 1024) || process.env.IS_KIOSK === 'true' ? (
        <h6 className="title-m title">{dispensary.friendlyName}</h6>
      ) : (
        <a
          href={`/dispensary/${dispensary.location.state.slug}/${dispensary.slug}`}>
          <h6 className="title-m title underline">{dispensary.friendlyName}</h6>
        </a>
      )}
      <div>
        <div className="hours">
          <a
            className="d-flex align-items-center pointer"
            onClick={() => setOpen(!open)}
            tabIndex={0}>
            <Clock />
            {dispensary.todaysHours &&
            !dispensary.todaysHours?.includes('Closed') ? (
              <span className="body-m">
                {cleanTimeStr(dispensary.todaysHours)}
              </span>
            ) : (
              <span className="body-m font-bold">Closed</span>
            )}
            <SmArrow />
          </a>
          {open && refOutsideClick !== true ? (
            <div className="hours-popup" ref={ref}>
              <div
                className="tail-container"
                aria-hidden="true"
                role="presentation">
                <div className="popup-tail bg-white" />
              </div>
              <div className="hours-content">
                <HoursContent openTimes={dispensary.openTimes} />
              </div>
            </div>
          ) : null}
        </div>
        {width && width > 1024 ? (
          <>
            {dispensary.mapImage ? (
              <div className="map margin-bottom-12">
                <Image
                  src={dispensary.mapImage.url}
                  height={dispensary.mapImage.height}
                  width={dispensary.mapImage.width}
                  alt="map"
                />
              </div>
            ) : null}
            {process.env.IS_KIOSK !== 'true' ? (
              <div className="margin-top-12">
                <CTAButton
                  text="See more"
                  url={`/dispensary/${dispensary.location.state.slug}/${dispensary.slug}`}
                  variant="tertiary"
                  size="sm"
                />
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};
